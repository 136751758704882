import { Badge, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import HeaderWrapper from "../../../components/headerwrapper/HeaderWrapper";
import { TextInput } from "../../../components/InputBox";
import SearchIcon from "@mui/icons-material/Search";
import { useRestaurant, useSuperAdmin } from "../../../utils/hooks";
import CustomTabPanel from "../../../components/Tabs/CustomtabPanels";
import MenuComponent from "./MenuList";
import { PageLoader } from "../../../components/loader";
import NoDataComponent from "../../../components/NoDataComponent";
import DraggableTabs from "../../../components/Tabs/DragableComponent";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AddIcon from '@mui/icons-material/Add';
import LogOutButton from "../../../components/Logout";

function MenuManagement() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const { useGetTabList, useGetTabListContent, setNotificationDrawer } = useRestaurant();
  const { tabValue } = useSuperAdmin();
  const { data: tabData, loading: tabDataIsLoading } = useGetTabList();

  const [menuTabList, setMenuTabList] = useState(tabData?.result.map((item) => ({
    label: `${item.food_category} (${item?.count})`,
    _id: item?._id,
  })) || [])

  const { data: menuList } = useGetTabListContent(
    tabData?.result[tabValue]?._id
  );

  useEffect(() => {
    setMenuTabList(
      tabData?.result.map((item) => ({
        label: `${item.food_category} (${item?.count})`,
        _id: item?._id,
      }))
    )
  }, [tabData, tabDataIsLoading])

  /**
   * Maps over the tabData result array to create a new array of objects with label and id properties.
   * The label is generated by combining the food category and count of each item.
   * @returns An array of objects with label and id properties.
   */
  // const menuTabList = tabData?.result.map((item) => ({
  //   label: `${item.food_category} (${item?.count})`,
  //   _id: item?._id,
  // }));
  return (
    <>
      <HeaderWrapper>
        <div
          className="overflow-auto"
          style={{
            height: "100vh"
            // height: "calc(100vh - 51px)",
          }}
        >
          <Grid container sx={12}>
            <Grid container xs={12} justifyContent="space-between">
              <Grid item xs={12} sx={{ borderBottom: "2px solid #1C1C1C1A" }}>
                <div className="  flex  items-center    justify-between py-4 px-8 ">
                  <h3 className="hidden sm:flex gap-3 items-center">
                    <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                    <span className="text-[#1C1C1C66]">/</span>
                    <span>Menu Management</span>
                  </h3>
                  <div className="max-w-[400px]  flex gap-4 items-center ">
                    <TextInput
                      variant="outlined"
                      preContent={<SearchIcon sx={{ color: "#c8c8c8" }} />}
                      transparentBorder
                      placeholder="Search"
                      value={searchValue}
                      borderRadius="10px"
                      onChange={(e) => setSearchValue(e.target.value)}
                    //   label="Description(optional)"
                    />
                    <Badge badgeContent={0} color="error">
                      <NotificationsNoneOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setNotificationDrawer(true)} />
                    </Badge>
                    <LogOutButton />
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container xs={12} justifyContent="space-between" className="my-2 mx-8" alignItems="center">
              <Grid item>
                <h1 className=" text-[30px] text-[#3e3e3e] font-medium">
                  Menu List
                </h1>
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <BasicButton
                  color="genieRed"
                  sx={{
                    textTransform: "none",
                    width: "126px",
                    height: "36px",
                    marginRight: "10px",
                    color: "white",
                    display: "flex",
                    gap: "5px",
                    margin: "auto"
                  }}
                  onClick={() => navigate("create")}
                >
                  <AddIcon />
                  Add Item
                </BasicButton>
              </Grid>
            </Grid>

            {/* <Button onClick={()=>{console.log(menuList)}}>Check Data</Button> */}

            <Grid item xs={12}>
              {!menuTabList ? (
                <PageLoader />
              ) : tabData?.result.length === 0 ? (
                <NoDataComponent tittle={"Add Menu to Restaurant "} />
              ) : (
                <DraggableTabs tabs={menuTabList || []}>
                  {(value) => {
                    return (
                      <>
                        <CustomTabPanel value={value} index={value}>
                          {menuList?.result.length === 0 ? (
                            <NoDataComponent tittle={"No Items Added"} />
                          ) : (
                            <MenuComponent list={menuList?.result} />
                          )}
                        </CustomTabPanel>
                      </>
                    );
                  }}
                </DraggableTabs>
              )}
            </Grid>
          </Grid>
        </div>
      </HeaderWrapper >
    </>
  );
}

export default MenuManagement;
