import React, { useState } from 'react'
import BasicButton from '../Button'
import useToken from '../../utils/hooks/useToken';
import ConfirmLogoutModal from '../headerwrapper/ConfirmLogoutmodal';
import { useNavigate } from 'react-router-dom';

const LogOutButton = () => {
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const { removeToken } = useToken();
    const navigate = useNavigate();

    return (
        <>
            <BasicButton
                variant="outlined"
                color="genieRed"
                sx={{
                    textTransform: "none",
                    // padding: "3px 10px",
                    display: "flex",
                    margin: "auto"
                }}
                onClick={() => setOpenLogoutModal(true)}
            >
                Log Out
            </BasicButton>
            {openLogoutModal && (
                <ConfirmLogoutModal
                    open={openLogoutModal}
                    handleCancel={() => setOpenLogoutModal(false)}
                    handleConfirmClick={() => {
                        removeToken()
                        navigate(-1)
                    }}
                />
            )}
        </>
    )
}

export default LogOutButton