import React, { useEffect, useRef, useState } from "react";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import share from "../../../assets/Icons/share.svg";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Star } from "@mui/icons-material";
import { RWebShare } from "react-web-share";
import { useDiner } from "../../../utils/Context/Dinner";
import { LazyLoadImage } from "react-lazy-load-image-component";

function RestaurantInfo({
  data,
  toggleDrawer,
  isDrawerOpenValue,
  isDrawerOpen,
}) {
  const [addressDropdownActive, setAddressDropdownActive] = useState(false);
  const restaurantInfoRef = useRef(null);
  const { setRestaurantInfoHeight } = useDiner();

  useEffect(() => {
    setRestaurantInfoHeight(restaurantInfoRef?.current?.clientHeight + 12)
  }, [restaurantInfoRef?.current?.clientHeight, setRestaurantInfoHeight]);

  return (
    <>
      <div
        className={` 
          mt-[0.5rem]
          flex 
          min-h-[72px] 
          justify-between 
          items-center 
          mx-[16px] 
          px-[12px]
          py-[8px]
          border-2 
          border-[#d9dbdf] 
          rounded-lg 
          bg-white 
          bg-opacity-60
          backdrop-blur-[10px]
          relative
          z-[10]
          `}
        ref={restaurantInfoRef}
      >
        <div className="flex items-center">
          <img
            src={data?.result?.logoURL}
            alt="Logo"
            className="rounded-full  w-[66px] h-[66px] object-cover"
          />
          <div className={`flex flex-col justify-center ml-2 ${addressDropdownActive ? "py-[11px]" : ""}`}>
            <h1 className="font-700 text-[14px]  text-black">
              {data?.result?.name}
            </h1>
            <div className="flex items-center gap-1 relative">
              {/* TODO: fix address */}
              <h3 className={` font-500 text-[12px] text-[#484848] underline md:max-w-full line-clamp-2 ${addressDropdownActive ? "line-clamp-none" : ""}`}>
                {!addressDropdownActive ?
                  typeof (data?.result?.address) === 'string' ? data?.result?.address : data?.result?.address.addressLine1 + "..."
                  :
                  <>
                    <p>{data?.result?.address?.addressLine1}</p>
                    <p>{data?.result?.address?.addressLine2 || ""}</p>
                    <p>{data?.result?.address?.city}</p>
                    <p>{data?.result?.address?.zip || ""}</p>
                    <p>{data?.result?.address?.country}</p>
                  </>
                }
              </h3>
              <ArrowDropDownOutlinedIcon sx={{ cursor: "pointer", transform: addressDropdownActive && "rotate(180deg)" }}
                onClick={async () => {
                  await setAddressDropdownActive(!addressDropdownActive)
                  await setRestaurantInfoHeight(restaurantInfoRef?.current?.clientHeight)
                }}
              />
              {/* <div className={`bg-white absolute top-[25px] border border-[0.5] border-[#D1D1D1] p-2 rounded-xl min-w-[168px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] 
                ${addressDropdownActive ? "block" : "hidden"}`}
              >
                <span className="text-[#757373] text-xs">Full Address</span> <br />
                {typeof (data?.result?.address) === 'string' ? data?.result?.address :
                  <>
                    <p>{data?.result?.address?.addressLine1}</p>
                    <p>{data?.result?.address?.addressLine2 || ""}</p>
                    <p>{data?.result?.address?.city}</p>
                    <p>{data?.result?.address?.zip || ""}</p>
                    <p>{data?.result?.address?.country}</p>
                  </>
                }
              </div> */}
            </div>
            {/* <div className="flex items-center gap-1 justify-between">
              <span className="text-[12px] text-[#777777]">10 - 20 min</span>
              <div className="text-[#377931] flex items-center gap-[3px] ml-2">
                <Star sx={{ height: "15px", width: "15px" }} />
                <span className="font-normal">4.6</span>
              </div>
            </div> */}
            {/* {isDrawerOpenValue ? (
              <h3 className="font-QuickSand font-500 text-[12px] text-[#484848]">
                international Cuisine
              </h3>
            ) : (
              ""
            )} */}
            {/* <h3 className="font-QuickSand font-500 text-[12px] text-[#777777]">
              {data?.result?.workingHours?.from}- {data?.result?.workingHours?.to}
            </h3> */}
          </div>
        </div>
        <div className="flex flex-col w-auto gap-2">
          <div className=" flex justify-end">
            {/* <ShareIcon sx={{ color: "#3f3f3f" }} /> */}
            {/* <a href={data?.result?.customDomain} target="_blank" rel="noreferrer"> */}
            <RWebShare
              // TODO: fix url
              data={{
                // url: `https://www.google.com/search?q=${data?.result?.name?.split(' ').join("+")}+${data?.result?.address?.addressLine1}`,
                url: `https://dev-frontend.grubgenie.ai/genie/dine/${data?.result?._id}`
              }}
              onClick={() => console.info("share successful!")}
            >
              <img className="cursor-pointer" src={share} alt="" />
            </RWebShare>
            {/* </a> */}
          </div>
          <div className="flex justify-around xs:gap-2">
            <a href={data?.result?.facebookURL} target="_blank" rel="noreferrer">
              <FacebookRoundedIcon sx={{ color: "#3f3f3f", height: "20px" }} />
            </a>
            <a href={data?.result?.instagramURL} target="_blank" rel="noreferrer">
              <InstagramIcon sx={{ color: "#3f3f3f", height: "20px" }} />
            </a>
            <a href={data?.result?.youtubeURL} target="_blank" rel="noreferrer">
              <YouTubeIcon sx={{ color: "#3f3f3f", height: "20px" }} />
            </a>
          </div>
          {/* {!isDrawerOpenValue ? (
            <div className=" flex justify-end" onClick={toggleDrawer}>
              <InfoOutlinedIcon />
            </div>
          ) : (
            <div className=" flex justify-end">
              <a href={data?.result?.name} target="_blank">
                <DirectionsIcon sx={{ color: "red", marginRight: "6px" }} />
              </a>
              <ShareIcon sx={{ color: "red" }} />
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}

export default RestaurantInfo;
