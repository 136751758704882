import React from "react";
import { BasicModal } from "../../../components/Modal/Modal";
import BasicButton from "../../../components/Button";

function ConfirmDeleteModal({ open, handleCancel, handleConfirmClick }) {
  return (
    <BasicModal open={open} close={handleCancel}>
      <div className="flex flex-col gap-6 p-6 min-w-[360px]">
        <p className="font-bold text-lg font-Quicksand">
          Are you sure want to Delete?
        </p>
        <div className="flex justify-end items-center gap-2">
          <BasicButton
            onClick={handleCancel}
            variant="outlined"
            sx={{
              textTransform: "none",
              // width: "236px",
              width: "100%",
              height: "36px",
            }}
          >
            Cancel
          </BasicButton>
          <BasicButton
            onClick={handleConfirmClick}
            sx={{
              textTransform: "none",
              // width: "236px",
              width: "100%",
              height: "36px",
            }}
          >
            Confirm
          </BasicButton>
        </div>
      </div>
    </BasicModal>
  );
}

export default ConfirmDeleteModal;
