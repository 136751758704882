import React, { useEffect, useState } from 'react'
import HeaderWrapper from '../../../components/headerwrapper/HeaderWrapper'
import { Badge, Drawer, Grid } from '@mui/material'
import { TextInput } from '../../../components/InputBox'
import LogOutButton from "../../../components/Logout";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";
import DineInSection from './DineInSection';
import TableSection from './TableSection';
import BasicButton from '../../../components/Button';
import AddIcon from '@mui/icons-material/Add';
import { BasicModal } from '../../../components/Modal/Modal';
import { useAuth, useRestaurant } from '../../../utils/hooks';
import closeIcon from "../../../assets/Icons/close.svg"
import { Controller, useForm } from 'react-hook-form';
import { CustomTextInput } from '../../../components/InputBox/CustomInput';
import { queryClient } from '../../../utils/config/queryClient';

const Index = () => {
    const [selectedTab, setSelectedTab] = useState("dineIn");
    const [dineManagementSummary, setDineManagementSummary] = useState({})
    const [dateRange, setDateRange] = useState(() => {
        const today = dayjs().toDate();
        const tomorrow = dayjs().add(1, 'day').toDate();
        return [today, tomorrow];
    });
    const [startDate, endDate] = dateRange;

    const {
        openModal,
        setOpenModal,
        OnAddTable,
        setNotificationDrawer
    } = useRestaurant()
    const { socket, userData } = useAuth();

    const { control, handleSubmit, reset } = useForm({
        mode: "onChange"
    })

    const onSubmit = (data) => {
        OnAddTable({ tableSequence: data.tableSequence.toString(), numberOfSeats: Number(data.numberOfSeats) })
        setOpenModal(false)
        reset()
    }

    useEffect(() => {
        socket.emit("request", {
            en: "DS",
            data: { partnerId: userData?._id }
        });

        socket.emit("request", {
            en: "TS",
            data: { partnerId: `${userData?._id}` },
        });

        socket.on("dineManagementCount", (data) => {
            setDineManagementSummary(data?.data)
        })

        socket.on("TS", (data) => {

        });

        socket.on("orders-update", (data) => {
            queryClient.invalidateQueries(["getAllDetails"])
            queryClient.invalidateQueries(["getOrderById"])
        })

        socket.on("table-update", (data) => {
            queryClient.invalidateQueries(["getAllTable"])
        })

        return () => {
            socket.off("DS");
            socket.off("TS");
            socket.off("dineManagementCount");
            socket.off("orders-update");
            socket.off("table-update");
        }
    }, [socket, userData])

    return (
        <>
            <HeaderWrapper>
                <Grid
                    container
                    xs={12}
                    sx={{ height: "100vh", display: "block" }}
                >
                    <Grid item xs={12} sx={{ height: "74px" }}>
                        <div className="  flex  items-center    justify-between py-4 px-8 border-b-[2px] border-[#1C1C1C1A] ">
                            <h3 className="hidden sm:flex gap-3 items-center">
                                <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                                <span className="text-[#1C1C1C66]">/</span>
                                <span>{selectedTab === "dineIn" ? "Dine In Management" : "Table Management"}</span>
                            </h3>
                            <div className="max-w-[400px]  flex gap-4 items-center ">
                                <TextInput
                                    variant="outlined"
                                    preContent={<SearchIcon sx={{ color: "#c8c8c8" }} />}
                                    transparentBorder
                                    placeholder="Search"
                                    // value={searchValue}
                                    borderRadius="10px"
                                // onChange={(e) => setSearchValue(e.target.value)}
                                //   label="Description(optional)"

                                />
                                <Badge badgeContent={0} color="error">  
                                    <NotificationsNoneOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => setNotificationDrawer(true)} />
                                </Badge>
                                <LogOutButton />
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sx={{ padding: "10px 32px 0px 32px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div className='flex w-fit text-xl'>
                            <div className={`pt-4 flex items-center justify-center px-[28px] ${selectedTab === "dineIn" ? "text-[#3E3E3E] font-medium border-b-[4px] border-[#FFC0BF] pb-[12px]" : "text-[#757373] font-normal pb-4"}`}
                                onClick={() => setSelectedTab("dineIn")}
                                role="button"
                                tabIndex={0}
                                onKeyPress={(e) => e.key === 'Enter' && setSelectedTab("dineIn")}
                            >
                                Dine in Management
                            </div>
                            <div className={`pt-4 flex items-center justify-center px-[28px] ${selectedTab === "table" ? "text-[#3E3E3E] font-medium border-b-[4px] border-[#FFC0BF] pb-[12px]" : "text-[#757373] font-normal pb-4"}`}
                                onClick={() => setSelectedTab("table")}
                                role="button"
                                tabIndex={0}
                                onKeyPress={(e) => e.key === 'Enter' && setSelectedTab("table")}
                            >
                                Table Management
                            </div>
                        </div>

                        {selectedTab === "dineIn" ? (
                            <DatePicker
                                // value={dayjs().format("YYYY-MM-DD")}
                                value={dateRange}
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => setDateRange(update)}
                                isClearable={true}
                                placeholderText="Select date range"
                            />
                        ) :
                            <BasicButton
                                color="genieRed"
                                sx={{
                                    textTransform: "none",
                                    color: "white",
                                    display: "flex",
                                    gap: "8px",
                                    fontWeight: "500",
                                    padding: "8px 16px",
                                    fontSize: "16px"
                                }}
                                onClick={() => setOpenModal(true)}
                            >
                                <AddIcon />
                                Add New Table
                            </BasicButton>
                        }
                    </Grid>

                    <Grid item xs={12} sx={{ padding: "20px 0px 20px 0px", height: "calc(100vh - 144px)", overflowY: "auto" }}>
                        {selectedTab === "dineIn" && <DineInSection startDate={startDate} endDate={endDate} dineManagementSummary={dineManagementSummary} />}
                        {selectedTab === "table" && <TableSection dineManagementSummary={dineManagementSummary} />}
                    </Grid>

                </Grid>
            </HeaderWrapper>

            {openModal && (
                <BasicModal open={openModal} close={() => { setOpenModal(false) }} maxWidth="553px">
                    <form className='flex flex-col gap-[20px]' onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex justify-between items-center">
                            <p className="font-semibold">Add New Table</p>
                            <img className="cursor-pointer" onClick={() => setOpenModal(false)} src={closeIcon} alt="" />
                        </div>

                        <Grid container xs={12} sx={{ gap: "20px" }}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                                <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">TABLE NUMBER<span className="text-[#b80e0c]">*</span></p>
                                </Grid>
                                <Grid xs={8}>
                                    <Controller
                                        name="tableSequence"
                                        control={control}
                                        rules={{
                                            required: "Table Number is required",
                                            validate: {
                                                positive: (value) => value > 0 || "Table number must be greater than 0",
                                                integer: (value) => Number.isInteger(Number(value)) || "Table number must be a whole number"
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CustomTextInput
                                                {...field}
                                                placeholder="Table Number"
                                                type="number"
                                                variant="outlined"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={12} sx={{ display: "flex" }}>
                                <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">NUMBER OF SEATS<span className="text-[#b80e0c]">*</span></p>
                                </Grid>
                                <Grid xs={8}>
                                    <Controller
                                        name="numberOfSeats"
                                        control={control}
                                        rules={{
                                            required: "Number of Seats is required",
                                            validate: (value) => {
                                                return value > 0 || "Number of seats must be greater than 0";
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CustomTextInput
                                                {...field}
                                                placeholder="Number of Seats"
                                                type="number"
                                                variant="outlined"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid container xs={12}>
                            <Grid xs={6} sx={{ paddingRight: "10px" }}>
                                <BasicButton
                                    variant="outlined"
                                    color="genieRed"
                                    sx={{
                                        textTransform: "none",
                                        display: "flex",
                                        width: "100%"
                                    }}
                                    onClick={() => setOpenModal(false)}
                                >
                                    Cancel
                                </BasicButton>
                            </Grid>
                            <Grid xs={6} sx={{ paddingLeft: "10px" }}>
                                <BasicButton
                                    color="genieRed"
                                    type="submit"
                                    sx={{
                                        textTransform: "none",
                                        display: "flex",
                                        color: "white",
                                        width: "100%",
                                    }}
                                // onClick={handleSave}
                                >
                                    Add Table
                                </BasicButton>
                            </Grid>
                        </Grid>
                    </form>
                </BasicModal>
            )}
        </>
    )
}

export default Index
