import { Badge, Grid } from '@mui/material'
import React from 'react'
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cashPayment from "../../../assets/images/cashPayment.svg";
import cart from "../../../assets/Icons/Cart.svg";
import backArrow from "../../../assets/Icons/backArrow.svg";
import { useDiner } from '../../../utils/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const CashPayment = () => {
  const navigate = useNavigate();
  const restaurantId = useParams();
  const location = useLocation();
  const { cartList } = useDiner();

  const tableId = new URLSearchParams(location.search).get("tableId");

  return (
    <>
      <Grid container direction="column">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem]"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          {/* TODO: Add cart count functionality */}
          <Badge badgeContent={cartList.reduce((acc, curr) => acc + curr.orderDetails.length, 0)}
            // onClick={() => navigate(`/genie/dine/${restaurantId?.id}/cart/?tableId=${tableId}`)}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}
            className="opacity-50"
          >
            <img src={cart} alt="cart" />
          </Badge>
        </Grid>

        <Grid
          container
          item
          xs={12}
          direction="row"
          gap="12px"
          alignItems="center"
          className="p-4"
        >
          <img className="h-[20px] w-[20px] cursor-pointer opacity-50" src={backArrow} alt=""
          // onClick={() => (handleDiff(), navigate(-1))}
          // onClick={() =>
          //   navigate(`/genie/dine/${restaurantId?.id}/bill?tableId=${tableId}`)
          // }
          />
          <h1 className="  font-[500] text-[14px]">
            {/* Your Order */}
            Cash Payment
          </h1>
        </Grid>

        <div className='px-4 w-full h-[calc(100vh-113px)] flex flex-col items-center gap-[39px] justify-center'>
          <img src={cashPayment} className='w-[160px]' alt="" />
          <p className='font-medium text-[18px]'>Waiter on the way</p>
        </div>

      </Grid>
    </>
  )
}

export default CashPayment