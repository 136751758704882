// import dayjs  from "dayjs";
import React from "react";
import MultiDatePicker from "react-multi-date-picker";
import CalendarTodayRoundedIcon from '../../assets/Icons/calendar_month.svg';


const DatePicker = ({ value = new Date(), onChange, placeholder }) => {
  //  const disabledAfterToday = (date) => {
  //   return date.isAfter(dayjs(), "day");
  //  };

  return (
    <div className="relative w-fit flex items-center h-[32px] border border-1 border-[#E3E3E3] px-2 rounded-[4px]">
      <MultiDatePicker
        inputClass="text-[20px] w-[280px] font-medium outline-none"
        value={value}
        onChange={onChange}
        format="DD MMM YYYY"
        placeholder={placeholder}
        className="w-full"
        clearable
        range
        dateSeparator=" - "
        portal
      //   animations={[transition()]}
      // disabled={disabledAfterToday}  
      />
      <img src={CalendarTodayRoundedIcon} alt="datePicker" className="text-gray-400 pointer-events-none" size={20} />
    </div>
  );
};

export default DatePicker;
