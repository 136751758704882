import { Badge, Grid } from '@mui/material'
import Isolation_Mode from "../../assets/images/Isolation_Mode.svg";
import cart from "../../assets/Icons/Cart.svg";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDiner } from '../../utils/hooks';
import useToken from '../../utils/hooks/useToken';
import { useState } from 'react';
import { Star } from '@mui/icons-material';
import CustomToggleButton from '../../components/CustomToggleButton';
import BasicButton from "../../components/Button";
import food_image from "../../assets/images/food_image.png";
import food_image_2 from "../../assets/images/food_image_2.jpeg";
import share from "../../assets/Icons/share.svg";
import cargo_restraunt_logo from "../../assets/images/cargo_restraunt_logo.png";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import offer_1 from "../../assets/images/offer_1.png";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CustomCarousel from '../../components/CustomCarousel';

const Takeout = () => {
  const { useGetCartCount } = useDiner();

  const navigate = useNavigate();
  const restaurantId = useParams();
  const { getCartId } = useToken();

  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");

  const { data: cartCount } = useGetCartCount({
    partnerId: restaurantId?.id,
    cartId: getCartId(),
  });

  const [selectedOption, setSelectedOption] = useState('delivery')

  const options = [
    { label: 'Delivery', time: '25 - 35 min' },
    { label: 'Pickup', time: '5 - 15 min' },
  ]
  const handleChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption)
    }
  }

  return (
    <>
      <Grid xs={12} direction="column" className="font-poppins">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem] sticky top-0 z-50"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          <Badge badgeContent={cartCount?.result?.cartItemCount} onClick={() => navigate(`cart/?tableId=${tableId}`)} sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}>
            <img src={cart} alt="cart" />
          </Badge>
        </Grid>

        <div container xs={12} sx={{ marginTop: "20px", display: "flex", flexDirection: "column", gap: "20px", position: "relative", justifyContent: "space-between" }}
          className='mt-[20px] flex flex-col gap-[20px] relative justify-between h-[calc(100vh-80px)]'
        >
          {/* <div className='absolute -z-10 -top-[20px] h-[70vh] w-full'>
            <img className='object-cover object-center h-full w-full' src={food_image} alt="" />
          </div> */}
          {/* <div className='absolute -z-10 -top-[20px] h-[70vh] w-full'> */}
          <img className='absolute w-[100vw] -z-10 -top-[20px] object-cover object-center min-h-[464px] h-[75%]' src={food_image} alt="" />
          {/* </div> */}

          <div className='flex flex-col gap-[20px] flex-none'>
            <Grid xs={12} sx={{ width: "100%", padding: "0px 16px" }}>
              <CustomToggleButton value={selectedOption} handleChange={handleChange} options={options} />
            </Grid>

            <Grid xs={12} sx={{ padding: "0px 16px" }}>
              <Grid xs={12} sx={{ padding: "20px 16px", background: "#fff", borderRadius: "10px", display: "flex", flexDirection: "column", gap: "12px" }}>
                <div className="flex gap-3">
                  <div className="w-[121px] h-[128px] rounded-xl border border-[#FFDCDC]">
                    <CustomCarousel animation='fade' size='small'>
                      <img className="w-[121px] h-[128px] object-cover object-center rounded-xl" src={food_image_2} alt="" draggable="false" />
                      <img className="w-[121px] h-[128px] object-cover object-center rounded-xl" src={food_image_2} alt="" draggable="false" />
                      <img className="w-[121px] h-[128px] object-cover object-center rounded-xl" src={food_image_2} alt="" draggable="false" />
                    </CustomCarousel>
                  </div>
                  <div className='flex-grow flex flex-col justify-between'>
                    <div className='flex items-center gap-2 font-medium text-base'>
                      <img
                        src={cargo_restraunt_logo}
                        alt="Logo"
                        className="rounded-full  w-[38px] h-[38px] object-cover"
                      />
                      <h1 className="font-QuickSand font-700 text-[14px]  text-black leading-[19.2px]">
                        {/* {data?.result?.name} */}
                        Cargo Restaurant
                      </h1>
                    </div>
                    <div>
                      <div className="flex items-center gap-1">
                        <h3 className="font-QuickSand font-500 text-[12px] text-[#484848] underline md:max-w-full truncate">
                          {/* {data?.result?.address} */}
                          Dubai Marina
                        </h3>
                        <ArrowDropDownOutlinedIcon sx={{ cursor: "pointer" }} />
                      </div>

                      <div className="items-center gap-1 justify-between flex">
                        <span className="text-[12px] text-[#777777]">10 - 20 min</span>
                        <div className="text-[#377931] flex items-center gap-[3px] ml-2">
                          <Star sx={{ height: "15px", width: "15px" }} />
                          <span className="font-normal">4.6</span>
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center justify-between'>
                      <div className="flex gap-2">
                        <a href={"*"} target="_blank" rel="noreferrer">
                          <FacebookRoundedIcon sx={{ color: "#3f3f3f", height: "20px" }} />
                        </a>
                        <a href={"*"} target="_blank" rel="noreferrer">
                          <InstagramIcon sx={{ color: "#3f3f3f", height: "20px" }} />
                        </a>
                        <a href={"*"} target="_blank" rel="noreferrer">
                          <YouTubeIcon sx={{ color: "#3f3f3f", height: "20px" }} />
                        </a>
                      </div>

                      <div className=" flex justify-end">
                        {/* <ShareIcon sx={{ color: "#3f3f3f" }} /> */}
                        <a href={"*"} target="_blank" rel="noreferrer">
                          <img src={share} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {/* TODO: add restaurant info */}
                  <BasicButton color="genieRed" sx={{ width: "100%", padding: "12px 0px" }} onClick={() => navigate(`/${restaurantId}`)}>
                    <span className="normal-case text-white font-medium text-base">
                      Go To Menu
                    </span>
                  </BasicButton>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid xs={12}>
            <div className='bg-[#FFD951] rounded-tl-[20px] rounded-tr-[20px] p-3'>
              <CustomCarousel>
                <div>
                  <img className='w-full h-[173px] object-cover object-center rounded-tr-[20px] rounded-tl-[20px]' src={offer_1} alt="" draggable="false" />
                  <div className='flex flex-col items-center justify-start mt-8 h-[128px]'>
                    <p className='font-medium text-[24px] leading-[25px] text-center max-w-[256px] line-clamp-3'>Explore personalized recommendations with Genie</p>
                  </div>
                </div>
                <div>
                  <img className='w-full h-[173px] object-cover object-center rounded-tr-[20px] rounded-tl-[20px]' src={offer_1} alt="" draggable="false" />
                  <div className='flex flex-col items-center justify-start mt-8 h-[128px]'>
                    <p className='font-medium text-[24px] leading-[25px] text-center max-w-[256px] line-clamp-3'>Explore personalized recommendations with Genie</p>
                  </div>
                </div>
                <div>
                  <img className='w-full h-[173px] object-cover object-center rounded-tr-[20px] rounded-tl-[20px]' src={offer_1} alt="" draggable="false" />
                  <div className='flex flex-col items-center justify-start mt-8 h-[128px]'>
                    <p className='font-medium text-[24px] leading-[25px] text-center max-w-[256px] line-clamp-3'>Explore personalized recommendations with Genie</p>
                  </div>
                </div>
              </CustomCarousel>
            </div>
          </Grid>
        </div>
      </Grid>
    </>
  )
}

export default Takeout