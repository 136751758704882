import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import { TextInput } from "../../../components/InputBox";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import BasicButton from "../../../components/Button";
import DragAndDropCoverPic from "../../../components/Dropzone";
import HeaderWrapper from "../../../components/headerwrapper/HeaderWrapper";
import { useAuth, useRestaurant } from "../../../utils/hooks";
import { useNavigate, useParams } from "react-router";
import InlineLoader from "../../../components/loader/commonLoader";
import WatermarkImage from "./Watermark";
import { titleCase } from "../../../utils/helper";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import backArrow from '../../../assets/Icons/backArrow.svg'
import asterisk from '../../../assets/Icons/asterisk.svg'
import plus from '../../../assets/Icons/plus.svg'
import MultiSelectDropdown from "../../../components/InputBox/MultiSelect.tsx";
import ButtonWithIcon from "../../../components/ButtonWithIcon/index.jsx";
import { SpiceIcon } from "../../../utils/helper/spiceIcon.js";
import ReusableIOSSwitch from "../../../components/switch/IOSSwitch";
import edit from '../../../assets/Icons/edit.svg'
import LogOutButton from "../../../components/Logout/index.jsx";
import cancel from "../../../assets/Icons/cancel.svg"
import { BasicModal } from "../../../components/Modal/Modal.jsx";
import CustomizationModal from "./modals/CustomizationModal.jsx";
import CategoryModal from "./modals/CategoryModal.jsx";
import TypeModal from "./modals/TypeModal.jsx";
import IngredientsModal from "./modals/IngredientsModal.jsx";
import { CustomTextInput } from "../../../components/InputBox/CustomInput.jsx";
import { toast } from "react-toastify";
import deleteIcon from "../../../assets/Icons/delete.svg"

function AddMenu() {
  const [spice, setSpice] = useState(0);
  const [hover, setHover] = useState(0);
  const [ingredients, setIngredients] = useState([]);
  const [allergicIngredients, setAllergicIngredients] = useState([]);

  const navigate = useNavigate();
  const menuId = useParams();

  const { setFile } = useAuth();

  const {
    useGetTabList,
    OnAddMenu,
    useGetFoodType,
    // useGetSpiceLevel,
    // headerText,
    setHeaderText,
    listArray,
    setListArray,
    openModal,
    setOpenModal,
    modelText,
    setModelText,
    AddCategory,
    AddType,
    HandleDeleteCategoryList,
    HandleDeleteType,
    AddSpice,
    HandleDeleteSpice,
    useGetMenuById,
    // setAddList,
    OnEditMenu,
    useAiMenuDetails,
    // OnEditMenuIngredients,
    setGptCheck,
    // mealTimingOptions,
    AiPhoto,
    setAiPhoto,
    aiPhotoGenerationIsLoading,
    onAIPhotoGeneration,
  } = useRestaurant();

  const { data: categoryList, isLoading: categoryListIsLoading } =
    useGetTabList();
  const { data: menuById, isLoading: menuByIdLoading } = useGetMenuById(menuId?.id);
  const { data: foodType, isLoading: foodTypeLoading } = useGetFoodType();
  // const { data: spiceLevel, isLoading: spiceLevelLoading } = useGetSpiceLevel();
  // const { data: allergies, isLoading: allergiesLoading } = useGetAllergies();
  const [searchValue, setSearchValue] = useState("");
  /**
   * Initializes form control functions for handling form submission, control over form fields,
   * resetting form values, watching form field values, and setting form field values.
   */
  const { handleSubmit, control, reset, watch, setValue, getValues, setError, trigger } = useForm({
    mode: "all",
    defaultValues: {
      // category: {
      //   label: "",
      //   value: "",
      // },
      image: {
        url: "",
        AiGenerated: false,
      },
      item_name: "",
      description: "",
      oPrice: "",
      ingredients: [],
      delivery: false,
      pickUp: false,
      dineIn: true,
      allergies: [],
      customization: [],
      spicinessLevel: 0,
      FPrice: "",
      dPrice: "",
      min: "",
      max: "",
      // type: {
      //   label: "",
      //   value: "",
      // },
      Cuisine: {
        label: "",
        value: "",
      },
    },
  });

  /**
   * Retrieves the value of the "OPrice" and "Discount" fields from the form data.
   * @returns The original price and discount price values from the form data.
   */
  const originalPrice = watch("oPrice");
  const discountPrice = watch("dPrice");
  const menuNameWatch = watch("item_name");

  const {
    data: dishIngredientsListGpt,
    isLoading: dishIngredientsListGptLoading,
  } = useAiMenuDetails(menuNameWatch);

  /**
   * useEffect hook that resets form values based on menuById data when menuId.id is truthy.
   * @param {Function} reset - Function to reset form values.
   * @param {Object} menuById - The menu data used to populate form values.
   * @returns None
   */
  useEffect(() => {
    if (menuId?.id) {
      setIngredients([menuById?.result?.ingredients].reduce((array, curr) => {
        if (curr) {
          curr.forEach(item => {
            if (!array.includes(item)) {
              array.push(item);
            }
          });
        }
        return array;
      }, []));
      setAllergicIngredients([menuById?.result?.allergies].reduce((array, curr) => {
        if (curr) {
          curr.forEach(item => {
            if (!array.includes(item)) {
              array.push(item);
            }
          });
        }
        return array;
      }, []));
      reset({
        _id: menuById?.result?._id,
        image: {
          url: menuById?.result?.image?.url,
          AiGenerated: menuById?.result?.image?.AiGenerated,
        },
        item_name: menuById?.result?.item_name,
        description: menuById?.result?.description,
        mealTiming: menuById?.result?.mealTiming?.map((item) => ({
          value: item,
          label: item,
        })),
        oPrice: menuById?.result?.oPrice,
        dPrice: menuById?.result?.dPrice,
        FPrice:
          menuById?.result?.oPrice - menuById?.result?.dPrice,
        min: menuById?.result?.portion?.min,
        max: menuById?.result?.portion?.max,

        category: {
          value: menuById?.result?.foodCategory?._id,
          label: menuById?.result?.foodCategory?.food_category,
        },
        type: {
          value: menuById?.result?.foodType?._id,
          label: menuById?.result?.foodType.food_type,
        },
        spicinessLevel: menuById?.result?.spicinessLevel,
        ingredients: menuById?.result?.ingredients.map(item => { return { value: item, label: item } }),
        allergies: menuById?.result?.allergies.map(item => { return { value: item, label: item } }),
        customization: menuById?.result?.customization?.map(custom => {
          return {
            isRequired: custom.isRequired,
            title: custom.title,
            items: custom.items.map(option => {
              return {
                itemName: option.itemName,
                price: option.price,
              }
            })
          }
        }),
        delivery: menuById?.result?.delivery,
        pickUp: menuById?.result?.pickUp,
        dineIn: menuById?.result?.dineIn,
      });
      setSpice(menuById?.result?.spicinessLevel);
      // setIsLoading(false);
    }

  }, [menuById, menuId?.id, reset]);

  /**
   * Handles form submission by checking if a menu ID exists. If it does, it calls the OnEditMenu function
   * with the form data, otherwise it calls the OnAddMenu function with the form data.
   */
  const onSubmit = (data) => {
    trigger()
    data["ingredients"] = getValues("ingredients").map((item) => item.value)
    data["allergies"] = getValues("allergies").map((item) => item.value)
    data["dPrice"] = parseInt(getValues("dPrice") || 0)
    if (data.image.url === "") {
      toast.error("Image is required")
    }
    else {
      if (menuId?.id) {
        OnEditMenu(data);
      } else {
        OnAddMenu(data);
      }
      navigate("/menu");
      setFile(null)
    }

  };

  const onErrors = (errors) => {
    console.log(errors);
  };

  /**
   * Handles the category list by setting the open modal state to true and updating the list array,
   * header text, and model text based on the category list result.
   * If the category list is not loading, it opens the modal and updates the list array with
   * the mapped values of food categories and their corresponding IDs.
   */
  const handleCategory = () => {
    if (!categoryListIsLoading) {
      setOpenModal(true);
      setAiPhoto([]);
      setListArray(
        categoryList?.result?.map((item) => ({
          label: item?.food_category,
          value: item?._id,
        }))
      );
      setHeaderText("category");
      setModelText("category");
    }
  };
  /**
   * Handles the spiciness level by setting the open modal flag to true,
   * populating the list array with spice level data, and setting the header and model text.
   * @returns None
   */
  // const handleSpicinessLevel = () => {
  //   if (!spiceLevelLoading) {
  //     setOpenModal(true);
  //     setListArray(
  //       spiceLevel?.result?.map((item) => ({
  //         label: item?.spice_level,
  //         value: item?._id,
  //       }))
  //     );
  //     setHeaderText("spice level");
  //     setModelText("spice");
  //   }
  // };
  /**
   * Handles the display of allergies by setting the necessary states to show a modal
   * with a list of allergies.
   * If allergies are not loading, it sets the open modal state to true, sets the list
   * array with the mapped values of allergies, sets the header text to "allergy ingredient",
   * and sets the model text to "allergy".
   */
  const handleAllergies = () => {
    setOpenModal(true);
    setModelText("allergy");
  };
  /**
   * Handles the type of food by setting the open modal flag to true,
   * populating the list array with food types, setting the header text
   * to "type of food", and setting the model text to "type".
   * @returns None
   */
  const handleType = () => {
    if (!foodTypeLoading) {
      setOpenModal(true);
      setListArray(
        foodType?.result?.map((item) => ({
          label: item?.food_type,
          value: item?._id,
        }))
      );
      setHeaderText("type of food");
      setModelText("type");
    }
  };

  const [editableData, setEditableData] = useState(null)
  const [editableIndex, setEditableIndex] = useState(null)
  const handleCustomizations = (data, index) => {
    setEditableData(data)
    setEditableIndex(index)
    setModelText("customizations");
  }

  const handleIngredients = () => {
    setOpenModal(true);
    setModelText("ingredients");
  }
  /**
   * Handles the form submission based on the value of modelText.
   * If modelText is "category", calls AddCategory function.
   * If modelText is "type", calls AddType function.
   * If modelText is "spice", calls AddSpice function.
   * If modelText is "allergy", calls addAllergy function.
   * @returns The result of the corresponding function based on the value of modelText.
   */
  const HandleSubmit = (data, index) => {
    if (modelText === "category") {
      return AddCategory();
      // setOpenModal(false)
    }
    if (modelText === "type") {
      return AddType();
    }
    if (modelText === "spice") {
      return AddSpice();
    }
    if (modelText === "allergy") {
      // return addAllergy();
      setAllergicIngredients(data)
      setOpenModal(false);

      // return OnEditMenuIngredients(
      //   menuById?.result,
      //   dishIngredientsListGpt?.result && dishIngredientsListGpt
      //     ? dishIngredientsListGpt?.result
      //     : menuById?.result?.items
      // );
    }
    if (modelText === "ingredients") {
      // console.log("original", ingredients)
      // console.log("data", data)
      setIngredients(data)
      setOpenModal(false)
    }
    if (modelText === "customizations") {
      if (!(index >= 0)) {
        setValue("customization", [
          ...getValues("customization"),
          data
        ])
      } else {
        const customization = getValues("customization")
        customization[index] = data
        setValue("customization", [...customization])
        setEditableIndex(null)
        setEditableData(null)
      }

      setOpenModal(false)
    }
  };
  /**
   * Handles the deletion of data based on the modelText value.
   * @param {{any}} data - The data to be deleted.
   * @returns The result of the corresponding delete function based on the modelText value.
   */
  const handleDelete = (data) => {
    if (modelText === "category") {
      return HandleDeleteCategoryList(data);
    }
    if (modelText === "type") {
      return HandleDeleteType(data);
    }
    if (modelText === "spice") {
      return HandleDeleteSpice(data);
    }
    if (modelText === "allergy") {
      if (dishIngredientsListGpt?.result && dishIngredientsListGpt) {
        dishIngredientsListGpt?.result.ingredients.splice(data, 1);
        setListArray(
          dishIngredientsListGpt?.result.ingredients?.map((item, index) => ({
            label: item,
            value: index,
          }))
        );
      }
      if (menuById?.result?.items?.ingredients) {
        menuById.result.items.ingredients.splice(data, 1);
        setListArray(
          menuById.result.items.ingredients?.map((item, index) => ({
            label: item,
            value: index,
          }))
        );
      }

      // return HandleDeleteAllergy(data);
    }
  };

  useEffect(() => {
    setValue("description", dishIngredientsListGpt?.result.description);
    setListArray(
      dishIngredientsListGpt?.result?.ingredients?.map((item) => ({
        label: item,
        value: item,
      }))
    );
    setIngredients(dishIngredientsListGpt?.result?.ingredients || []);
    setValue("ingredients", dishIngredientsListGpt?.result?.ingredients.map(item => { return { value: item, label: item } }) || [])
  }, [dishIngredientsListGpt?.result, setValue, setListArray]);

  /**
   * useEffect hook that calculates the final price based on the original price and discount price,
   * and updates the "FPrice" value using the setValue function.
   * @param {Function} setValue - The function to update the value in the state.
   * @param {number} originalPrice - The original price of the item.
   * @param {number} discountPrice - The discount price of the item.
   * @returns None
   */
  useEffect(() => {
    const finalPrice = originalPrice - discountPrice;
    setValue("FPrice", finalPrice);
  }, [originalPrice, discountPrice, setValue]);

  useEffect(() => {
    if (!aiPhotoGenerationIsLoading && AiPhoto[0]?.url && menuNameWatch !== "") {
      setValue("image", {
        url: AiPhoto[0]?.url,
        AiGenerated: true,
      });
    }
  }, [aiPhotoGenerationIsLoading, AiPhoto, setValue]);

  const handleRegenerate = () => {
    setValue("image", {
      url: "",
      AiGenerated: false,
    });
    const dish_name = watch("item_name");
    if (!dish_name || dish_name.trim() === "") {
      setError("item_name", {
        type: "manual",
        message: "Please enter an item name first"
      });
      return;
    }
    const description = watch("description");
    onAIPhotoGeneration({
      dish_name,
      description,
    });
  };

  useEffect(() => {
    if (!dishIngredientsListGptLoading && dishIngredientsListGpt) {
      trigger("item_name");
      trigger("description");
      trigger("image");
      trigger("ingredients");
    }
  }, [dishIngredientsListGptLoading, dishIngredientsListGpt, trigger]);

  return (
    <HeaderWrapper>
      {/* {dishIngredientsListGptLoading || menuByIdLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin" />
        </div>
      ) : null} */}

      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <div
          className="overflow-auto"
          style={{
            height: "100vh",
          }}
        >
          <Grid spacing={2} className="">

            <Grid xs={12} justifyContent="space-between">
              <Grid item xs={12} sx={{ borderBottom: "2px solid #1C1C1C1A" }}>
                <div className="  flex  items-center    justify-between py-4 px-8 ">
                  <h3 className="hidden sm:flex gap-3">
                    <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                    <span className="text-[#1C1C1C66]">/</span>
                    <span>Menu Management</span>
                  </h3>
                  <div className="max-w-[400px]  flex gap-4 items-center ">
                    <TextInput
                      variant="outlined"
                      preContent={<SearchIcon sx={{ color: "#c8c8c8" }} />}
                      transparentBorder
                      borderRadius="10px"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    //   label="Description(optional)"
                    />
                    <NotificationsNoneOutlinedIcon />
                    <LogOutButton />
                  </div>
                </div>
              </Grid>
            </Grid>

            {dishIngredientsListGptLoading || menuByIdLoading ? (
              <div className="flex flex-col justify-center items-center h-screen">
                <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
                <p className="text-gray-600">
                  {dishIngredientsListGptLoading ? "Generating menu item..." : "Loading..."}
                </p>
              </div>
            ) :

              <Grid xs={12} container>
                <Grid container xs={12} justifyContent="space-between" className="pb-6 mt-2 px-8" sx={{ borderBottom: "2px solid #1C1C1C1A", alignItems: "center" }}>
                  <Grid item>
                    <h1 className="text-[25px] lg:text-[30px] text-[#3e3e3e] font-medium flex gap-4">
                      <img src={backArrow} alt="" className="cursor-pointer" onClick={() => navigate(-1)} />
                      {menuById ? "Edit Menu Item" : "Add New Menu Item"}
                    </h1>
                  </Grid>

                  <Grid sx={{ display: "flex", gap: "10px" }}>
                    <BasicButton
                      variant="outlined"
                      color="genieRed"
                      sx={{
                        textTransform: "none",
                        // padding: "3px 10px",
                        display: "flex",
                        margin: "auto"
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </BasicButton>
                    <BasicButton
                      color="genieRed"
                      type="submit"
                      sx={{
                        textTransform: "none",
                        // padding: "3px 10px",
                        display: "flex",
                        margin: "auto",
                        color: "white",
                      }}
                    >
                      {menuId?.id ? "Update Item" : "Save Item"}
                    </BasicButton>
                  </Grid>
                </Grid>
                {/* {process.env.REACT_APP_ENVIRONMENT === "local" && <Button sx={{ position: "absolute" }} onClick={() => { console.log(getValues()) }}>Check Data</Button>} */}
                <Grid container xs={12} sx={{ height: "calc(100vh - 152px)" }}>
                  {/* Left container */}
                  <Grid xs={12} md={6} className="px-8 pt-8 pb-8 lg:pr-16 md:border-r-2 gap-8">
                    <div className="flex flex-col gap-8">
                      {/* Item Image container */}
                      <Grid item xs={12}>
                        {aiPhotoGenerationIsLoading ? (
                          <Grid xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <InlineLoader />
                          </Grid>
                        ) : (
                          <div>
                            <Controller
                              name="image"
                              control={control}
                              required={true}
                              rules={{
                                required: "Image is required"
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return !field?.value?.AiGenerated ? (
                                  <DragAndDropCoverPic
                                    {...field}
                                    displayPicture={field.value?.url}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    handleRegenerate={handleRegenerate}
                                  />
                                ) : (
                                  <WatermarkImage
                                    src={field.value?.url}
                                    watermarkText="AI"
                                    setValue={setValue}
                                    handleRegenerate={handleRegenerate}
                                  />
                                );
                              }}
                            />
                          </div>
                        )}
                      </Grid>

                      {/* Name */}
                      <Grid item xs={12}>
                        <Grid item className="mr-5">
                          <Controller
                            name="item_name"
                            control={control}
                            rules={{
                              required: "Menu Name is Required",
                              pattern: {
                                value: /^(?=.*[a-zA-Z])|(?=.*[a-zA-Z])(?=.*\d)(?!.*[\W_]).{2,}|(?=.*[a-zA-Z])(?=.*[\W_])(?!.*\d).{2,}$/,
                                message: "Menu Name cannot be special characters only"
                              }
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <Grid className="flex justify-between relative">
                                  <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">ITEM NAME<span className="text-[#b80e0c]">*</span></p>
                                  </Grid>
                                  <Grid xs={8}>
                                    <CustomTextInput
                                      {...field}
                                      variant="outlined"
                                      placeholder="Enter Item Name"
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      onChange={(e) => {
                                        const titleCasedValue = titleCase(e.target.value); // Remove special characters
                                        field.onChange(titleCasedValue);
                                      }}
                                    />
                                    <Tooltip title="Using Gen AI generate item details"
                                      arrow
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            bgcolor: 'white',
                                            '& .MuiTooltip-arrow': {
                                              color: 'white',
                                            },
                                            color: "#4d4d4d",
                                            width: "8rem",
                                            textAlign: "center",
                                          },
                                          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
                                        },
                                      }}
                                      placement="top"
                                    >
                                      <div className="bg-[#b50e0e] p-[3px] rounded-[0.2rem] cursor-pointer absolute right-2 top-[7.35px]">
                                        <img src={asterisk} onClick={() => {
                                          const itemName = getValues("item_name");
                                          if (itemName && itemName.trim() !== "") {
                                            setGptCheck(true);
                                          } else {
                                            setError("item_name", {
                                              type: "manual",
                                              message: "Please enter an item name first"
                                            });
                                          }
                                        }} alt="AI Generate" />
                                      </div>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>

                      {/* Category */}
                      <Grid item xs={12}>
                        <div className="">
                          <Controller
                            name="category"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Category is Required"
                              },
                              validate: (value) => {
                                return value && value.value && value.label ? true : "Category is Required";
                              },
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <Grid className="flex justify-between">
                                  <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">CATEGORY<span className="text-[#b80e0c]">*</span></p>
                                  </Grid>
                                  <Grid xs={8}>
                                    <SearchableDropDown
                                      {...field}
                                      sx={{ width: "auto" }}
                                      variant="outlined"
                                      placeholder="Select Category"
                                      options={categoryList?.result?.map((item) => ({
                                        value: item?._id,
                                        label: item?.food_category,
                                      }))}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            }}
                          />
                        </div>
                        <Grid xs={8} marginLeft={"auto"} marginTop={"8px"}>
                          <ButtonWithIcon icon={plus} onClick={handleCategory} text="Add new category" />
                        </Grid>
                      </Grid>

                      {/* Description */}
                      <Grid item xs={12}>
                        <div className="">
                          <Controller
                            name="description"
                            control={control}
                            rules={{
                              required: "Description is Required",
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <Grid className="flex items-start">
                                  <Grid xs={4}>
                                    <p className="text-[#757373] basis-40 font-semibold">DETAILS<span className="text-[#b80e0c]">*</span></p>
                                  </Grid>
                                  <Grid xs={8}>
                                    <CustomTextInput
                                      {...field}
                                      variant="outlined"
                                      // label="Description"
                                      placeholder="Enter Description"
                                      error={!!error}
                                      multiline
                                      rows={3}
                                      helperText={error ? error.message : null}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            }}
                          />
                        </div>
                      </Grid>

                      {/* Type */}
                      <Grid item xs={12}>
                        <Controller
                          name="type"
                          control={control}
                          rules={{
                            required: "Type is Required",
                          }}
                          render={({ field, fieldState: { error } }) => {
                            return (
                              <Grid className="flex items-center">
                                <Grid xs={4}>
                                  <p className="text-[#757373] basis-40 font-semibold">TYPE<span className="text-[#b80e0c]">*</span></p>
                                </Grid>
                                <Grid xs={8}>
                                  {/* {console.log(error)} */}
                                  <SearchableDropDown
                                    {...field}
                                    variant="outlined"
                                    // label="Type"
                                    placeholder="Select type"
                                    options={foodType?.result?.map((item) => ({
                                      value: item?._id,
                                      label: item?.food_type,
                                    }))}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                </Grid>
                              </Grid>
                            );
                          }}
                        />
                        <Grid xs={8} marginLeft={"auto"} marginTop={"8px"}>
                          <ButtonWithIcon icon={plus} onClick={handleType} text="Add new type" />
                        </Grid>

                      </Grid>

                      {/* Customization */}
                      <Grid item xs={12}>
                        <Grid className="flex items-center">
                          <Grid xs={4}>
                            <p className="text-[#757373] basis-40 font-semibold">CUSTOMIZATION</p>
                          </Grid>
                          <Grid xs={8} marginLeft={""}>
                            <ButtonWithIcon
                              icon={plus}
                              onClick={() => {
                                setOpenModal(true)
                                handleCustomizations()
                              }}
                              text="Add new customization" />
                          </Grid>
                        </Grid>
                        <Grid xs={8} marginLeft={"auto"} sx={{ display: "flex", flexDirection: "column", height: "fit-content", marginTop: "8px", gap: "8px" }}>

                          {watch("customization") ? watch("customization").map((custom, index) => (
                            <Box key={index} sx={{ width: "100%", backgroundColor: "#f7f7f7", padding: "8px 12px", color: "#505050", border: "1px solid #e2e8f0", borderRadius: "4px" }}>
                              <div className="flex items-center justify-between font-semibold">
                                <p>{custom.title}</p>
                                <div className="flex items-center gap-2">
                                  <img src={edit} onClick={
                                    () => {
                                      setOpenModal(true)
                                      handleCustomizations(custom, index)
                                    }
                                  } alt="Edit" className="cursor-pointer w-[20px]" />
                                  <img src={deleteIcon} alt="delete customization"
                                    onClick={
                                      () => {
                                        const updatedCustomizations = [...watch("customization")];
                                        updatedCustomizations.splice(index, 1);
                                        setValue("customization", updatedCustomizations);
                                      }
                                    }
                                    className="cursor-pointer w-[20px]" />
                                </div>
                              </div>
                              <div className="mt-6">
                                {custom.items ? custom.items.map(item => (
                                  <div className="flex items-center justify-between">
                                    <p>{item.itemName}</p>
                                    <p className="font-semibold">+{item.price}</p>
                                  </div>
                                )) : <></>}
                              </div>
                            </Box>
                          )) : <></>}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  {/* Right Container */}
                  <Grid sm={12} md={6} className="px-8 lg:pl-16 md:py-8 gap-8">
                    <div className="flex flex-col gap-8">
                      {/* Pricing */}
                      <Grid item xs={12}>
                        <Grid container spacing={1} className="">
                          <Grid item xs={4}>
                            <Controller
                              name="oPrice"
                              control={control}
                              rules={{
                                required: "Price is Required",
                                validate: value => {
                                  trigger("dPrice")
                                  return value > 0 || "Original price must be greater than zero"
                                }
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <>
                                    <p className="text-[#757373] basis-40 font-semibold leading-4 mb-2">ORIGINAL PRICE<br />(AED)<span className="text-[#b80e0c]">*</span></p>
                                    <CustomTextInput
                                      {...field}
                                      type="number"
                                      variant="outlined"
                                      // label={"ORIGINAL PRICE(AED)"}
                                      placeholder="0.00"
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </>
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Controller
                              name="dPrice"
                              control={control}
                              rules={{
                                validate: value => {
                                  if (value === "" || value === null) return true; // Allow empty discount
                                  const numValue = parseFloat(value);
                                  const originalPrice = parseFloat(watch("oPrice"));
                                  return (numValue >= 0 && numValue <= originalPrice) || "Discount must be between 0 and the original price";
                                },
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <>
                                    <p className="text-[#757373] basis-40 font-semibold leading-4 mb-2">DISCOUNT<br />(AED)</p>
                                    <CustomTextInput
                                      {...field}
                                      type="number"
                                      variant="outlined"
                                      placeholder="0.00"
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </>
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Controller
                              name="FPrice"
                              control={control}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <>
                                    <p className="text-[#757373] basis-40 font-semibold leading-4 mb-2">FINAL PRICE<br />(AED)<span className="text-[#b80e0c]">*</span></p>
                                    <CustomTextInput
                                      {...field}
                                      disabled={true}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </>
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Portion */}
                      <Grid item xs={12}>
                        <Grid className="flex items-center">
                          <Grid xs={5}>
                            <p className="text-[#757373] font-semibold">PORTION<span className="text-[#b80e0c]">*</span></p>
                          </Grid>
                          <Grid container xs={7}>
                            <Grid item xs={5.8}>
                              <Controller
                                name="min"
                                control={control}
                                rules={{
                                  required: "Min cannot be empty",
                                  validate: {
                                    greaterThanZero: value => value > 0 || "Min should be greater than zero",
                                    lessThanMax: (value) => {
                                      const maxValue = parseFloat(getValues("max")); // Assuming you have access to getValues
                                      return value <= maxValue || "Min should be less than or equal to Max";
                                    }
                                  }
                                }}
                                render={({ field, fieldState: { error } }) => {
                                  return (
                                    <CustomTextInput
                                      {...field}
                                      type="number"
                                      variant="outlined"
                                      placeholder="0"
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      postContent={
                                        <h1 className=" text-sm text-[#515151] bg-white">
                                          Min.
                                        </h1>
                                      }
                                      sx={{ padding: "0 20px" }}
                                    />
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={5.8} marginLeft={"auto"}>
                              <Controller
                                name="max"
                                control={control}
                                rules={{
                                  required: "Max cannot be empty",
                                  validate: {
                                    greaterThanZero: value => value > 0 || "Max should be greater than zero",
                                    lessThanMin: (value) => {
                                      trigger("min")
                                      const minValue = parseFloat(getValues("min")); // Assuming you have access to getValues
                                      return value >= minValue || "Max should be greater than or equal to Min";
                                    },
                                  }
                                }}
                                render={({ field, fieldState: { error } }) => {
                                  return (
                                    <CustomTextInput
                                      {...field}
                                      type="number"
                                      variant="outlined"
                                      placeholder="0"
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      postContent={
                                        <h1 className=" text-sm text-[#515151] bg-white">
                                          Max.
                                        </h1>
                                      }
                                      sx={{ padding: "0 20px" }}
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Spiciness */}
                      <Grid item xs={12}>
                        <div className="">
                          <Controller
                            name="spicinessLevel"
                            control={control}
                            rules={{
                              required: "Spice level is Required",
                            }}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <Grid className="flex items-center">
                                  <Grid xs={5}>
                                    <p className="text-[#757373] font-semibold">SPICINESS LEVEL</p>
                                  </Grid>
                                  <Grid xs={7} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div className="flex gap-1">
                                      {[...Array(5)].map((star, index) => {
                                        const currentSpice = index + 1;
                                        return (
                                          <label key={index}>
                                            <input
                                              type="radio"
                                              name="rating"
                                              value={currentSpice}
                                              className="hidden"
                                              onClick={() => {
                                                setSpice(currentSpice)
                                                setValue("spicinessLevel", currentSpice)
                                              }}
                                            />
                                            <SpiceIcon
                                              currentSpice={currentSpice}
                                              hover={hover}
                                              rating={spice}
                                              setHover={setHover}
                                            />
                                          </label>
                                        );
                                      })}
                                    </div>
                                    <img src={cancel} alt="clear spice level" className={`cursor-pointer ${spice > 0 ? "block" : "hidden"}`} onClick={() => {
                                      setSpice(0)
                                      setValue("spicinessLevel", 0)
                                    }} />
                                  </Grid>
                                </Grid>
                              );
                            }}
                          />
                        </div>
                      </Grid>

                      {/* Ingredients */}
                      <Grid item xs={12}>
                        <Grid className="flex">
                          <Grid xs={5}>
                            <p className="text-[#757373] basis-40 font-semibold mt-[10.3px]">INGREDIENTS<span className="text-[#b80e0c]">*</span></p>
                          </Grid>
                          <Grid xs={7}>
                            <Controller
                              name="ingredients"
                              control={control}
                              rules={{
                                required: "Ingredients are Required",
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <MultiSelectDropdown
                                    name="ingredients"
                                    {...field}
                                    control={control}
                                    options={ingredients.map((ingredient) => ({
                                      value: ingredient,
                                      label: ingredient,
                                    }))}
                                    placeholder="Select Ingredients"
                                    width="w-full"
                                  />
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid xs={7} marginLeft={"auto"} marginTop={"8px"}>
                          <ButtonWithIcon icon={plus} onClick={handleIngredients} text="Add new ingredients" />
                        </Grid>
                      </Grid>

                      {/* Allergies */}
                      <Grid item xs={12}>
                        <Grid className="flex">
                          <Grid xs={5}>
                            <p className="text-[#757373] basis-40 font-semibold mt-[3.8px]">INGREDIENTS <br /> CAUSING ALLERGIES</p>
                          </Grid>
                          <Grid xs={7}>
                            <Controller
                              name="allergies"
                              control={control}
                              // rules={{
                              //   required: "Ingredients are Required",
                              // }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <MultiSelectDropdown
                                    name="allergies"
                                    {...field}
                                    control={control}
                                    options={allergicIngredients.map((ingredient) => ({
                                      value: ingredient,
                                      label: ingredient,
                                    }))}
                                    placeholder="Select Ingredients"
                                    width="w-full"
                                  />
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid xs={7} marginLeft={"auto"} marginTop={"8px"}>
                          <ButtonWithIcon icon={plus} onClick={handleAllergies} text="Add new ingredients" />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid className="flex items-start">

                          <Grid xs={5}>
                            <p className="text-[#757373] basis-40 font-semibold">AVAILABILITY FOR<span className="text-[#b80e0c]">*</span></p>
                          </Grid>
                          <Grid xs={7}>
                            <div className="flex flex-col gap-1">
                              {/* <div className="flex items-start gap-2">
                                <div>
                                  <Controller
                                    name="delivery"
                                    control={control}
                                    render={
                                      ({ field, fieldState: { error } }) => {
                                        return (
                                          <ReusableIOSSwitch
                                            checked={field.value}
                                            onChange={field.onChange}
                                          />
                                        );
                                      }
                                    }
                                  />
                                </div>
                                <div className="font-normal">Delivery</div>
                              </div> */}
                              {/* <div className="flex items-start gap-2">
                                <div>
                                  <Controller
                                    name="pickUp"
                                    control={control}
                                    render={
                                      ({ field, fieldState: { error } }) => {
                                        return (
                                          <ReusableIOSSwitch
                                            checked={field.value}
                                            onChange={field.onChange}
                                          />
                                        );
                                      }
                                    }
                                  />
                                </div>
                                <div className="font-normal">Pick-up</div>
                              </div> */}
                              <div className="flex items-start gap-2">
                                <div>
                                  <Controller
                                    name="dineIn"
                                    control={control}
                                    render={
                                      ({ field, fieldState: { error } }) => {
                                        return (
                                          <ReusableIOSSwitch
                                            checked={field.value}
                                            onChange={field.onChange}
                                          />
                                        );
                                      }
                                    }
                                  />
                                </div>
                                <div className="font-normal">Dine in</div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>

                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
          {openModal && (
            <BasicModal open={openModal} close={() => { setOpenModal(false) }} maxWidth={modelText === "customization" ? "46rem" : "37.5rem"}>
              {modelText === "customizations" && <CustomizationModal close={() => setOpenModal(false)} handleSubmit={HandleSubmit} data={editableData} index={editableIndex} />}
              {modelText === "category" && <CategoryModal data={listArray} close={() => setOpenModal(false)} handleSubmit={HandleSubmit} handleDelete={handleDelete} />}
              {modelText === "type" && <TypeModal data={listArray} close={() => setOpenModal(false)} handleSubmit={HandleSubmit} handleDelete={handleDelete} />}
              {modelText === "ingredients" && <IngredientsModal data={ingredients} setData={setIngredients} close={() => setOpenModal(false)} handleSubmit={HandleSubmit} />}
              {modelText === "allergy" && <IngredientsModal data={allergicIngredients} setData={setAllergicIngredients} close={() => setOpenModal(false)} handleSubmit={HandleSubmit} />}
            </BasicModal>
          )}
        </div>
      </form >
    </HeaderWrapper >
  );
}

export default AddMenu;
