import React, { useContext, createContext, useState, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import {
  ChangePasswordAPI,
  CheckEmailExistOrNot,
  CheckFileUpload,
  PartnerLogIn,
  PartnerSignUp,
  ResetPasswordAPI,
  getAllEstablishmentType,
  getAllServiceType,
  logIn,
  mutateRefreshToken,
} from "../../controllers/auth";
import useToken from "../../hooks/useToken";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LocalStorageService from "../../../Constants/localStorage";
import io from "socket.io-client";
import { getLoginUrl } from "../../helper";

const authContext = createContext(undefined);

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  const [toggleIcon, setToggleIcon] = useState(true);

  const [isRefreshTokenCalled, setIsRefreshTokenCalled] = useState(false);
  const [PageNumber, setPageNumber] = useState(1);
  const [selectedCousine, setSelectedCousine] = useState([]);
  const [selectedEstablishment, setSelectedEstablishmentType] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [s3URl, setS3Url] = useState("");
  const [location, setLocation] = useState({ latitude: "", longitude: "" });
  const [file, setFile] = useState(null);
  const [locationFetched, setLocationFetched] = useState(false)

  const { setToken, saveUser } = useToken();
  const navigate = useNavigate();
  const socket = useMemo(
    () => io(`${process.env.REACT_APP_API_SERVER_URL}`),
    []
  );
  const steps = [
    "Login Details",
    "Restaurant Details",
    "Cuisine Details",
    "Location Details",
    "Timings ",
    "Description & Social Links ",
    "Service type",
    "Online Ordering Link",
  ];

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const LocalStorage = LocalStorageService.getService();
  const userData = LocalStorage.getUser();

  // function for toggling the password show/hide.
  const togglePasswordIcon = () => {
    setToggleIcon(!toggleIcon);
  };

  /**
   * Custom hook that handles user sign-in functionality.
   * @param {{function}} logIn - The function to log in the user.
   * @returns {{function}} mutateSignIn - Function to trigger the sign-in mutation.
   * @returns {{boolean}} isSignInLoading - Flag indicating if the sign-in mutation is in progress.
   */
  const { mutate: mutateSignIn, isLoading: isSignInLoading } = useMutation(
    logIn,
    {
      onSuccess: async (res) => {
        if (res?.data?.result) {
          setToken({
            access_token: res?.data?.result?.accessToken,
            refresh_token: res?.data?.result?.refreshToken,
          });
          saveUser(res?.data?.result);
          axios.defaults.headers.common.Authorization = `Bearer ${res?.data?.result?.accessToken}`;
        }
        navigate("/dashboard");
      },
      onError: (error) => {
        toast.error(error?.response.data.message);
      },
    }
  );

  const onAdminSignIn = (data) => {
    // axios.defaults.headers.common.Authorization = ``;

    mutateSignIn(data);
  };

  const { mutate: mutateResetPassword } = useMutation(ResetPasswordAPI, {
    onSuccess: async (res) => {
      if (res?.data?.statusCode === 200 && res?.data?.result) {
        const data = res?.data?.result?.resetUrl?.split("/");
        const token = data[data?.length - 1];
        navigate(`/changepassword/${token}`);
      }
    },
    onError: (error) => {
      toast.error(error?.response.data.message);
    },
  });

  /**
   * Function to handle admin sign-in.
   * @param {{data}} data - The data containing admin sign-in information.
   * @returns None
   */
  const onResetPassword = (data) => {
    mutateResetPassword(data);
  };

  const { mutate: mutateChangePassword } = useMutation(ChangePasswordAPI, {
    onSuccess: async (res) => {
      if (res?.data?.statusCode === 200 && res?.data?.result) {
        toast.success("Password Changed");
        navigate(`/`);
      }
    },
    onError: (error) => {
      toast.error(error?.response.data.message);
    },
  });

  /**
   * Function to handle admin sign-in.
   * @param {{data}} data - The data containing admin sign-in information.
   * @returns None
   */
  const onChangePassword = (data) => {
    mutateChangePassword(data);
  };

  /**
   * Refreshes the access token using the mutateRefreshToken mutation.
   * If successful, updates the token, sets the Authorization header for axios,
   * and redirects the user to the "/superadmin" page.
   * If an error occurs, clears the token from local storage, displays an error message,
   * and waits for 1 second before performing any further actions.
   */
  const { mutateAsync: refreshAccessToken } = useMutation(mutateRefreshToken, {
    onSuccess: (res) => {
      if (res?.data?.result) {
        setToken({
          access_token: res?.data?.result?.token?.accessToken,
          refresh_token: userData?.refreshToken,
        });
        axios.defaults.headers.common.Authorization = `Bearer ${res?.data?.result?.token?.accessToken}`;
        // window.location.replace("/superadmin");
      }
    },
    onError: (error) => {
      LocalStorageService.clearToken();
      // toast.error(error?.response.data.message);
      setTimeout(() => {
        // window.location.replace("/");
      }, 1000);
    },
  });

  const onRefreshToken = async () => {
    const alterData = {
      userId: userData?._id,
      userType: userData?.userType,
      refreshToken: userData?.refreshToken,
    };
    await refreshAccessToken(alterData);
  };

  /**
   * Handles partner sign-in functionality by making a mutation request to the server.
   * @param {{Object}} data - The partner sign-in data containing username and password.
   * @returns None
   */
  const { mutate: mutatePartnerSignIn, isLoading: isPartnerSignInLoading } =
    useMutation(PartnerLogIn, {
      onSuccess: async (res) => {
        if (res?.data?.result) {
          setToken({
            access_token: res?.data?.result?.accessToken,
            refresh_token: res?.data?.result?.refreshToken,
          });
          saveUser(res?.data?.result);
        }

        axios.defaults.headers.common.Authorization = `Bearer ${res?.data?.result?.accessToken}`;
        if (res?.data?.result?.userType === "restaurant-partner") {
          const url = getLoginUrl(res?.data?.result?.customDomain);

          // Cookies.set('accessToken', res.data.result.accessToken, {  path: '/' ,domain:".dev-frontend.grubgenie.ai",})

          if (res?.data?.result?.accessToken) {
            // window.location.href = getLoginUrl(res?.data?.result?.customDomain);

            window.location.href = `${url}/?token=${encodeURIComponent(
              res?.data?.result?.accessToken
            )}`;
          }
        }
      },
      onError: (error) => {
        toast.error(error?.response.data.message);
      },
    });

  const onPartnerSignIn = (data) => {
    // axios.defaults.headers.common.Authorization = ``;
    const alteredData = {
      email: data?.username,
      password: data?.password,
    };

    mutatePartnerSignIn(alteredData);
  };

  const { mutate: mutatePartnerSignUp } = useMutation(PartnerSignUp, {
    onSuccess: async (res) => {
      if (res?.data?.result) {
        setToken({
          access_token: res?.data?.result?.accessToken,
          refresh_token: res?.data?.result?.refreshToken,
        });
        saveUser(res?.data?.result);
      }
      // setPageNumber(1);
      axios.defaults.headers.common.Authorization = `Bearer ${res?.data?.result?.accessToken}`;
      // if (res?.data?.result?.userType === "restaurant-partner") {
      //   navigate("/");
      // }
      if (res?.data?.result?.userType === "restaurant-partner") {
        const url = getLoginUrl(res?.data?.result?.customDomain);

        // Cookies.set('accessToken', res.data.result.accessToken, {  path: '/' ,domain:".dev-frontend.grubgenie.ai",})

        if (res?.data?.result?.accessToken) {
          // window.location.href = getLoginUrl(res?.data?.result?.customDomain);

          window.location.href = `${url}/?token=${encodeURIComponent(
            res?.data?.result?.accessToken
          )}`;
        }
      }
    },
    onError: (error) => {
      toast.error(error?.response.data.message);
    },
  });

  const onPartnerSignUp = (data) => {
    const alteredData = {
      email: data?.email,
      password: data?.password,
      mobile: data?.phone,
      name: data?.name,
      logoURL: s3URl,
      customDomain: data?.customDomain,
      deliveryRadius: Number(data?.deliveryRadius),
      establishmentType: data?.establishmentType.map(item => item.value),
      serviceType: selectedServiceType,
      cuisineType: selectedCousine,
      dineInPayment: data?.dineInPayment,
      workingHours: data?.Timing?.map(({ _id, ...day }) => ({
        ...day,
        schedule: day.schedule.map(({ _id, ...rest }) => rest)
      })),
      description: data?.Description,
      locationURL: data?.Location,
      address: data?.AddressObj,
      facebookURL: data?.facebookURL,
      instagramURL: data?.instagramURL,
      youtubeURL: data?.youtubeURL,
      zomatoURL: data?.zomatoUrl,
      googleBusinessURL: data?.googleBusinessURL
    };

    // console.log(alteredData)
    mutatePartnerSignUp(alteredData);
  };

  const nextPage = () => {
    setPageNumber(PageNumber + 1);
  };
  const previousPage = () => {
    setPageNumber(PageNumber - 1);
  };

  const { mutate: mutateEmailCheck, isLoading: isEmailCheckLoading } =
    useMutation(CheckEmailExistOrNot, {
      onSuccess: async (res) => {
        nextPage();
      },
      onError: (error) => {
        toast.error(error?.response.data.message);
      },
    });

  const onEmailCheckUp = (data) => {
    mutateEmailCheck(data);
  };

  const useGetEstablishmentType = () =>
    useQuery(["getAllEstablishmentType"], () => getAllEstablishmentType(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => {
        setSelectedEstablishmentType(data?.result);
      },
    });
  const useGetServiceType = () =>
    useQuery(["getAllServiceType"], () => getAllServiceType(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => { },
    });

  const { mutate: mutateFileUpload, isLoading: isFileUploadLoading } =
    useMutation(CheckFileUpload, {
      onSuccess: async (res) => {
        setS3Url(res?.data?.result?.s3URL);
      },
      onError: (error) => {
        toast.error(error?.response.data.message);
      },
    });

  const onFileUploadUp = (data) => {
    if (data?.logo) {
      const { file, name, width, height } = data.logo;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", name);
      formData.append("width", width);
      formData.append("height", height);

      mutateFileUpload(formData);
    }
  };

  /**
   * Axios interceptor to handle request and response errors.
   * @param {Object} config - The request configuration object.
   * @param {Object} error - The error object.
   * @returns {Promise} A promise that resolves the response or rejects the error.
   */
  axios.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const status = error.response.status;
      const message = error.response.data.message;
      if (error?.response) {
        if (
          originalRequest.url ===
          "https://dev-backend.grubgenie.ai/v1/admin/auth/refresh" ||
          originalRequest.url ===
          "https://dev-backend.grubgenie.ai/v1/partner/auth/refresh"
        ) {
          // If it's a refresh token request, just return the error
          return Promise.reject(error);
        }
        if (
          status === 401 &&
          (message === "Invalid Token" || message === "Unauthorized Access") && !isRefreshTokenCalled 
        ) {
    await onRefreshToken();
    setIsRefreshTokenCalled(true);
  }
}

return Promise.reject(error);
    }
  );

return {
  // togglePasswordIcon
  toggleIcon,
  setToggleIcon,
  togglePasswordIcon,
  // adminlogin
  onAdminSignIn,
  isSignInLoading,

  // partner login
  onPartnerSignUp,
  onPartnerSignIn,
  isPartnerSignInLoading,
  // socket io
  socket,
  userData,

  //password reset
  onResetPassword,
  onChangePassword,

  // onboarding flow
  PageNumber,
  setPageNumber,
  nextPage,
  previousPage,
  selectedCousine,
  setSelectedCousine,
  onEmailCheckUp,
  isEmailCheckLoading,
  useGetEstablishmentType,
  selectedEstablishment,
  setSelectedEstablishmentType,
  selectedType,
  setSelectedType,
  useGetServiceType,
  selectedServiceType,
  setSelectedServiceType,
  onFileUploadUp,
  s3URl,
  location,
  setLocation,
  file,
  setFile,
  steps,
  handleKeyDown,
  locationFetched,
  setLocationFetched,
  mutatePartnerSignUp,
  isFileUploadLoading,
};
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
