import React from "react";
import logo from "../../assets/images/Frame.png";
import { TextInput } from "../../components/InputBox";
import BasicButton from "../../components/Button";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/hooks";

export default function Resetwrapper() {
  const {onResetPassword} = useAuth();
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    // TODO:Handle Submit
   onResetPassword(data)
  };

  return (
    <div className="flex  flex-col justify-center items-center h-[100vh]">
      <div className=" w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow  ">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-[#ED1703]  ">
            <img src={logo} alt="Main Logo" />
          </div>

          <div className=" flex  flex-col gap-5  p-4 sm:p-6 md:p-8 ">
            <div>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      placeholder="youremail@domain.com"
                      label="Email"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>

            <BasicButton
              color="primary"
              type="submit"
              sx={{ textTransform: "none" }}
            >
              Reset password
            </BasicButton>
          </div>
        </form>
      </div>
      <div className="text-sm mt-5 font-medium text-gray-500 flex justify-center">
        <p
          className="text-genieRed hover:underline "
          onClick={() => {
            navigate("/");
          }}
        >
          Back to Login{" "}
        </p>
      </div>
    </div>
  );
}
