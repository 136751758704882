import { Accordion, AccordionDetails, AccordionSummary, Badge, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useDiner } from "../../../utils/hooks";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { PageLoader } from "../../../components/loader";
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cart from "../../../assets/Icons/Cart.svg";
import backArrow from "../../../assets/Icons/backArrow.svg";
import spiceIcon from "../../../assets/Icons/spiceIcon.png";
import broccoli from "../../../assets/Icons/broccoli.png";
import AddIcon from "@mui/icons-material/Add";
import emptyCart from "../../../assets/images/emptyCart.png";
import edit from "../../../assets/Icons/edit.svg"
import info from "../../../assets/Icons/info.svg"
import receipt from "../../../assets/Icons/receipt.svg"

import CloseIcon from "@mui/icons-material/Close";
import useToken from "../../../utils/hooks/useToken";
import BasicButton from "../../../components/Button";
import DetailsDropdown from "../../../components/DetailsDropdown";
import { ExpandMore } from "@mui/icons-material";

function RestaurantBill() {
  // const { useGetCartItem, cartList, addPayment, useGetOrderDetails, setOrderModel, OrderModel, useGetCartCount } =
  //   useDiner();
  const { useGetCartItem, cartList, useGetOrderDetails, useGetCartCount, useGetCustomizations, PayBill, setPaymentAmount } =
    useDiner();
  const { getCartId, getUserDetails } = useToken();

  const navigate = useNavigate();
  const userDetails = getUserDetails();

  const restaurantId = useParams();
  const location = useLocation();
  const { id } = useParams();
  const tableId = new URLSearchParams(location.search).get("tableId");
  const [itemIds, setItemIds] = useState([])
  const [serviceFee, setServiceFee] = useState(0.25)

  useEffect(() => {
    if (!tableId) {
      navigate(`/genie/dine/${restaurantId?.id}`)
    }
  }, [tableId, navigate, restaurantId]);

  useEffect(() => {
    setItemIds(cartList[cartList.length - 1]?.orderDetails?.map(item => item.itemId))
  }, [cartList]);

  const { data: OrderData, isLoading: orderDataIsLoading } =
    useGetOrderDetails();

  // const { data: cartCount } = useGetCartCount({
  //   partnerId: restaurantId?.id,
  //   cartId: getCartId(),
  // });

  const { isLoading: cartDataIsLoading } = useGetCartItem({
    // cartID: getCartId(),
    // partnerId: id,
    dinerId: getUserDetails()?.id,
  });

  const { data: customizations, isLoading: customizationsIsLoading } = useGetCustomizations({
    menuIds: itemIds,
    partnerId: restaurantId?.id
  })

  const paymentFunction = (data) => {
    PayBill(data, restaurantId?.id, tableId)
  }

  return (
    <>
      <Grid container direction="column">
        {/* <Grid
          item
          sx={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid> */}
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem]"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          {/* TODO: Add cart count functionality */}
          <Badge badgeContent={cartList.reduce((acc, curr) => acc + curr.orderDetails.length, 0)}
            onClick={() => navigate(`/genie/dine/${restaurantId?.id}/cart/?tableId=${tableId}`)}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}>
            <img src={cart} alt="cart" />
          </Badge>
        </Grid>
        {/* <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="border-b-[2px] p-3 border-gray-300  "
        >
          <h1 className="text-quicksand  font-[700] text-[16px]">Your Bill</h1>
          <CloseIcon
            onClick={() => (navigate(-1), setOrderModel(!OrderModel))}
          />
        </Grid> */}
        <Grid
          container
          item
          xs={12}
          direction="row"
          gap="12px"
          alignItems="center"
          className="p-4"
        >
          <img className="h-[20px] w-[20px] cursor-pointer" src={backArrow} alt=""
            // onClick={() => (handleDiff(), navigate(-1))}
            onClick={() =>
              // navigate(-1)
              navigate(`/genie/dine/${restaurantId?.id}/cart?tableId=${tableId}`)
            }
          />
          <h1 className="  font-[500] text-[14px]">
            {/* Your Order */}
            Order Summary
          </h1>
        </Grid>
        <>
          {cartDataIsLoading || customizationsIsLoading || !customizations === undefined ? (
            <div className="flex flex-col justify-center items-center h-[calc(100vh-232.17px)]">
              <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
              <p className="text-gray-600">
                Loading...
              </p>
            </div>
          ) : (
            <>
              {cartList ?
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  className=" overflow-auto"
                  style={{
                    maxHeight: "calc(100vh - 119.17px)",
                    height: "fit-content",
                    padding: "0 16px",
                    paddingBottom: "119.17px",
                    gap: "12px"
                  }}
                >
                  {/* <Accordion
                    expanded
                    sx={{ width: "100%", boxShadow: "none", border: "none", borderRadius: "0px" }}
                  >
                    <AccordionSummary
                      expandIcon={(userDetails && userDetails.userType === "diner") && <ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ padding: 0, fontWeight: "500", fontSize: "16px", minHeight: "0px", margin: 0, '& .MuiAccordionSummary-content': { margin: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }, '&.Mui-expanded': { minHeight: '48px' } }}
                    >
                      <span className="font-[#000000]">ORDER #1</span>
                      {(userDetails && userDetails.userType === "diner") &&
                        <div className="flex items-center gap-1 text-[#B80E0C]"
                          onClick={(e) => {
                            e.stopPropagation()
                            console.log("clicked")
                          }}
                        >
                          <img className="h-[14px]" src={edit} alt="" />
                          <span className="font-normal text-[14px]">Edit Order</span>
                        </div>
                      }
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0, gap: "12px", display: "flex", flexDirection: "column" }}>
                      {cartList?.map((cartItem) => {
                        return (
                          <Grid
                            container
                            gap={2}
                            item
                            xs={12}
                            key={cartItem?._id}
                            // className={`${cartItem === cartList[cartList.length - 1] ? '' : 'border-b-[1px] border-[#F3F0F3]'} pb-[10px] overflow-auto items-center`}
                            className={`border-b-[1px] border-[#F3F0F3] pb-[10px] overflow-auto items-end`}
                            sx={{ flexWrap: "nowrap" }}
                            justifyContent={"space-between"}
                            alignItems={(userDetails && userDetails.userType === "diner") ? "center" : ""}
                          >
                            <div>
                              <div className="">
                                <div className="flex items-center max-h-3">
                                  <div className="mr-1">
                                    {[...Array(cartItem?.spicinessLevel)].map((_, index) => (
                                      <img
                                        key={index}
                                        src={spiceIcon}
                                        alt="Spice"
                                        className="w-3 h-3 inline-block"
                                      />
                                    ))}
                                  </div>
                                  <div>
                                    {[...Array(1)].map((_, index) => (
                                      <img
                                        key={index}
                                        src={broccoli}
                                        alt="Veg"
                                        className="w-3 h-3 inline-block"
                                      />
                                    ))}
                                  </div>
                                </div>
                                <div>
                                  <h1 className="font-normal text-[18px] flex gap-3">
                                    <span>{cartItem?.item_name}</span>
                                    <span className="text-[#CD1719] font-semibold text-nowrap">X {cartItem?.quantity}</span>
                                  </h1>

                                  {(userDetails && userDetails.userType === "diner") &&
                                    <DetailsDropdown headerText="More Details" className="mt-[18px]"
                                      // content={item?.ingredients?.reduce((acc, ingredient, index, array) => {
                                      //   return acc + ingredient + (index === array.length - 1 ? "" : ", ");
                                      // }, "")}
                                      content={cartItem?.description}
                                    // content={item?.description}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col items-end">

                              <div className={`flex flex-col justify-between items-center ${(userDetails && userDetails.userType === "diner") && "w-[106px]"}`}>

                                <h1 className={`font-semibold text-[18px] text-nowrap ${(userDetails && userDetails.userType === "diner") && "py-[17px]"}`}>
                                  <span className="text-xs text-[#4d4d4d]">AED </span>
                                  {(cartItem?.oPrice - cartItem?.dPrice).toFixed(2)}
                                </h1>

                                {(userDetails && userDetails.userType === "diner") &&
                                  <div className="bg-[#FFD166] text-xs font-normal px-[6px] py-[2px] rounded-full w-fit text-nowrap leading-[18px]">Being Prepared</div>
                                }
                              </div>
                            </div>
                          </Grid>
                        );
                      })}
                      <Grid
                        container
                        gap={2}
                        item
                        xs={12}
                        className={`pb-[10px] overflow-auto items-end`}
                        sx={{ flexWrap: "nowrap" }}
                        justifyContent={"space-between"}
                      >
                        <div>
                          <div className="">
                            <div className="flex items-center max-h-3">
                            </div>
                            <h1 className="font-medium text-[16px] flex items-center gap-2">
                              <div className="w-8 h-8 bg-[#EAE6F5] flex items-center justify-center rounded-full">
                                <img src={receipt} alt="" />
                              </div>
                              <span className="text-[#3E3E3E]">Service Fee</span>
                              <img className="h-4" src={info} alt="" />
                            </h1>
                          </div>
                        </div>
                        <div className="flex flex-col items-end">
                          <div className="flex justify-between items-center gap-3">

                            <h1 className={`font-semibold text-[18px] text-nowrap ${(userDetails && userDetails.userType === "diner") && "w-[106px]"} text-center`}>
                              <span className="text-xs text-[#4d4d4d]">AED </span>
                              0.25
                            </h1>
                          </div>
                        </div>
                      </Grid>
                    </AccordionDetails>
                  </Accordion> */}
                  {cartList?.map((order, index) => (
                    // order?.orderDetails?.length > 0 &&
                    <Accordion
                      key={index}
                      sx={{
                        width: "100%",
                        boxShadow: "none",
                        border: "none",
                        borderRadius: "0px",
                        '&::before': {
                          top: "-6px",
                          opacity: '1 !important',
                          display: 'block !important'
                        },
                        '&.Mui-expanded': {
                          margin: 0,
                        },
                        '&.Mui-expanded + .MuiAccordion-root::before': {
                          opacity: '1 !important',
                          display: 'block !important'
                        }
                      }}
                      defaultExpanded={index === cartList?.length - 1}
                    >
                      <AccordionSummary
                        expandIcon={order?.isPlaced === true ? <ExpandMore sx={{ color: "#1C1B1F", transform: "scale(1.3)" }} /> : null}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          padding: 0,
                          fontWeight: "500",
                          fontSize: "16px",
                          minHeight: "0px",
                          margin: 0,
                          '& .MuiAccordionSummary-content': {
                            margin: "8px 0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          },
                          '& .MuiAccordionSummary-content.Mui-expanded': {
                            margin: '8px 0px !important'
                          },
                          '&.Mui-expanded': {
                            minHeight: 'fit-content',
                            height: 'fit-content',
                            margin: '0 !important'
                          },
                          '& .MuiPaper-root': {
                            margin: "0"
                          }
                        }}
                      >
                        <span className="text-[#000000]">ORDER #{index + 1}</span>
                        {/* TODO: add order clear functionality */}
                        {/* {index === cartList?.length - 1 && order?.isPlaced === false &&
                          <div className={`flex items-center gap-1 text-[#B80E0C] ${deleteOrderIsLoading ? "opacity-50" : "cursor-pointer"}`}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (!deleteOrderIsLoading) {
                                cancelOrder(order?._id)
                              }
                            }}
                          >
                            <img className="h-[14px]" src={cancel} alt="" />
                            <span className="font-normal text-[14px]">Clear Order</span>
                          </div>
                        } */}
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0, gap: "12px", display: "flex", flexDirection: "column" }}>
                        {order?.orderDetails?.map((cartItem, index) => {
                          return (
                            <Grid
                              container
                              gap={2}
                              item
                              xs={12}
                              key={cartItem?._id}
                              className={`${cartItem === cartList[cartList.length - 1] ? '' : 'border-b-[1px] last:border-b-0 border-[#F3F0F3]'} pb-[10px] overflow-auto items-end`}
                              sx={{ flexWrap: "nowrap" }}
                              justifyContent={"space-between"}
                            >
                              <div>
                                <div className="">
                                  <div className="flex items-center max-h-3">
                                    <div className="mr-1">
                                      {[...Array(customizations?.find(customization => customization._id === cartItem.itemId)?.spicinessLevel)].map((_, index) => (
                                        <img
                                          key={index}
                                          src={spiceIcon}
                                          alt="Spice"
                                          className="w-3 h-3 inline-block"
                                        />
                                      ))}
                                    </div>
                                    <div>
                                      {[...Array(1)].map((_, index) => (
                                        <img
                                          key={index}
                                          src={broccoli}
                                          alt="Veg"
                                          className="w-3 h-3 inline-block"
                                        />
                                      ))}
                                    </div>
                                  </div>

                                  {/* <h1 className="  font-quicksand font-[600] text-[16px] truncate w-[50%]  ">
                                    <span className="font-[700] text-[16px] text-genieRed  ">
                                      <span className="mr-1">{cartItem?.quantity}</span>
                                      <CloseIcon
                                        sx={{ fontSize: "16px", fontWeight: "700" }}
                                      />
                                    </span>
                                    <span className=" font-[600] text-[14px] ml-1  ">
                                      {cartItem?.item_name}
                                    </span>
                                  </h1> */}
                                  {/* {console.log(order.isPlaced===false)} */}
                                  <>
                                    <h1 className="font-normal text-[18px] flex gap-3">
                                      <span>{cartItem?.item_name}</span>
                                      <span className="text-[#CD1719] font-semibold text-nowrap">X {cartItem?.quantity}</span>
                                    </h1>
                                    <span className="text-[#545454] font-normal">{cartItem?.customization?.map(customization => customization.items.map(item => item.itemName).join(", ")).join(", ")}</span>
                                  </>


                                </div>
                              </div>
                              <div className="flex flex-col items-end">
                                <div className={`flex flex-col justify-between items-center ${(userDetails && userDetails.userType === "diner") && "w-[106px]"}`}>

                                  <div className="">

                                    <h1 className={`font-semibold text-[18px] text-nowrap ${(userDetails && userDetails.userType === "diner") && ""}`}>
                                      <span className="text-xs text-[#4d4d4d]">AED </span>
                                      {((cartItem?.oPrice - cartItem?.dPrice + cartItem?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * cartItem?.quantity).toFixed(2)}
                                    </h1>
                                    {/* {cartItem?.customization?.length > 0 &&
                                      <h1 className="font-semibold text-xs text-[#b80e0c] text-nowrap relative -left-[10.8px]">
                                        <span className="text-xs text-[#b80e0c]">+ AED </span>
                                        {cartItem?.customization?.reduce((acc, customization) => acc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0).toFixed(2)}
                                      </h1>
                                    } */}
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  <Grid
                    container
                    gap={2}
                    item
                    xs={12}
                    className={`overflow-auto items-end`}
                    sx={{ flexWrap: "nowrap", position: "relative" }}
                    justifyContent={"space-between"}
                  >
                    <h1 className="font-medium text-[16px] flex items-center gap-2">
                      <div className="w-8 h-8 bg-[#EAE6F5] flex items-center justify-center rounded-full">
                        <img src={receipt} alt="" />
                      </div>
                      <span className="text-[#3E3E3E]">Service Fee</span>
                      <img className="h-4" src={info} alt="" />
                    </h1>
                    <div className="flex flex-col items-end">
                      <div className="flex justify-between items-center gap-3">
                        <h1 className={`font-semibold text-[18px] text-nowrap ${(userDetails && userDetails.userType === "diner") && "w-[106px]"} text-center`}>
                          <span className="text-xs text-[#4d4d4d]">AED </span>
                          {serviceFee}
                        </h1>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                :
                <div className="flex flex-col items-center justify-center h-[calc(100vh-180px)] flex-grow px-4">
                  <img className="max-w-[231px]" src={emptyCart} alt="" />
                  <p className="font-medium text-[18px] mb-[20px]">Your basket is empty</p>
                  <BasicButton
                    color="genieRed"
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      padding: "12px 16px",
                      fontWeight: "500",
                      width: "100%",
                    }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    <span className="font-medium flex items-center gap-[8px]">
                      <AddIcon height={"14px"} width={"14px"} />
                      <span className="leading-snug">Add Items</span>
                    </span>
                  </BasicButton>
                </div>
              }
            </>
          )}
          <Grid
            container
            item
            xs={12}
            direction="column"
            // display={cartList?.length > 0 ? "flex" : "none"}
            className={`px-4 pt-2 pb-3 absolute bottom-0 border-t-[1px] bg-white border-[#d1d1d1] z-10`}
          >
            <div className="flex justify-between ">
              <div>
                <h1 className=" font-normal text-[16px] text-[#252525]">
                  Payable Amount
                </h1>
                <h3 className=" font-[500] text-[12px] text-[#777777]">
                  {/* ({cartList?.length}{" "}
                  {cartList?.length > 1 ? "items" : "item"}) */}
                  ({cartList.reduce((acc, curr) => acc + curr.orderDetails.length, 0)}{" "}
                  {cartList.reduce((acc, curr) => acc + curr.orderDetails.length, 0) > 1 ? "items" : "item"})
                </h3>
              </div>
              <h1 className="font-semibold text-[20px] text-[#252525]">
                <span className="text-[#4D4D4D] text-[14px]">AED </span>
                {/* {parseFloat(totalCartPrice)?.toFixed(2)} */}
                {cartList.reduce((acc, curr) => acc + curr?.orderDetails?.reduce((acc, curr) => acc + (curr.oPrice - curr.dPrice + curr.customization.reduce((customizationAcc, customization) => customizationAcc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * curr.quantity, 0), 0) + serviceFee}
                {setPaymentAmount(cartList.reduce((acc, curr) => acc + curr?.orderDetails?.reduce((acc, curr) => acc + (curr.oPrice - curr.dPrice + curr.customization.reduce((customizationAcc, customization) => customizationAcc + customization.items.reduce((itemAcc, item) => itemAcc + item.price, 0), 0)) * curr.quantity, 0), 0) + serviceFee)}
              </h1>
            </div>
            <div className="flex flex-col gap-2 justify-between  items-center  pt-2">
              {/* <div className="flex flex-col">
                <div className="text-quicksand font-[600] text-[14px] max-w-[30vw] overflow-hidden ">
                  {cartData?.result?.notes}
                </div>
                <div
                  className="text-genieRed flex items-center "
                  onClick={toggleNoteDrawer}
                >
                  {cartData?.result?.notes ? (
                    <CreateIcon sx={{ color: "#ED1703" }} />
                  ) : (
                    <NoteOutlinedIcon sx={{ color: "#ED1703" }} />
                  )}
                  <span className="ml-2 font-[600] text-[14px] text-genieRed">
                    {cartData?.result?.notes ? "Edit a note" : " Add a note"}
                  </span>
                </div>
              </div> */}

              <BasicButton
                color="genieRed"
                type="submit"
                variant="outlined"
                // width: "236px", height: "36px"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  padding: "12px 16px",
                  fontWeight: "500",
                  width: "100%",
                  height: "48px",
                  display: !cartList.find(order => order.isPlaced === false) ? "block" : "none"
                }}
              // onClick={() => {
              //   // handleDiff();
              //   // placeOrder(getOrderId());
              //   navigate(-1)
              // }}
              >
                <span className="font-medium text-black">
                  Split Bill
                </span>
              </BasicButton>

              <div className="flex gap-2 w-full">
                <BasicButton
                  color="genieRed"
                  type="submit"
                  // width: "236px", height: "36px"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "12px 16px",
                    fontWeight: "500",
                    width: "100%"
                  }}
                  // onClick={() => {
                  //   handleDiff();
                  //   placeOrder(id);
                  // }}
                  onClick={() => {
                    paymentFunction(cartList)
                  }}
                >
                  <span className="font-medium text-nowrap">
                    Pay Bill
                  </span>
                </BasicButton>
                <BasicButton
                  color="genieRed"
                  type="submit"
                  // width: "236px", height: "36px"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "12px 16px",
                    fontWeight: "500",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate(`/genie/dine/${restaurantId}/bill/cash/?tableId=${tableId}`)
                  }}
                >
                  <span className="font-medium text-nowrap">
                    Pay in Person
                  </span>
                </BasicButton>
              </div>
            </div>
          </Grid>
        </>
      </Grid>
    </>
  );
}

export default RestaurantBill;
