import React, { useEffect, useState } from 'react'
import { PageLoader } from '../../../components/loader'
import { Grid } from '@mui/material'
import Table from '../../../components/CustomTable/TableHeader'
import OrdRow from '../../../components/CustomTable/TableManagementRow'
import BasicButton from '../../../components/Button'
import receipt_bg from '../../../assets/Icons/receipt_bg.svg'
import { useRestaurant } from '../../../utils/hooks'
import QrCodeModal from './QrCodeModal'

const TableSection = ({dineManagementSummary}) => {
    const [qrCodeOpen, setQrCodeOpen] = useState(false);
    const [tableLink, setTableLink] = useState("");
    const {
        useGetTable,
        // responseTableData,
        // setResponseTableData,
        // qrScanned,
        // setQrSCanned,
        // isTableDrawer,
        // setIsTableDrawer,
        // OnAddTable,
        // addTableIsLoading,
    } = useRestaurant();

    const { data: tableData, isLoading: tableLoading } = useGetTable();

    const headers = [
        "Table No.",
        "No. of seats",
        "QR Code",
        "Current Order Status",
    ];

    const handleQrCode = (link) => {
        setQrCodeOpen(true)
        setTableLink(link)
    }

    return (
        <>
            <div className="overflow-auto">
                <Grid container>
                    <Grid item container xs={12} sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: "21px", gap: "8px", padding: "0px 32px" }}>
                        <Grid item xs={3} lg={2}>
                            <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                                <h1 className="font-normal text-xs">Total Tables</h1>
                                <h1 className="font-semibold text-2xl ">{tableData?.result?.length || 0}</h1>
                            </div>
                        </Grid>
                        <Grid item xs={3} lg={2}>
                            <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                                <h1 className="font-normal text-xs">Tables Occupied</h1>
                                <h1 className="font-semibold text-2xl ">0</h1>
                            </div>
                        </Grid>
                        <Grid item xs={3} lg={2}>
                            <div className="bg-[#FCFAE9] border border-[#F4F1D7] flex flex-col gap-2 rounded p-5">
                                {/* TODO: fix this */}
                                <h1 className="font-normal text-xs">Tables Available</h1>
                                <h1 className="font-semibold text-2xl ">{tableData?.result?.length || 0}</h1>
                            </div>
                        </Grid>
                        <Grid item xs={3} lg={2} sx={{ marginLeft: { xs: '0', lg: 'auto' } }}>
                            <div className="bg-[#E9FCF2] border border-[#D0EDDD] flex flex-col gap-2 rounded p-5">
                                <h1 className="font-normal text-xs">Total Orders</h1>
                                <h1 className="font-semibold text-2xl ">{dineManagementSummary?.totalOrderCount || 0}</h1>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container item >
                        <Grid
                            item
                            xs={12}
                            md={12}
                            sx={{ padding: `0px 32px` }}
                        >
                            <Table headers={headers}>
                                {tableLoading ?
                                    <tr>
                                        <td colSpan={headers.length}>
                                            <div className="flex flex-col justify-center items-center w-full py-6">
                                                <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
                                                <p className="text-gray-600">
                                                    Loading...
                                                </p>
                                            </div>
                                        </td>
                                    </tr> :
                                    tableData?.result?.length === 0 ?
                                        <tr>
                                            <td colSpan={headers.length}>
                                                <div className="flex flex-col justify-center items-center w-full py-3">
                                                    <p className="text-gray-600">No tables found</p>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        tableData?.result?.map((ord, index) => (
                                            <OrdRow key={ord.id} ord={ord} index={index} handleQrCode={handleQrCode} />
                                        ))
                                }
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            {qrCodeOpen && (
                //TODO:Add Table Number in model and jpg
                <QrCodeModal
                    open={qrCodeOpen}
                    handleCancel={() => setQrCodeOpen(false)}
                    link={tableLink}
                    tableNumber={tableLink.split("=")[1]}
                // handleConfirmClick={() => ( navigate(-1))}
                />
            )}
        </>
    )
}

export default TableSection