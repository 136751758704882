import React, { useState, useCallback, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useRestaurant, useSuperAdmin } from "../../utils/hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { titleCase } from "../../utils/helper";

// Function to reorder the list on drag end
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableTabs = ({ tabs, children }) => {
  const [items, setItems] = useState(tabs);
  const containerRef = useRef(null);
  const { tabValue, handleTabChange } = useSuperAdmin();
  const { OnChangeSequence } = useRestaurant();

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const reorderedItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );
      OnChangeSequence(reorderedItems);
      handleTabChange({}, result.destination.index);
      setItems(reorderedItems);
    },
    [items, handleTabChange, OnChangeSequence]
  );

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="relative w-full overflow-hidden ">
        <button
          className="absolute  md:hidden left-0 top-1/2 transform -translate-y-1/3 bg-white p-2 "
          onClick={scrollLeft}
        >
          <ArrowBackIosIcon sx={{ color: "#ED1703" }} />
        </button>
        <div
          ref={containerRef}
          className="flex w-full border-b-2 border-[#E8E8E8] overflow-x-auto "
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className="flex pt-2 px-6  pr-10"
                  {...provided.droppableProps}
                >
                  {items.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className={`h-10 font-poppins text-sm flex items-center justify-center mx-3 cursor-pointer ${tabValue === index
                            ? "text-[#B80E0C] font-semibold"
                            : "text-[#757373] font-medium"
                            }`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={() => handleTabChange(provided, index)}
                        >
                          <span className="flex items-center w-max">
                            {tabValue === index && <DragIndicatorIcon />}
                            {titleCase(item.label)}
                          </span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <button
          className="absolute md:hidden right-0 top-1/2 transform -translate-y-1/3 bg-white p-2 "
          onClick={scrollRight}
        >
          <ArrowForwardIosIcon sx={{ color: "#ED1703" }} />
        </button>
      </div>
      {children(tabValue)}
    </>
  );
};

export default DraggableTabs;
