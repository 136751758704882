import React, { useState } from "react";
import { getBackgroundColor } from "../../utils/helper";
import { Chip } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import copy from '../../assets/Icons/copy.svg'
import download from '../../assets/Icons/download.svg'
import CheckIcon from '@mui/icons-material/Check';
import StatusDropDown from "../StatusDropDown";
import { useRestaurant } from "../../utils/hooks";

const OrdRow = ({ ord, index, onClick, active, handleQrCode }) => {
    const [copied, setCopied] = useState(false);

    const { OnUpdateTable } = useRestaurant()

    const handleCopy = () => {
        navigator.clipboard.writeText(ord.tableUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    };

    const handleStatusChange = (status) => {
        OnUpdateTable(ord._id, { status: status.join('') })
    }

    return (
        <tr
            key={index}
            className={`border-b ${active ? "bg-[#FCF0F0]" : ""}`}
        >
            <td
                className="px-3 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug select-none"
                onClick={onClick}
            >
                {ord?.tableSequence}
            </td>
            <td
                className=" px-3 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug select-none"
                onClick={onClick}
            >
                {ord?.numberOfSeats || 0}
            </td>
            <td className="px-3 py-3 font-normal text-[#4D4D4D] border-r leading-snug flex items-center justify-between">
                <a href={ord.tableUrl} target="_blank" rel="noreferrer" className="underline text-[#3E3E3E] mr-2">
                    {ord.tableUrl}
                </a>
                <div className="flex gap-2 flex-shrink-0">
                    <img className="cursor-pointer w-4 h-4 min-w-[16px]" src={download} alt="download" onClick={() => handleQrCode(ord?.tableUrl)} />
                    {copied ? (
                        <CheckIcon className="text-[#b80e0c]" sx={{ height: "16px", width: "16px", minWidth: "16px" }} />
                    ) : (
                        <img className="cursor-pointer w-4 h-4 min-w-[16px]" src={copy} alt="copy" onClick={handleCopy} />
                    )}
                </div>
            </td>
            <td className=" px-3 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug select-none">
                <div className="w-full flex items-center justify-between">
                    <StatusDropDown names={["qr_scanned", "new", "preparing", "ready_to_serve", "served", "payment_done", "available"]} value={ord?.status} onChange={handleStatusChange} />
                </div>
            </td>
        </tr>
    );
};

export default OrdRow;
