import { Badge, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDiner } from "../../../utils/hooks";
import { TextInput } from "../../../components/InputBox";
import { useDebounce } from "../../../useDebaunce";
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cart from "../../../assets/Icons/Cart.svg";
import useToken from "../../../utils/hooks/useToken";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import backArrow from "../../../assets/Icons/backArrow.svg";
import payment_success from "../../../assets/images/payment_success.png"
import email from "../../../assets/Icons/email.svg"
import sms from "../../../assets/Icons/sms.svg"
import whatsapp from "../../../assets/Icons/whatsapp.svg"
import forwardArrow from "../../../assets/Icons/forwardArrow.svg";
import BasicButton from "../../../components/Button";


function RestaurantPaymentSuccess() {
  // const [likedItems, setLikedItems] = useState([]);
  // const [dislikedItems, setDislikedItems] = useState([]);
  // const [animateThumbUp, setAnimateThumbUp] = useState(null);
  // const [animateThumbDown, setAnimateThumbDown] = useState(null);
  const [receiptMethod, setReceiptMethod] = useState("sms")

  const {
    // orderResult,
    addRating,
    // hover2,
    // setHover2,
    // rating2,
    // setRating2,
    // hover,
    // setHover,
    // rating,
    // setRating,
    suggestion,
    // setSuggestion,
    useGetCartCount,
    paymentAmount,
    setPaymentAmount,
  } = useDiner();
  const { removeToken } = useToken();

  const { getCartId } = useToken();
  const navigate = useNavigate();
  const restaurantId = useParams();
  const location = useLocation();
  // const { id } = useParams();
  const tableId = new URLSearchParams(location.search).get("tableId");

  const { data: cartCount } = useGetCartCount({
    partnerId: restaurantId?.id,
    cartId: getCartId(),
  });

  /**
   * Handles the action of liking an item by adding it to the liked items list,
   * updating the ratings, and setting animation for the thumbs up icon.
   * @param {{string}} itemId - The unique identifier of the item being liked.
   * @param {{string}} itemName - The name of the item being liked.
   */
  // const handleLike = (itemId, itemName) => {
  //   if (!likedItems.includes(itemId)) {
  //     addRating({
  //       itemsLiked: [{ itemId, item_name: itemName, liked: true }],
  //     });
  //     setLikedItems([...likedItems, itemId]);
  //     if (dislikedItems.includes(itemId)) {
  //       setDislikedItems(dislikedItems.filter((id) => id !== itemId));
  //     }
  //   }

  //   // Trigger animation
  //   setAnimateThumbUp(itemId);
  //   setTimeout(() => {
  //     setAnimateThumbUp(null);
  //   }, 300); // Match this duration with your CSS animation duration
  // };

  /**
   * Handles the dislike action for an item by updating the disliked items list, liked items list,
   * and triggering an animation.
   * @param {{string}} itemId - The unique identifier of the item being disliked.
   * @param {{string}} itemName - The name of the item being disliked.
   */

  // const handleDislike = (itemId, itemName) => {
  //   if (!dislikedItems.includes(itemId)) {
  //     addRating({
  //       itemsLiked: [{ itemId, item_name: itemName, disLiked: true }],
  //     });
  //     setDislikedItems([...dislikedItems, itemId]);
  //     if (likedItems.includes(itemId)) {
  //       setLikedItems(likedItems.filter((id) => id !== itemId));
  //     }
  //   }

  //   // Trigger animation
  //   setAnimateThumbDown(itemId);
  //   setTimeout(() => {
  //     setAnimateThumbDown(null);
  //   }, 300); // Match this duration with your CSS animation duration
  // };



  /**
   * Debounces the given value using a delay of 1500 milliseconds.
   * @param {{any}} suggestion - The value to debounce.
   * @returns The debounced value after the delay.
   */
  var debauchedValue = useDebounce(suggestion, 1500);

  /**
   * useEffect hook that triggers when the debauchedValue changes.
   * If the suggestion is not an empty string, it adds a rating with the debauchedValue as feedback.
   * @returns None
   */
  useEffect(() => {
    if (suggestion !== "") {
      addRating({ feedback: debauchedValue });
    }
  }, [debauchedValue, addRating, suggestion]);

  return (
    <>
      <Grid container direction="column">
        {/* <Grid
          item
          sx={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid> */}
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem]"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          {/* TODO: Add cart count functionality */}
          <Badge badgeContent={cartCount?.result?.cartItemCount}
            // onClick={() => navigate(`/genie/dine/${restaurantId?.id}/cart/?tableId=${tableId}`)}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}>
            <img src={cart} alt="cart" className="opacity-50" />
          </Badge>
        </Grid>
        <Grid container sx={{ maxHeight: "calc(100vh - 190px)", overflowY: "auto" }}>
          <Grid
            container
            item
            xs={12}
            direction="row"
            gap="12px"
            alignItems="center"
            className="p-4 max-h-[53px]"
          >
            <img className="h-[20px] w-[20px] cursor-pointer" src={backArrow} alt=""
              // onClick={() => (handleDiff(), navigate(-1))}
              onClick={() => {
                navigate(`/genie/dine/${restaurantId?.id}/cart?tableId=${tableId}`)
                window.location.reload();
                removeToken()
                setPaymentAmount(0)
              }}
            />
            <h1 className="  font-[500] text-[14px]">
              My orders
            </h1>
          </Grid>
          {/* <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="flex-end "
          alignItems="center"
          className=" p-3  "
          >
            <CloseIcon onClick={() => navigate(-2 )} />
          </Grid> */}
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}
          >
            {/* <CheckCircleIcon
            sx={{ width: "40px", height: "40px", color: "#4BB543" }}
            /> */}
            {/* <img className="max-h-[180px] max-w-[180px] m-6" src={payment_success} alt="" /> */}
            <img className="max-h-[20vh] m-[22.5px] h-[135px]" src={payment_success} alt="" />
            {/* <img className="h-auto w-auto m-6" src={payment_success} alt="" /> */}
            <h1 className="font-medium text-[20px] text-[#000000]">
              Payment was successful!
            </h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className="px-4 h-[104px]"
          >
            <div className="bg-[#E8FFE5] rounded-lg w-full flex flex-col items-center py-3 px-[15px] gap-2">
              {/* <h1 className="font-[600] text-[14px] ">
              You paid
              <span className="font-[800] px-1">
                AED {orderResult?.totalOrderValue}
              </span>
              for {orderResult?.orderDetails?.length}{" "}
              {orderResult?.orderDetails?.length === 1 ? "item" : "items"}
              </h1> */}
              <p className="text-[#595454] font-normal text-base leading-9">Total amount Payed</p>
              <h1 className="text-black font-semibold text-2xl leading-9">AED {paymentAmount ? paymentAmount: "0"}</h1>
            </div>
          </Grid>

          <Grid xs={12} sx={{ display: "flex", flexDirection: "column", gap: "12px", marginTop: "44px", marginBottom: "32px" }}>
            <div className="text-[#3E3E3E] font-medium text-lg text-center">Get receipt via</div>
            <div className="flex mx-4 pb-2 justify-between relative after:absolute after:w-full after:h-[2px] after:bg-[#E6E6E6] after:bottom-0 after:rounded-full">
              {['email', 'sms', 'whatsapp'].map((method) => (
                <div
                  key={method}
                  className={`w-full flex justify-center font-medium gap-2 items-center relative cursor-pointer ${receiptMethod === method && "after:absolute after:-bottom-[8.5px] after:h-[3px] after:bg-[#B80E0C] after:w-full after:rounded-full after:z-10"}`}
                  onClick={() => setReceiptMethod(method)}
                >
                  <img src={method === 'email' ? email : method === 'sms' ? sms : whatsapp} alt="" />
                  {method.charAt(0).toUpperCase() + method.slice(1)}
                </div>
              ))}
            </div>
            <div className="px-4">
              <TextInput
                variant="outlined"
                borderRadius="30px"
                backgroundColor="#fff"
                placeholder={`Please enter ${receiptMethod === "email" ? "mail address" : "Phone Number"}`}
                postContent={
                  <div className="h-8 w-8 bg-[#B80E0C] rounded-full flex justify-center items-center">
                    <img src={forwardArrow} alt="submit" />
                  </div>
                }
                sx={{
                  '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {
                    paddingRight: "9px",
                    height: "50px"
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid xs={12}
          sx={{ padding: "12px 16px", borderTop: "1px solid #D1D1D1", display: "flex", flexDirection: "column", gap: "8px", position: "absolute", width: "100%", bottom: "0px", background: "white", zIndex: 100 }}
        >
          <BasicButton color="genieRed" variant="outlined" sx={{ textTransform: "none", width: "100%", fontWeight: 500, fontSize: "16px", lineHeight: "24px", padding: "12px 0" }}
            onClick={() => {
              navigate(`/genie/dine/${restaurantId?.id}?tableId=${tableId}`)
              removeToken()
              setPaymentAmount(0)
              // window.location.reload();
            }}
          >
            Back to Home
          </BasicButton>
          <BasicButton color="genieRed" variant="contained" sx={{ textTransform: "none", width: "100%", fontWeight: 500, fontSize: "16px", lineHeight: "24px", color: "white", padding: "12px 0" }}>
            Get the receipt
          </BasicButton>
        </Grid>


        {/* <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          alignItems="center"
          className=" p-3  "
          spacing={1}
        >
          <Grid item xs={12}>
            <h1 className="font-[600] text-[14px] ml-[5%] ">
              How was the food?
            </h1>
          </Grid>

          <Grid item xs={12}>
            <div className="ml-[5%]">
              {[...Array(5)].map((star, index) => {
                const currentRating = index + 1;
                return (
                  <label key={index}>
                    <input
                      type="radio"
                      name="rating"
                      value={currentRating}
                      className="hidden"
                      onClick={() => (
                        setRating(currentRating),
                        addRating({ food: currentRating })
                      )}
                    />
                    <StarIcon
                      sx={{ fontSize: "36px", borderRadius: "40px" }}
                      className={` ${currentRating <= (hover || rating)
                        ? "text-[#E0E001]"
                        : "text-[grey]"
                        }`}
                      onMouseEnter={() => setHover(currentRating)}
                      onMouseLeave={() => setHover(null)}
                    />
                  </label>
                );
              })}
            </div>
          </Grid>
        </Grid> */}
        {/* <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          alignItems="center"
          className=" p-3  "
          spacing={1}
        >
          <Grid item xs={12}>
            <h1 className="font-[600] text-[14px] ml-[5%] ">
              How was the overall experience?
            </h1>
          </Grid>

          <Grid item xs={12}>
            <div className="ml-[5%]">
              {[...Array(5)].map((star, index) => {
                const currentRating = index + 1;
                return (
                  <label key={index}>
                    <input
                      type="radio"
                      name="rating2"
                      value={currentRating}
                      className="hidden"
                      onClick={() => (
                        setRating2(currentRating),
                        addRating({ experience: currentRating })
                      )}
                    />
                    <StarIcon
                      sx={{ fontSize: "36px", borderRadius: "40px" }}
                      className={` ${currentRating <= (hover2 || rating2)
                        ? "text-[#E0E001]"
                        : "text-[grey]"
                        }`}
                      onMouseEnter={() => setHover2(currentRating)}
                      onMouseLeave={() => setHover2(null)}
                    />
                  </label>
                );
              })}
            </div>
          </Grid>
        </Grid> */}
        {/* <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sx={{ marginLeft: "5%", marginRight: "5%" }}>
            <TextInput
              variant="outlined"
              label="Suggestion(optional)"
              multiline
              rows={2}
              onChange={(e) => setSuggestion(e.target.value)}
            />
          </Grid>
        </Grid> */}
        {/* <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent={"space-around"}
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <h1 className="font-[600] text-[14px] ml-[5%] ">
              Which plates did you like the least?
            </h1>
          </Grid>
          <Grid
            container
            item
            sx={{ marginLeft: "5%", marginRight: "5%" }}
            gap={1}
          >
            {orderResult?.orderDetails?.map((item) => (
              <Grid items xs={5.5} key={item?.itemId}>
                <div className="bg-white rounded border p-3">
                  <img src={plate} alt="Menu" className="rounded" />
                  <div className="">
                    <h1 className="p-2 truncate font-quickSand font-[700] text-[14px]">
                      {item?.item_name}
                    </h1>
                    <p className="flex h-10 justify-around items-center">
                      <ThumbUpOffAltRoundedIcon
                        className={`${likedItems.includes(item?.itemId)
                          ? "text-genieRed hover:text-genieRed"
                          : "hover:text-genieRed"
                          } ${animateThumbUp === item?.itemId
                            ? "thumbUpAnimation"
                            : ""
                          }`}
                        onClick={() =>
                          handleLike(item?.itemId, item?.item_name)
                        }
                      />
                      <ThumbDownAltRoundedIcon
                        className={`${dislikedItems.includes(item?.itemId)
                          ? "text-genieRed hover:text-genieRed"
                          : "hover:text-genieRed"
                          } ${animateThumbDown === item?.itemId
                            ? "thumbDownAnimation"
                            : ""
                          }`}
                        onClick={() =>
                          handleDislike(item?.itemId, item?.item_name)
                        }
                      />
                    </p>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
}

export default RestaurantPaymentSuccess;
