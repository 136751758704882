import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "../../../components/InputBox";
import HeaderWrapper from "../../../components/headerwrapper/HeaderWrapper";
import { Grid, useMediaQuery } from "@mui/material";
import { PageLoader } from "../../../components/loader";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DatePicker from "../../../components/DatePicker";
import OrdRow from "../../../components/CustomTable/Custom";
import Table from "../../../components/CustomTable/TableHeader";
import { useDelivery, useRestaurant } from "../../../utils/hooks";
import BasicButton from "../../../components/Button";
import DrawerComponent from "../../../components/Drawer/Index";
import QrCodeModal from "../TableManagement/QrCodeDisplay";
import useToken from "../../../utils/hooks/useToken";

function DeliveryManagement() {
  const { orderDetails, setOrderDetails, qrCodeOpen, setQrCodeOpen } =
    useDelivery();
  const { setTokenAvailable, onGetUserDetailsFromAccessToken } =
    useRestaurant();
    const { token } = useToken();
  

  const { handleSubmit } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    // const object = { ...data };
    // if (partnerInfo?.result?._id) {
    //   OnEditPartner({ ...object, id: partnerInfo?.result?._id });
    // } else {
    //   OnAddPartner(data);
    // }
  };
  const isSmallScreen = useMediaQuery("(max-width:1024px)");

  const organizationsList = [
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Preparing",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Packing",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Picked Up",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "Regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Delivered",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "Regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Delivered",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "Regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Delivered",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "Regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Delivered",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "Regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Pick up Ready",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Order Placed",
    },
    {
      id: 1,
      ordNo: "23423423",
      item_n: "123",
      ord_total: "200",
      experience: ["Genie", "regular"],
      pay_method: "cash",
      riderAssigned: "ravi",
      status: "Preparing",
    },
  ];

  const headers = [
    "Order No",
    "items No",
    "Order Total",
    "Order Experience",
    "Payment Method",
    "Rider Assigned",
    "Status",
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get("token");

    if (tokenFromUrl) {
      onGetUserDetailsFromAccessToken(tokenFromUrl);
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    if (token) {
      setTokenAvailable(true);
      // QueryClient.refetchQueries("getPartnerInfo");
    }
  }, [onGetUserDetailsFromAccessToken, setTokenAvailable, token]);

  return (
    <HeaderWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* TODO:Add Loader */}
        {false ? (
          <PageLoader />
        ) : (
          <div className="h-[100dvh] overflow-auto pl-4">
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ borderBottom: "2px solid #1C1C1C1A" }}>
                <div className="  flex  items-center    justify-between p-4 ">
                  <h3 className=" flex gap-3">
                    <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                    <span className="text-[#1C1C1C66]">/</span>
                    <span>Restaurant information</span>
                  </h3>
                  <div className="max-w-[280px]  mr-4 flex gap-4 items-center ">
                    <TextInput
                      variant="outlined"
                      preContent={<SearchIcon />}
                      placeholder="Search"
                      //   label="Description(optional)"
                    />
                    <NotificationsNoneOutlinedIcon />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="  flex  items-center    justify-between  px-4 ">
                  <h1 className="text-[24px] ">Overview</h1>
                  <div>
                    <DatePicker />
                  </div>
                </div>
              </Grid>
              <Grid item container xs={12} gap={2} marginTop={2}>
                <Grid item xs={5} lg={2}>
                  <div className="bg-[#F4F1D7]   flex flex-col gap-2  rounded p-5">
                    <h1 className="font-normal text-xs">Orders to start</h1>
                    <h1 className="font-semibold text-md ">10</h1>
                  </div>
                </Grid>
                <Grid item xs={5} lg={2}>
                  <div className="bg-[#F4F1D7]   flex flex-col gap-2  rounded p-5">
                    <h1 className="font-normal text-xs">Orders Preparing</h1>
                    <h1 className="font-semibold text-md ">10</h1>
                  </div>
                </Grid>
                <Grid item xs={5} lg={2}>
                  <div className="bg-[#F4F1D7]   flex flex-col gap-2  rounded p-5">
                    <h1 className="font-normal text-xs">
                      Delivery in Progress
                    </h1>
                    <h1 className="font-semibold text-md ">10</h1>
                  </div>
                </Grid>
                <Grid item xs={5} lg={2}>
                  <div className="bg-[#F4F1D7]   flex flex-col gap-2  rounded p-5">
                    <h1 className="font-normal text-xs">Orders Delivered</h1>
                    <h1 className="font-semibold text-md ">10</h1>
                  </div>
                </Grid>
                <Grid item xs={5} lg={2}>
                  <div className="bg-[#D0EDDD]  flex flex-col gap-2  rounded p-5">
                    <h1 className="font-normal text-xs">Total Orders</h1>
                    <h1 className="font-semibold text-md ">300</h1>
                  </div>
                </Grid>
              </Grid>

              <Grid container item spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={!orderDetails ? 12 : 8.5}
                  sx={{ marginRight: "1rem", height: "65vh" }}
                >
                  <Table headers={headers}>
                    {organizationsList.map((ord, index) => (
                      <OrdRow key={ord.id} ord={ord} index={index} />
                    ))}
                  </Table>
                </Grid>

                {orderDetails && (
                  <Grid item xs sx={{ height: "65vh" }}>
                    <div className="bg-[#FCF0F0] rounded-l-xl h-full ">
                      <h1 className="flex justify-center items-center h-10 font-semibold text-md text-[#B80E0C] ">
                        Order #{orderDetails?.ordNo}
                      </h1>
                      <h1 className="flex justify-center items-center font-normal text-sm mb-2 ">
                        Order List
                      </h1>
                      <hr />
                      <div className="px-4 h-[calc(65dvh-8.75rem)] overflow-scroll">
                        <div className="flex justify-between py-2">
                          <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                            Margarita Pizza
                            <span className=" rounded-full bg-white p-1">
                              x2
                            </span>
                          </h1>
                          <h1 className="flex items-center">$19</h1>
                        </div>
                        <hr />
                        <div className="flex justify-between py-2">
                          <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                            Margarita Pizza
                            <span className=" rounded-full bg-white p-1">
                              x2
                            </span>
                          </h1>
                          <h1 className="flex items-center">$19</h1>
                        </div>
                        <hr />
                        <div className="flex justify-between py-2">
                          <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                            Margarita Pizza
                            <span className=" rounded-full bg-white p-1">
                              x2
                            </span>
                          </h1>
                          <h1 className="flex items-center">$19</h1>
                        </div>
                        <hr />
                        <div className="flex justify-between py-2">
                          <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                            Margarita Pizza
                            <span className=" rounded-full bg-white p-1">
                              x2
                            </span>
                          </h1>
                          <h1 className="flex items-center">$19</h1>
                        </div>
                        <hr />
                        <div className="flex justify-between py-2">
                          <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                            Margarita Pizza
                            <span className=" rounded-full bg-white p-1">
                              x2
                            </span>
                          </h1>
                          <h1 className="flex items-center">$19</h1>
                        </div>
                        <hr />
                      </div>

                      <div className="px-4  ">
                        <BasicButton
                          // color="primary"
                          type="submit"
                          color="genieRed"
                          sx={{
                            color: "white",
                            textTransform: "none",
                            width: "100%",
                            height: "36px",
                          }}
                        >
                          Generate QR Code
                        </BasicButton>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </form>

      {isSmallScreen && (
        <DrawerComponent
          position="right"
          open={orderDetails}
          onClose={() => setOrderDetails(null)}
        >
          <Grid item xs>
            <div className="bg-[#FCF0F0] rounded-l-xl h-full ">
              <h1 className="flex justify-center items-center h-10 font-semibold text-md text-[#B80E0C] ">
                Order #{orderDetails?.ordNo}
              </h1>
              <h1 className="flex justify-center items-center font-normal text-sm mb-2 ">
                Order List
              </h1>
              <hr />
              <div className="px-4 h-[calc(65dvh-8.75rem)] overflow-scroll">
                <div className="flex justify-between py-2">
                  <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                    Margarita Pizza
                    <span className=" rounded-full bg-white p-1">x2</span>
                  </h1>
                  <h1 className="flex items-center">$19</h1>
                </div>
                <hr />
                <div className="flex justify-between py-2">
                  <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                    Margarita Pizza
                    <span className=" rounded-full bg-white p-1">x2</span>
                  </h1>
                  <h1 className="flex items-center">$19</h1>
                </div>
                <hr />
                <div className="flex justify-between py-2">
                  <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                    Margarita Pizza
                    <span className=" rounded-full bg-white p-1">x2</span>
                  </h1>
                  <h1 className="flex items-center">$19</h1>
                </div>
                <hr />
                <div className="flex justify-between py-2">
                  <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                    Margarita Pizza
                    <span className=" rounded-full bg-white p-1">x2</span>
                  </h1>
                  <h1 className="flex items-center">$19</h1>
                </div>
                <hr />
                <div className="flex justify-between py-2">
                  <h1 className="flex   items-center  gap-2 font-normal text-sm  ">
                    Margarita Pizza
                    <span className=" rounded-full bg-white p-1">x2</span>
                  </h1>
                  <h1 className="flex items-center">$19</h1>
                </div>
                <hr />
              </div>

              <div className="px-4  ">
                <BasicButton
                  // color="primary"
                  type="submit"
                  color="genieRed"
                  sx={{
                    color: "white",
                    textTransform: "none",
                    width: "100%",
                    height: "36px",
                  }}
                  onClick={() => setQrCodeOpen(true)}
                >
                  Generate QR Code
                </BasicButton>
              </div>
            </div>
          </Grid>
        </DrawerComponent>
      )}
      {qrCodeOpen && (
        //TODO:Add Table Number in model and jpg
        <QrCodeModal
          open={qrCodeOpen}
          handleCancel={() => setQrCodeOpen(false)}
          // link={tableLink}
          // handleConfirmClick={() => ( navigate(-1))}
        />
      )}
    </HeaderWrapper>
  );
}

export default DeliveryManagement;
