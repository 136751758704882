import React, { useEffect, useRef, useState } from "react";
import RestaurantInfo from "./RestaurantInfo";
import { Badge, Box, Grid, Paper } from "@mui/material";
import { TextInput } from "../../components/InputBox";
import CustomTabs from "../../components/Tabs";
import CustomTabPanel from "../../components/Tabs/CustomtabPanels";
import MenuCard from "./MenuCard";
import DrawerComponent from "../../components/Drawer/Index";
import { useDiner } from "../../utils/Context/Dinner";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAuth, useSuperAdmin } from "../../utils/hooks";
import useToken from "../../utils/hooks/useToken";
import BasicButton from "../../components/Button";
import getBrowserFingerprint from "get-browser-fingerprint";
import SwipeableEdgeDrawer from "../../components/Drawer/SwipableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import { useDebounce } from "../../useDebaunce";
import Isolation_Mode from "../../assets/images/Isolation_Mode.svg";
import cart from "../../assets/Icons/Cart.svg";
import food_image from "../../assets/images/food_image.png";
import search from "../../assets/Icons/search.svg";
import ButtonWithIcon from "../../components/ButtonWithIcon";
import spiceIcon from "../../assets/Icons/spiceIcon.png";
import broccoli from "../../assets/Icons/broccoli.png";
import { titleCase } from "../../utils/helper";
import Counter from "../../components/Counter";
import OfferCard from "../../components/OfferCard";
import fire from "../../assets/Icons/fire.png";
import close from "../../assets/Icons/close.svg";
import backArrow from "../../assets/Icons/backArrow.svg";
import { queryClient } from "../../utils/config/queryClient";
import { LazyLoadComponent } from "react-lazy-load-image-component";


function Diner() {
  const [searchValue, setSearchValue] = useState("");
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isViewOrder, setViewOrder] = useState(false);
  const [isDrawerOrderOpen, setDrawerOrderOpen] = useState(true);
  const [isDrawerOpenValue, setDrawerOpenValue] = useState(false);
  const [foodTypeValue, setFoodTypeValue] = useState("");
  const [foodSpiceId,] = useState("");
  const [, setIsSearchFocused] = useState(false);
  const [showNewScreen, setShowNewScreen] = useState(false);
  const bannerImageRef = useRef(null)

  const restaurantId = useParams();
  const { getCartId, getUserDetails, getPartnerId, savePartnerId, removeToken, getScanStatus, setScanStatus, getOrderId } = useToken();
  const { socket } = useAuth();

  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");

  const { tabValue } = useSuperAdmin();
  const navigate = useNavigate();
  const { id } = useParams();
  const userDetails = getUserDetails();
  const {
    useGetDinerDetails,
    useGetRestaurantInfo,
    restaurantInfoHeight,
    useGetFoodType,
    // useGetSpiceLevel,
    useGetFoodCategory,
    useGetFoodMenuByCategory,
    useGetOrderDetails,
    OrderModel,
    setOrderModel,
    useGetCartCount,
    cartList,
    useGetCartItem,
    useGetFoodItemsByQuery,
    countDiner,
    decrementAdultCount,
    incrementAdultCount,
  } = useDiner();

  const { isLoading: cartDataIsLoading } = useGetCartItem({
    // cartID: getCartId(),
    // partnerId: id,
    dinerId: getUserDetails()?.id,
  });

  /**
   * Generates a unique fingerprint for the current browser.
   * @returns A string representing the browser fingerprint.
   */
  const fingerprint = getBrowserFingerprint();

  /**
   * Toggles the state of the drawer and order model between open and closed.
   * @returns None
   */
  const toggleDrawer = () => {
    setOrderModel(!OrderModel);
    setDrawerOpen(!isDrawerOpen);
    setDrawerOpenValue(!isDrawerOpenValue);
  };
  /**
   * Toggles the visibility of the order drawer and view order state.
   * @returns None
   */
  const toggleViewOrderDrawer = () => {
    setOrderModel(!OrderModel);
    setViewOrder(!isViewOrder);
  };
  /**
   * Toggles the state of the order drawer between open and closed.
   * @returns None
   */
  const toggleOrderDrawer = () => {
    setDrawerOrderOpen(!isDrawerOrderOpen);
  };

  // const { data: dinerDetails, isLoading: dinerDetailsIsLoading } =
  useGetDinerDetails({
    dinerId: fingerprint.toString(),
    userAgent: window.navigator?.userAgent,
  });

  const { data: restaurantInfo } = useGetRestaurantInfo({
    restId: restaurantId?.id,
    tableId: tableId,
  });

  // const { data: cartCount } = useGetCartCount({
  //   dinerId: getUserDetails()?.id,
  //   status: "all"
  // });

  // const { data: OrderData } = useGetOrderDetails();
  const { data: foodType, isLoading: foodTypeIsLoading } = useGetFoodType(restaurantId?.id);
  // const { isLoading: spiceLevelIsLoading } = useGetSpiceLevel({ restId: restaurantId?.id });
  const { data: menuCategory, isLoading: menuCategoryIsLoading } = useGetFoodCategory(restaurantId?.id);

  const [isLoading, setIsLoading] = useState(true)
  const [searchResultIsActive, setSearchResultIsActive] = useState(false)

  const debauchedValue = useDebounce(searchValue, 300);

  const { data: menuList, isLoading: menuListIsLoading } =
    useGetFoodMenuByCategory({
      restId: restaurantId?.id,
      menuId: menuCategory?.result[tabValue]?._id,
      // query: debauchedValue,
      // foodTypeId: foodTypeValue,
      // spiceLevelId: foodSpiceId,
    });

  const { data: searchResult, isLoading: searchResultIsLoading } =
    useGetFoodItemsByQuery({
      restId: restaurantId?.id,
      // menuId: menuCategory?.result[tabValue]?._id,
      query: debauchedValue,
      foodTypeId: foodTypeValue,
      spiceLevelId: foodSpiceId,
    });

  useEffect(() => {
    const isDataLoaded =
      restaurantInfo &&
      !foodTypeIsLoading &&
      // !spiceLevelIsLoading &&
      !menuCategoryIsLoading
    // &&
    // !cartDataIsLoading
    // !menuListIsLoading &&
    // !orderIsLoading;

    setIsLoading(!isDataLoaded);
  }, [
    restaurantInfo,
    foodTypeIsLoading,
    // spiceLevelIsLoading,
    menuCategoryIsLoading,
    // cartDataIsLoading
    // menuListIsLoading,
    // orderIsLoading,
  ]);

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
    setShowNewScreen(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      setShowNewScreen(false);
      if (e.target.value !== "")
        setSearchResultIsActive(true)
      else
        setSearchResultIsActive(false)
      e.target.blur()
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setShowNewScreen(true);
  };

  // useEffect(() => {
  //   const currentPartnerId = getPartnerId();

  //   if (!currentPartnerId || currentPartnerId !== id) {
  //     savePartnerId(id);

  //     if (currentPartnerId && currentPartnerId !== id) {
  //       removeToken();
  //       // Instead of reloading, we can handle the reset more gracefully
  //       // Reset relevant state/context here
  //       setSearchValue('');
  //       setDrawerOpen(false);
  //       setViewOrder(false);
  //       // ... reset other necessary state

  //       // Optionally show a notification to user
  //       // toast.info("Switched to new restaurant"); // if you have a toast system
  //     }
  //   }
  // }, [id, getPartnerId, savePartnerId, removeToken]);

  useEffect(() => {
    if (getScanStatus() == null && getOrderId() == null && tableId) {
      setScanStatus(true);
      socket.emit("request", {
        en: "QR_SCANNED",
        data: {
          partnerId: restaurantId?.id,
          tableId: tableId
        }
      });
    }
  }, []);

  useEffect(() => {
    if (bannerImageRef.current) {
      bannerImageRef.current.style.maxHeight = `${restaurantInfoHeight + 40}px`
    }
  }, [restaurantInfoHeight])

  return (
    <>
      <Grid container sx={12} direction="column" className="font-poppins">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem] sticky top-0 z-50"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          />
          <Badge
            badgeContent={cartList.reduce((acc, curr) => { return acc + curr.orderDetails.length }, 0)}
            onClick={() => {
              if (tableId) {
                navigate(`cart/?tableId=${tableId}`)
              }
            }}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}
          >
            {/* <Badge badgeContent={cartCount || 0} onClick={() => navigate(`cart/?tableId=${tableId}`)} sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}> */}
            <img src={cart} alt="cart" className={`${tableId ? "cursor-pointer" : "opacity-50"}`} />
          </Badge>
        </Grid>

        {isLoading ?
          <div className="flex flex-col justify-center items-center h-[calc(100vh-60px)]">
            <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
            <p className="text-gray-600">
              Loading...
            </p>
          </div>
          :
          <Grid sx={{
            position: "relative",
            marginBottom: cartList.reduce((acc, curr) => { return acc + curr.orderDetails.length }, 0) > 0 ? "72.67px" : "0px"
          }}>
            <Grid xs={12} sx={{ position: "absolute", zIndex: "-1" }} className={`${showNewScreen ? "hidden" : ""}`}>
              <LazyLoadComponent
                threshold={100}
                placeholder={
                  <div className="animate-pulse bg-gray-200 max-h-[120px] w-[100vw]" />
                }
              >
                <img
                  src={restaurantInfo?.result?.bannerURL}
                  alt={`${restaurantInfo?.result?.name || 'Restaurant'} banner`}
                  ref={bannerImageRef}
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = food_image; // Fallback to default image
                    e.target.alt = 'Default restaurant banner';
                  }}
                  className={`
                    max-h-[120px] w-[100vw] 
                    object-cover 
                    transition-[max-height] ease-in-out duration-200
                    bg-gray-50
                  `}
                />
              </LazyLoadComponent>
            </Grid>
            <Grid item sx={12} className={`${showNewScreen ? "hidden" : ""}`}>
              <RestaurantInfo
                data={restaurantInfo}
                toggleDrawer={toggleDrawer}
                isDrawerOpenValue={isDrawerOpenValue}
              />
            </Grid>

            <div className={`${searchResultIsActive ? "p-0  z-[100]" : ""}`}>
              {/* <Grid item xs={12}>
                <Box sx={{ width: "100vw", height: "136.67px", backgroundColor: "red", position: "absolute", zIndex: "-3", top: "-22px" }}></Box>
              </Grid> */}

              <Grid item sx={12}>
                <Grid container sx={12} className=" px-[16px] mt-[1.375rem] mb-2 ">
                  <Grid item xs={12} className="flex" alignItems={"center"} classes="shadow-md relative">
                    <Paper elevation={2}
                      sx={{ width: "100%", borderRadius: "30px", backgroundColor: "#fff" }}>
                      <TextInput
                        variant="outlined"
                        placeholder="What's on your mind?"
                        value={searchValue}
                        onChange={handleSearchChange}
                        onFocus={handleSearchFocus}
                        onBlur={handleSearchBlur}
                        onKeyDown={handleSearchKeyDown}
                        borderRadius="30px"
                        transparentBorder
                        backgroundColor="#fff"
                        preContent={<img className="w-[18px] h-[18px]" src={search} alt="" />}
                        postContent={
                          <img src={close} alt=""
                            // className={`${isSearchFocused ? "" : "opacity-0"}`}
                            onClick={() => {
                              setSearchValue("")
                              setFoodTypeValue("")
                              setShowNewScreen(false)
                              setSearchResultIsActive(false)
                            }}
                          />
                        }
                        sx={{
                          width: "100%",
                          borderRadius: "30px",
                          backgroundColor: "#fff",
                          '& .MuiInputBase-input::placeholder': {
                            color: '#545454'
                          },
                          '& .MuiInputBase-input:-webkit-autofill': {
                            '-webkit-box-shadow': '0 0 0 100px transparent inset',
                            transition: 'background-color 5000s ease-in-out 0s'
                          }
                        }}
                      />
                    </Paper>

                    {/* <div className="absolute right-[16px] rounded-full p-[2.5px] flex cursor-pointer"
                    style={{ background: "linear-gradient(180deg, #FF61C0 0%, #8AFFE3 25%, #FFC961 50%, #FFFFFF 75%, #C1D2FF 100%)" }}
                  >
                    <div className="h-[39px] w-[39px] rounded-full bg-[#b80e0c] flex justify-center items-center py-[4px] m-auto">
                      <img src={NewGenie} alt="" className="h-full" />
                    </div>
                  </div> */}
                  </Grid>
                </Grid>
              </Grid>

              {showNewScreen && debauchedValue.length > 0 && searchResult?.result?.length > 0 &&
                <div className="px-4 border-b-8 pt-6 pb-[20px] flex flex-col gap-[10px]">
                  <span className="font-medium text-[14px] text-[#545454]">Looking for...</span>
                  {/* TODO: set limit */}
                  {searchResult?.result.slice(0, 5).map(item => {
                    return (
                      <div className="flex items-center gap-2 py-3 border-t cursor-pointer"
                        onClick={() => {
                          setSearchValue(item?.item_name)
                          setShowNewScreen(false)
                          setSearchResultIsActive(true)
                        }}
                      >
                        <span className="font-medium">{item?.item_name}</span>
                        {/* TODO: Add spice level and food type icons */}
                        <div className="flex items-center gap-1">
                          <img className="w-3 h-3" src={broccoli} alt="" />
                          <img className="w-3 h-3" src={spiceIcon} alt="" />
                        </div>
                      </div>
                    )
                  })}
                </div>
              }

              <Grid item className={`relative ${showNewScreen ? "border-b-8 border-[#F0F0F0]" : ""}`} sx={{ marginTop: showNewScreen ? "26px" : "0px" }}>
                {showNewScreen && <span className="font-medium text-[14px] text-[#545454] px-4">Craving?</span>}
                <div className={`max-w-[100vw] my-[12px] px-4 flex items-center gap-2 ${showNewScreen ? "flex-wrap" : "flex-nowrap overflow-x-auto after:content-[''] after:w-10 after:h-full after:absolute after:right-0 after:bg-gradient-to-r after:from-transparent after:to-[#fff]"}`}>
                  {foodType?.result.map((item, index) =>
                    <ButtonWithIcon
                      sx={{
                        border: "1.5px solid #C0C0C0",
                        color: "#3f3f3f",
                        flexShrink: 0,
                      }}
                      imageClass="max-h-[18px]"
                      backgroundColor="#fff"
                      text={titleCase(item?.food_type)}
                      key={index}
                      onClick={() => {
                        // setFoodTypeValue(prevValue => prevValue === item?._id ? "" : item?._id);
                        // if (debauchedValue.length === 0)
                        //   setSearchResultIsActive(true)
                        if (foodTypeValue === item?._id) {
                          setFoodTypeValue("")
                          if (debauchedValue.length === 0)
                            setSearchResultIsActive(false)
                        }
                        else {
                          setFoodTypeValue(item?._id)
                          if (debauchedValue.length === 0)
                            setSearchResultIsActive(true)
                        }
                      }}
                      active={foodTypeValue === item?._id}
                    // icon={spiceIcon}
                    />
                  )}
                </div>
              </Grid>

              {!showNewScreen && searchResultIsActive && debauchedValue.length > 0 &&
                <div className="px-4 flex items-center gap-[12px] mt-[6px] mb-[6px]">
                  <img
                    className="h-4 cursor-pointer"
                    src={backArrow} alt=""
                    onClick={() => {
                      setSearchResultIsActive(false);
                      setSearchValue("")
                      setFoodTypeValue("")
                    }}
                  />
                  <span className="font-medium">Result for ‘{searchValue}’</span>
                </div>
              }
            </div>

            {showNewScreen &&
              // TODO: add border-b-8 for bottom line
              <Grid item xs={12} className="w-[100vw] py-[22px] flex  gap-4" sx={{ flexDirection: "column" }}>
                <span className="font-medium text-[14px] text-[#545454] px-4">Popular Dishes</span>
                <div className="relative h-fit">
                  <div className="flex flex-nowrap overflow-x-auto after:content-[''] after:w-10 after:h-full after:absolute after:right-0 after:bg-gradient-to-r after:from-transparent after:to-[#fff]">
                    {menuCategory?.result.map(Category => {
                      return (
                        <div className="flex flex-col items-center px-[20px] py-[11px] cursor-pointer">
                          {/* <img className="max-w-[37px] max-h-[33px]" src={fire} alt="" /> */}
                          <span className="font-medium text-[14px] text-nowrap">{Category.food_category}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Grid>
            }

            {!showNewScreen && !searchResultIsActive && tableId &&
              <Grid item xs={12} className="px-4 py-[12px] bg-[#FFFEEF] flex justify-between items-center">
                <p className="font-medium text-[#262626]">
                  No. of Diners
                </p>
                <Counter increment={incrementAdultCount} decrement={decrementAdultCount} value={countDiner} />
              </Grid>
            }

            {!showNewScreen && !searchResultIsActive &&
              <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                <Grid container direction="column">
                  <CustomTabs
                    tabs={
                      menuCategory?.result.map((item) => ({
                        label: item.food_category,
                        value: item._id,
                      })) || []
                    }
                  >
                    {(value) => {
                      return (
                        <>
                          <CustomTabPanel value={value} index={value}>
                            <div>
                              <MenuCard
                                list={menuList?.result}
                                isLoading={menuListIsLoading}
                                cartList={cartList}
                              />
                            </div>
                          </CustomTabPanel>
                        </>
                      );
                    }}
                  </CustomTabs>
                </Grid>
              </Grid>
            }

            {!showNewScreen && searchResultIsActive &&
              <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                <Grid container direction="column">
                  <MenuCard
                    list={searchResult?.result}
                    isLoading={searchResultIsLoading}
                    cartList={cartList}
                  />
                </Grid>
              </Grid>
            }

            <Box display={"none"}>
              {(showNewScreen || !searchResultIsActive) &&
                <Grid
                  sx={{
                    marginBottom: "16px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex",
                    gap: "9px",
                    width: "100vw",
                    overflowX: "auto",
                    flexWrap: "nowrap",
                    marginTop: showNewScreen ? "18px" : "0px"
                  }}
                >
                  <OfferCard image={food_image}
                    text="Order from these restaurants and save"
                    colorHex="bg-[#ffd951]"
                    buttonText="Browse offer"
                  />
                  <OfferCard
                    image={food_image}
                    text="Have cleaning supplies and other convenience items delivered"
                    colorHex="bg-[#63f099]"
                    buttonText="Shop convenience"
                  />
                </Grid>
              }
            </Box>

          </Grid>
        }

        {cartList.reduce((acc, curr) => { return acc + curr.orderDetails.length }, 0) > 0 &&
          <Grid
            item
            sx={12}
            className=" bg-[#FFFFFF]  flex justify-between items-end  px-[1rem] py-[0.75rem] fixed w-[100vw] bottom-0 z-50 border-t border-[#D1D1D1]"
          >
            <BasicButton color="genieRed"
              onClick={() => navigate(`cart/?tableId=${tableId}`)}
              sx={{
                color: "#fff",
                textTransform: "none",
                fontWeight: 500,
                width: "100%",
                height: "48px"
              }}
            >
              <span className="flex items-baseline">
                <span>View cart | {cartList.reduce((acc, curr) => { return acc + curr.orderDetails.length }, 0)}</span>
                <span className="text-xs uppercase">&nbsp;{cartList.reduce((acc, curr) => { return acc + curr.orderDetails.length }, 0) > 1 ? "items" : "item"}</span>
              </span>
            </BasicButton>
          </Grid>
        }
      </Grid>


      {/* {OrderData && OrderModel ? (
        <SwipeableEdgeDrawer
          position="bottom"
          open={true}
          activeText="Total order Amount"
          activePrice={`AED ${OrderData?.result?.totalOrderValue}`}
          onClose={toggleOrderDrawer}
        >
          <Grid
            item
            xs={12}
            direction="column"
            className="p-2 flex justify-around"
            height={"100%"}
          >
            <div className="flex justify-between  items-center  ">
              <div className="flex flex-col">
                <div className="text-quicksand font-[600] text-[14px] flex flex-col">
                  <h1 className="text-[#7F7F7F] font-semibold text-[14px]">
                    Note
                  </h1>
                  {OrderData?.result?.notes ? OrderData?.result?.notes : "-"}
                </div>
              </div>
            </div>
            <div className="flex w-full  gap-2 items-evenly my-3 ">
              <BasicButton
                color="primary"
                type="submit"
                variant="outlined"
                sx={{
                  textTransform: "none",
                  width: "100%",
                  height: "36px",
                  padding: "9px 20px",
                }}
                onClick={toggleViewOrderDrawer}
              >
                View order
              </BasicButton>
              <BasicButton
                color="primary"
                type="submit"
                sx={{
                  textTransform: "none",
                  width: "100%",
                  height: "36px",
                  padding: "9px 20px",
                }}
                onClick={() => navigate("bill")}
              >
                Request bill
              </BasicButton>
            </div>
          </Grid>
        </SwipeableEdgeDrawer>
      ) : (
        ""
      )} */}

      {/* {isDrawerOpen && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={isDrawerOpen}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              height: "150px",
            },
          }}
          onClose={toggleDrawer}
        >
          <RestaurantInfo
            data={restaurantInfo}
            toggleDrawer={toggleDrawer}
            isDrawerOpen={isDrawerOpen}
            isDrawerOpenValue={isDrawerOpenValue}
          />
        </DrawerComponent>
      )} */}

      {/* {isViewOrder && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={isViewOrder}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              height: "auto",
            },
          }}
          onClose={toggleViewOrderDrawer}
        >
          <Grid container className="p-2 ">
            <Grid item xs={12} className=" p-2 border-b-[2px]">
              <h1>Your order</h1>
            </Grid>
            <Grid item xs={12} className="border-b-[2px] p-2">
              <div className="flex justify-between  items-center  ">
                <div className="flex flex-col">
                  <div className="text-quicksand font-[600] text-[14px] flex flex-col">
                    <h1 className="text-[#7F7F7F] font-semibold text-[14px]">
                      Note
                    </h1>
                    {OrderData?.result?.notes ? OrderData?.result?.notes : "-"}
                  </div>
                </div>
              </div>
            </Grid>
            {OrderData?.result?.orderDetails.map((item) => (
              <Grid item xs={12} className="border-b-[2px] p-2 overflow-auto">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <h1 className="font-quicksand font-[600] max-w-52 text-[14px] text-[#484848]">
                      {item?.item_name ? item?.item_name : "-"}
                    </h1>
                    <div
                      className={` w-fit font-quicksand font-[600] text-[12px] text-[#3F3F3F] bg-[#33C442] rounded-full px-1`}
                    >
                      {item?.status ? item?.status : "-"}
                    </div>
                  </div>
                  <div className="  absolute right-[10px] font-quicksand font-[700]">
                    <span className="  text-[16px] text-genieRed">
                      {item?.quantity} <CloseIcon className="text-genieRed" />
                    </span>{" "}
                    <span className="text-[16px]">
                      AED {item?.oPrice - item?.dPrice}
                    </span>
                  </div>
                </div>
              </Grid>
            ))}

            <Grid
              container
              item
              xs={12}
              direction="column"
              className="px-3 pt-2"
            >
              <div className="flex justify-between ">
                <div>
                  <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
                    Total
                  </h1>
                  <h3 className="font-quicksand font-[500] text-[12px] text-[#777777]">
                    ({OrderData?.result?.orderDetails?.length}{" "}
                    {OrderData?.result?.orderDetails?.length > 1
                      ? "items"
                      : "item"}
                    )
                  </h3>
                </div>
                <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
                  AED {OrderData?.result?.totalOrderValue}
                </h1>
              </div>
              <div className="flex  pt-2">
                <BasicButton
                  color="primary"
                  type="submit"
                  sx={{ textTransform: "none", width: "100%" }}
                  onClick={() => navigate("bill")}
                >
                  Request bill
                </BasicButton>
              </div>
            </Grid>
          </Grid>
        </DrawerComponent>
      )} */}
    </>
  );
}

export default Diner;

