import { Badge, Grid } from '@mui/material'
import Isolation_Mode from "../../assets/images/Isolation_Mode.svg";
import cart from "../../assets/Icons/Cart.svg";
import { useParams } from 'react-router-dom';
import { useDiner } from '../../utils/hooks';
import useToken from '../../utils/hooks/useToken';
import { useState } from 'react';
import { Star } from '@mui/icons-material';
import CustomToggleButton from '../../components/CustomToggleButton';
import BasicButton from "../../components/Button";
import share from "../../assets/Icons/share.svg";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import offer_1 from "../../assets/images/offer_1.png";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CustomCarousel from '../../components/CustomCarousel';

const MobileView = ({
    logoURL,
    bannerURL,
    restaurantName,
    restaurantPhotos,
    restaurantTiming,
    youtubeURL,
    instagramURL,
    facebookURL,
    googleBusinessURL,
    address,
    deliveryTime
}) => {
    const { useGetCartCount } = useDiner();

    const restaurantId = useParams();
    const { getCartId } = useToken();

    const { data: cartCount } = useGetCartCount({
        partnerId: restaurantId?.id,
        cartId: getCartId(),
    });

    const [selectedOption, setSelectedOption] = useState('delivery')
    const [addressDropdownActive, setAddressDropdownActive] = useState(false);

    const options = [
        { label: 'Delivery', time: deliveryTime ? `${Number(deliveryTime)} - ${Number(deliveryTime) + 10} min` : '25 - 35 min' },
        { label: 'Pickup', time: '5 - 15 min' },
    ]
    const handleChange = (event, newOption) => {
        if (newOption !== null) {
            setSelectedOption(newOption)
        }
    }

    return (
        <>
            <Grid xs={12} direction="column" className="font-poppins">
                <Grid
                    item
                    sx={12}
                    className=" bg-[#b80e0c]  flex justify-between items-end  px-[12.35px] py-[7.41px] sticky top-0 z-50"
                >
                    <img
                        src={Isolation_Mode}
                        alt=""
                        srcSet=""
                        className="h-[22.23px]"
                        height={"36px"}
                    />
                    <Badge badgeContent={cartCount?.result?.cartItemCount} sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}>
                        <img src={cart} alt="cart" className='h-[19.76px]' />
                    </Badge>
                </Grid>

                <div container xs={12} sx={{ marginTop: "12.35px", display: "flex", flexDirection: "column", gap: "20px", position: "relative", justifyContent: "space-between" }}
                    className='mt-[12.35px] flex flex-col gap-[20px] relative justify-between h-[calc(100vh-80px)]'
                >
                    {/* <div className='absolute -z-10 -top-[20px] h-[70vh] w-full'>
            <img className='object-cover object-center h-full w-full' src={food_image} alt="" />
          </div> */}
                    {/* <div className='absolute -z-10 -top-[20px] h-[70vh] w-full'> */}
                    <img className='absolute w-[222.29px] -z-10 -top-[20px] object-cover object-center h-[286.5px]' src={bannerURL?.url || bannerURL} alt="" />
                    {/* </div> */}

                    <div className='flex flex-col gap-[12.35px] flex-none'>
                        <Grid xs={12} sx={{ width: "100%", padding: "0px 9.88px" }}>
                            <CustomToggleButton value={selectedOption} handleChange={handleChange} options={options} size="small" />
                        </Grid>

                        <Grid xs={12} sx={{ padding: "0px 9.88px" }}>
                            <Grid xs={12} sx={{ padding: "12.35px 9.88px", background: "#fff", borderRadius: "6.17px", display: "flex", flexDirection: "column", gap: "12px" }}>
                                <div className="flex gap-3">
                                    <div className="w-[74.71px] h-[85.21px] rounded-[7.41px] border border-[#FFDCDC]">
                                        <CustomCarousel animation='fade' size='small'>
                                            {restaurantPhotos?.map((photo) => (
                                                <img className="w-[74.71px] h-[85.21px] object-cover object-center rounded-[7.41px]" src={photo.url} alt="" draggable="false" />
                                            ))}
                                            {/* <img className="w-[74.71px] h-[85.21px] object-cover object-center rounded-[7.41px]" src={food_image_2} alt="" draggable="false" />
                                            <img className="w-[74.71px] h-[85.21px] object-cover object-center rounded-[7.41px]" src={food_image_2} alt="" draggable="false" />
                                            <img className="w-[74.71px] h-[85.21px] object-cover object-center rounded-[7.41px]" src={food_image_2} alt="" draggable="false" /> */}
                                        </CustomCarousel>
                                    </div>
                                    <div className='flex-grow flex flex-col justify-between'>
                                        <div className='flex items-center gap-2 font-medium text-base'>
                                            <img
                                                src={logoURL?.url || logoURL}
                                                alt="Logo"
                                                className="rounded-full  w-[23.46px] h-[23.46px] object-cover"
                                            />
                                            <h1 className="font-QuickSand font-700 text-[9.88px]  text-black leading-[11.86px]">
                                                {/* {data?.result?.name} */}
                                                {restaurantName}
                                            </h1>
                                        </div>
                                        <div>
                                            <div className="flex items-center gap-1 relative">
                                                <h3 className="font-QuickSand font-500 text-[7.41px] leading-[11.11px] text-[#484848] underline md:max-w-full truncate">
                                                    {/* {data?.result?.address} */}
                                                    {/* Dubai Marina */}
                                                    <span className="block truncate w-[60px]">
                                                        {address?.addressLine1}
                                                    </span>
                                                </h3>
                                                <ArrowDropDownOutlinedIcon sx={{ cursor: "pointer", transform: addressDropdownActive && "rotate(180deg)", width: "9.88px", height: "9.88px" }} onClick={() => setAddressDropdownActive(!addressDropdownActive)} />
                                                <div className={`bg-white absolute text-[7.41px] top-[25px] border border-[0.5] z-10 border-[#D1D1D1] p-2 rounded-[7.41px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] ${addressDropdownActive ? "block" : "hidden"}`}>
                                                    <span className="text-[#757373]">Full Address</span> <br />
                                                    {typeof (address) === 'string' ? address :
                                                        <>
                                                            <p>{address?.addressLine1}</p>
                                                            <p>{address?.addressLine2 || ""}</p>
                                                            <p>{address?.city}</p>
                                                            <p>{address?.zip !== "N/A" ? address?.zip : ""}</p>
                                                            <p>{address?.country}</p>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className="items-center gap-1 justify-between flex">
                                                <span className="text-[7.41px] text-[#777777]">10 - 20 min</span>
                                                <div className="text-[#377931] flex items-center gap-[3px] ml-2">
                                                    <Star sx={{ height: "9.26px", width: "9.26px" }} />
                                                    <span className="font-normal text-[7.41px]">4.6</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div className="flex gap-[4.96px]">
                                                <a href={facebookURL} target="_blank" rel="noreferrer" className='h-fit w-fit'>
                                                    <FacebookRoundedIcon sx={{ color: "#3f3f3f", height: "12px", width: "12px" }} />
                                                </a>
                                                <a href={instagramURL} target="_blank" rel="noreferrer">
                                                    <InstagramIcon sx={{ color: "#3f3f3f", height: "12px", width: "12px" }} />
                                                </a>
                                                <a href={youtubeURL} target="_blank" rel="noreferrer">
                                                    <YouTubeIcon sx={{ color: "#3f3f3f", height: "12px", width: "12px" }} />
                                                </a>
                                            </div>

                                            <div className=" flex justify-end">
                                                {/* <ShareIcon sx={{ color: "#3f3f3f" }} /> */}
                                                <a href={"*"} target="_blank" rel="noreferrer">
                                                    <img src={share} alt="" className='h-[12px]' />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <BasicButton color="genieRed" sx={{ width: "100%", padding: "7.41px 0px" }}>
                                        <span className="normal-case text-white font-medium text-[9.88px]">
                                            Go To Menu
                                        </span>
                                    </BasicButton>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid xs={12}>
                        <div className='bg-[#FFD951] rounded-tl-[12.35px] rounded-tr-[12.35px] p-[7.41px]'>
                            <CustomCarousel size='small'>
                                <div>
                                    <img className='w-full h-[106.82px] object-cover object-center rounded-tr-[12.35px] rounded-tl-[12.35px]' src={offer_1} alt="" draggable="false" />
                                    <div className='flex flex-col items-center justify-start mt-[19.76px] h-[70px]'>
                                        <p className='font-medium text-[14.82px] leading-[15.44px] text-center max-w-[256px] line-clamp-3'>Explore personalized recommendations with Genie</p>
                                    </div>
                                </div>
                                <div>
                                    <img className='w-full h-[106.82px] object-cover object-center rounded-tr-[12.35px] rounded-tl-[12.35px]' src={offer_1} alt="" draggable="false" />
                                    <div className='flex flex-col items-center justify-start mt-[19.76px] h-[70px]'>
                                        <p className='font-medium text-[14.82px] leading-[15.44px] text-center max-w-[256px] line-clamp-3'>Explore personalized recommendations with Genie</p>
                                    </div>
                                </div>
                                <div>
                                    <img className='w-full h-[106.82px] object-cover object-center rounded-tr-[12.35px] rounded-tl-[12.35px]' src={offer_1} alt="" draggable="false" />
                                    <div className='flex flex-col items-center justify-start mt-[19.76px] h-[70px]'>
                                        <p className='font-medium text-[14.82px] leading-[15.44px] text-center max-w-[256px] line-clamp-3'>Explore personalized recommendations with Genie</p>
                                    </div>
                                </div>
                            </CustomCarousel>
                        </div>
                    </Grid>
                </div>
            </Grid>
        </>
    )
}

export default MobileView