import React from "react";
import StatusDropdown from "../StatusDropDown/index";
import { useRestaurant } from "../../utils/hooks";

const OrdRow = ({ ord, index, onClick, active }) => {
  const { OnUpdateOrderStatus } = useRestaurant()

  const handlePaymentStatusChange = (status) => {
    console.log(status)
    OnUpdateOrderStatus(ord.orderId, { payment_status: status.join('') });
  }
  
  const handleOrderStatusChange = (status) => {
    OnUpdateOrderStatus(ord.orderId, { orderStatus: status.join('') });
  }

  return (
    <tr
      key={index}
      className={`border-b ${active ? "bg-[#FCF0F0]" : ""}`}
    // onClick={onClick}
    >
      <td
        className="px-3 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug select-none"
        onClick={onClick}
      >
        {ord?.orderId}
      </td>
      <td
        className=" px-3 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug select-none"
        onClick={onClick}
      >
        {ord?.tableSequence}
      </td>
      <td
        className=" px-3 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug select-none"
        onClick={onClick}
      >
        {ord?.itemCount}
      </td>
      <td
        className=" px-3 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug select-none"
        onClick={onClick}
      >
        AED {Number(ord?.orderTotal).toFixed(2)}
      </td>
      <td
        className=" px-3 py-3  font-normal text-[#4D4D4D]  border-r  leading-snug select-none"
      >
        <div className="w-full flex items-center justify-between">
          <StatusDropdown names={["pending", "requested", "done"]} value={ord?.paymentStatus} onChange={handlePaymentStatusChange} />
        </div>
      </td>
      <td
        className={`px-3 py-3  font-normal text-[#4D4D4D] leading-snug select-none`}
      >
        <StatusDropdown names={["qr_scanned", "new", "preparing", "ready_to_serve", "served", "payment_done"]} value={ord?.orderStatus} onChange={handleOrderStatusChange} />
      </td>
    </tr>
  );
};

export default OrdRow;
