import { Grid } from '@mui/material'
import React from 'react'
import ButtonWithIcon from '../ButtonWithIcon'
import rightArrow from '../../assets/Icons/rightArrow.svg'

const OfferCard = ({image, onClick, text, colorHex="bg-[#ffd951]", buttonText}) => {
    return (
        <Grid container height="143px" className={`${colorHex} rounded-[12px] min-w-[310px]`}>
            <Grid xs={8} className="py-[16px] pl-[18px] h-full  justify-between" sx={{ display: "flex", flexDirection: "column" }}>
                <p className="text-[16px] font-medium text-black">
                    {text}
                </p>
                <ButtonWithIcon onClick={onClick} sx={{ background: "#fff" }} text={buttonText} icon={rightArrow} position="right" />
            </Grid>
            <Grid xs={4} className="h-full">
                <img className="h-full w-full object-cover object-center rounded-r-[12px]" src={image} alt="" />
            </Grid>
        </Grid>
    )
}

export default OfferCard