import React, { useContext, createContext, useState, useEffect, useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useToken from "../../hooks/useToken";
import {
  getAllDinerDetails,
  getAllRestaurantInfo,
  getAllRestaurantInfoWithoutTable,
  getAllFoodType,
  getAllSpiceLevel,
  getAllFoodCategory,
  getAllFoodMenuByCategory,
  AddCartMenu,
  getAllCartItem,
  noteAdd,
  PlaceOrder,
  getAllOrderItem,
  Payment,
  Rating,
  getAllCartCount,
  getPersonalizeEvent,
  getAllGenieExp,
  getItemByQuery,
  getCartItemDetails,
  CreateOrder,
  AddToOrder,
  deleteOrder,
  getCustomizations,
  updateDinerCount,
  payBill
} from "../../controllers/diner";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useAuth } from "../auth";

const DinerContext = createContext(undefined);

export const useDiner = () => useContext(DinerContext);

function useProvideDiner() {
  const queryClient = useQueryClient();

  const {
    setToken,
    saveUser,
    savePartnerId,
    getPartnerId,
    saveCartID,
    getCartId,
    getUserDetails,
    token,
    saveOrderID,
    getOrderId,
    RemoveCartID,
    RemoveOrderID,
    getDinerCount,
    saveDinerCount,
    removeToken
  } = useToken();
  const { socket } = useAuth()

  const [isDrawerOpenValue, setDrawerOpenValue] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [cartList, setCartList] = useState([]);
  const [cartListDiff, setCartListDiff] = useState([]);
  const [count, setCount] = useState(0);
  const [OrderModel, setOrderModel] = useState(true);
  const [ratingId, setRatingId] = useState("");
  const [isDrawerNoteOpen, setDrawerNoteOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [orderResult, setOrderResult] = useState({});
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [hover2, setHover2] = useState(0);
  const [suggestion, setSuggestion] = useState("");
  const [OrderId, setOrderId] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [genieCheck, setGenieCheck] = useState(false);
  const [genieRecommendation, setGenieRecommendation] = useState([]);
  const [countDiner, setCountDiner] = useState(Number(getDinerCount()));
  const [dietaryPreferences, setDietaryPreferences] = useState({
    id: "",
    value: "",
  });
  const [allergicPreferences, setAllergicPreferences] = useState("");
  const [spicyPreferences, setSpicyPreferences] = useState({
    id: "",
    value: "",
  });
  const [cartResponseLoading, setCartResponseLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState([])
  const [deleteOrderModel, setDeleteOrderModel] = useState(false);
  const [deleteOrderPromise, setDeleteOrderPromise] = useState(null);
  const [localOrderDetails, setLocalOrderDetails] = useState([])
  const [restaurantInfoHeight, setRestaurantInfoHeight] = useState(0)
  // const [dietaryPreferencesText, setDietaryPreferencesText] = useState("");

  const [allergicData, setAllergicData] = useState([
    { id: 1, label: "Dairy", checked: false },
    { id: 2, label: "Gluten", checked: false },
    { id: 3, label: "Shellfish", checked: false },
    { id: 4, label: "Soy", checked: false },
    { id: 5, label: "Nuts", checked: false },
    { id: 6, label: "Eggs", checked: false },
  ]);
  const [paymentAmount, setPaymentAmount] = useState(0)

  const navigate = useNavigate();
  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");

  // console.log(useParams())

  /**
   * Increments the adult count in the diner state by 1.
   */
  const incrementAdultCount = () => {
    setCountDiner((prevCount) => prevCount + 1);
    saveDinerCount(countDiner + 1)
    if (getOrderId()) {
      dinerCountUpdate({ orderId: getOrderId(), count: countDiner + 1 })
    }
  };

  /**
   * Decrements the adult count in the diner state by 1.
   * @returns None
   */
  const decrementAdultCount = () => {
    setCountDiner((prevCount) => Math.max(0, prevCount - 1));
    saveDinerCount(countDiner - 1)
    if (getOrderId()) {
      dinerCountUpdate({ orderId: getOrderId(), count: countDiner - 1 })
    }
  };

  /**
   * Increments the count of kids in the diner state by 1.
   * @returns None
   */
  const incrementKidsCount = () => {
    setCountDiner((prevCount) => ({
      ...prevCount,
      kidsCount: prevCount.kidsCount + 1,
    }));
  };

  /**
   * Decrements the count of kids in the diner by 1.
   * @returns None
   */
  const decrementKidsCount = () => {
    setCountDiner((prevCount) => ({
      ...prevCount,
      kidsCount: Math.max(0, prevCount.kidsCount - 1),
    }));
  };

  /**
   * Handles the change event for a checkbox with the given id.
   * Toggles the 'checked' property of the item with the matching id in the data array.
   * @param {{number}} id - The id of the checkbox item to toggle.
   * @returns None
   */
  const handleCheckboxChange = (id) => {
    setAllergicData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const increment = () => {
    setCount(prev => prev + 1);
  };

  const decrement = () => {
    setCount(prev => prev - 1);
  };

  // TODO: fix this logic
  const toggleDrawer = (restData, itemCount) => {
    setOrderModel(!OrderModel);
    setDrawerOpen(!isDrawerOpen);

    // console.log(restData)

    // if (itemCount || count > 0 || restData?.customizationArray) {
    //   OnAddCart({
    //     // itemId: isDrawerOpenValue._id,
    //     // count: itemCount ? itemCount : count,
    //     // restId: restData?.restId,
    //     // tableSequence: restData?.tableId,
    //     // customization: restData?.customizationArray ? restData?.customizationArray : [],
    //     partnerId: restData?.restId || restData?.partnerId,
    //     dinerCount: {
    //       adults: countDiner.adultCount,
    //       kids: countDiner.kidsCount,
    //     },
    //     tableSequence: restData?.tableId,
    //     orderDetails: [
    //       {
    //         itemId: isDrawerOpenValue._id,
    //         item_name: isDrawerOpenValue?.itemName,
    //         oPrice: isDrawerOpenValue?.itemPrice,
    //         dPrice: isDrawerOpenValue?.itemPrice,
    //         quantity: itemCount ? itemCount : count,
    //       }
    //     ]
    //   });
    // }
    setCount(0);
  };

  const toggleNoteDrawer = () => {
    setDrawerNoteOpen(!isDrawerNoteOpen);
  };

  const handleNoteChange = (e) => {
    setNoteValue(e.target.value);
  };

  const useGetDinerDetails = () =>
    useQuery(["getAllDinerDetails"], () => getAllDinerDetails(), {
      enabled: !!!getUserDetails(),
      // enabled: true,
      select: (data) => data?.data,
      onSuccess: (res) => {
        if (getUserDetails() == null) {
          if (res?.result) {
            setToken({
              access_token: res?.result?.accessToken,
              refresh_token: res?.result?.refreshToken,
            });
            saveUser(res?.result);
            axios.defaults.headers.common.Authorization = `Bearer ${res?.result?.accessToken}`;
          }
        }
      },
    });

  const useGetRestaurantInfo = ({ restId, tableId }) =>
    useQuery(
      ["getAllRestaurantInfo", restId, tableId],
      () => {
        if (tableId) {
          return getAllRestaurantInfo({ restId, tableId })
        } else {
          return getAllRestaurantInfoWithoutTable(restId)
        }
      },
      {
        enabled: !!token && !!restId,
        select: (data) => data?.data,
        onSuccess: (res) => {
          if (!res?.result?.isActive) {
            navigate("closed", { state: res?.result });
          }
        },
        onError: (err) => {
          // navigate("/")
          navigate(`genie/dine/${restId}/invalid`, { state: err?.response?.data?.result });
        }
      }
    );

  const useGetFoodType = (id) =>
    useQuery(["getAllFoodType", id], () => getAllFoodType(id), {
      enabled: !!token && !!id,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetCartCount = ({ status, dinerId }) =>
    useQuery(
      ["getAllCartCount", dinerId],
      () => getAllCartCount({ dinerId, status }),
      {
        enabled: !!token && !!dinerId,
        select: (data) => data?.data,
        onSuccess: (res) => {
          console.log(res)
        },
      }
    );

  const useGetSpiceLevel = ({ restId }) =>
    useQuery(["getAllSpiceLevel", restId], () => getAllSpiceLevel({ restId }), {
      enabled: !!token && !!restId,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetFoodCategory = (id) =>
    useQuery(["getAllFoodCategory", id], () => getAllFoodCategory(id), {
      enabled: !!token && !!id,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetFoodMenuByCategory = ({
    restId,
    menuId,
    // query,
    // foodTypeId,
    // spiceLevelId,
  }) =>
    useQuery(
      [
        "getAllFoodMenuByCategory",
        restId,
        menuId,
        // query,
        // foodTypeId,
        // spiceLevelId,
      ],
      () =>
        getAllFoodMenuByCategory({
          restId,
          menuId,
          // query,
          // foodTypeId,
          // spiceLevelId,
        }),
      {
        enabled: !!restId && !!menuId,
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );

  const useGetFoodItemsByQuery = ({
    restId,
    menuId,
    query,
    foodTypeId,
    spiceLevelId,
  }) =>
    useQuery(
      [
        "getItemByQuery",
        restId,
        query,
        menuId,
        foodTypeId,
        spiceLevelId,
      ],
      () =>
        getItemByQuery({
          restId,
          menuId,
          query,
          foodTypeId,
          spiceLevelId,
        }),
      {
        enabled: !!restId && !!getUserDetails()?.id && !!(query || menuId || foodTypeId || spiceLevelId),
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );
  const useGetCartItem = ({ dinerId }) =>
    useQuery(
      ["getAllCartByID", dinerId],
      () => getAllCartItem(dinerId),
      {
        enabled: !!dinerId && !!tableId,
        select: (data) => data?.data,
        onSuccess: async (res) => {
          setCountDiner(
            res?.result[res?.result?.length - 1]?.dinerCount || Number(getDinerCount()) || 1
          );

          // Update both states with the new data directly from res
          // setLocalOrderDetails(res?.result);
          // setCartList(res?.result.map(({ _id, orderDetails, isPlaced }) =>
          //   ({ _id: _id, orderDetails: orderDetails, isPlaced: isPlaced })
          // ));
          setCartList(res?.result.map(({ _id, orderDetails, isPlaced }) => ({ _id: _id, orderDetails: orderDetails, isPlaced: isPlaced })));
        },
        cacheTime: 0,
      }
    );
  const useGetOrderDetails = () =>
    useQuery(
      ["getAllOrderByID", getOrderId()],
      () => getAllOrderItem(getOrderId()),
      {
        enabled: !!getOrderId(),
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );

  const useGetCustomizations = ({ menuIds, partnerId }) =>
    useQuery(
      ["getAllCustomizations", menuIds],
      () =>
        Promise.all(
          menuIds.map((menuId) =>
            getCustomizations({ menuId, partnerId })
          )
        ),
      {
        enabled: !!menuIds?.length && !!partnerId,
        select: (data) => data.map((item) => item?.data?.result),
        onSuccess: (res) => {
          // console.log(res);
        },
      }
    );

  const useGetGenieExp = ({ restId }) =>
    useQuery(
      ["getAllGenieExp", genieCheck],
      () =>
        getAllGenieExp({
          restId: restId,
          allergiesIds: allergicData,
          // allergiesIds: allergicPreferences,
          foodTypeId: dietaryPreferences?.id,
          spiceLevelId: spicyPreferences?.id,
        }),
      {
        enabled: true,
        cacheTime: 0,
        select: (data) => data?.data,
        onSuccess: (res) => {
          setGenieRecommendation(res?.result);
          setGenieCheck(false);
        },
      }
    );

  // post
  const { mutate: mutateAddCartMenu, isLoading: addCartMenuIsLoading } =
    useMutation(AddCartMenu, {
      onSuccess: async (res) => {
        // toast.success("Menu added to cart Successfully");
        if (getCartId() == null) {
          saveCartID(res?.data?.result?._id);
        } else {
          queryClient.refetchQueries("getAllCartByID");
          queryClient.refetchQueries("getAllCartCount");
        }
        // setDrawerOpen(false);
        // setCount(0);

        // TODO: fix this 
        // setLoadingItems(prev => prev.filter(itemId =>
        //   !res?.data?.result?.cartItems.some(cartItem => cartItem?.itemId === itemId)
        // ))

        setOrderModel(!OrderModel);
        setCartResponseLoading(false)
      },
      onError: (error) => {
        console.log(error);
      },
    });

  // const [pendingOrderDetails, setPendingOrderDetails] = useState([]);

  // const debouncedCreateOrder = useCallback(
  //   debounce((data, options) => {
  //     CreateOrder(data).then(options.onSuccess).catch(options.onError);
  //   }, 1000),
  //   []
  // );

  // Create a memoized debounced function that properly cleans up
  const debouncedInvalidateCart = useCallback(
    debounce((queryClient) => {
      if (queryClient) {
        queryClient.invalidateQueries("getAllCartByID");
      }
    }, 800, { leading: false, trailing: true }),
    [] // Empty dependency array since we want this to be stable
  );

  // Cleanup the debounced function when component unmounts
  useEffect(() => {
    return () => {
      debouncedInvalidateCart.cancel();
    };
  }, [debouncedInvalidateCart]);

  const { mutate: mutateCreateOrder, isLoading: createOrderIsLoading } =
    useMutation(CreateOrder,
      {
        onMutate: async (data) => {
          await queryClient.cancelQueries(['getAllCartByID', getUserDetails()?.id]);
          const previousCart = queryClient.getQueryData(['getAllCartByID', getUserDetails()?.id]);

          // Safely update the cart data
          queryClient.setQueryData(['getAllCartByID', getUserDetails()?.id], (oldData) => {
            if (!oldData?.data?.result) {
              return {
                data: {
                  result: [{
                    orderDetails: data.orderDetails || [],
                    isPlaced: false
                  }]
                }
              };
            }

            return {
              ...oldData,
              data: {
                ...oldData.data,
                result: [
                  ...(Array.isArray(oldData.data.result) ? oldData.data.result : []),
                  {
                    orderDetails: data.orderDetails || [],
                    isPlaced: false
                  }
                ]
              }
            };
          });
          return { previousCart };
        },
        onSuccess: (res) => {
          saveOrderID(res?.data?.result?._id);
          // queryClient.invalidateQueries("getAllCartByID");
          socket.emit("request", {
            en: "CREATE_ORDER",
            data: {
              partnerId: res?.data?.result?.partnerId,
              tableId: res?.data?.result?.tableId,
              orderId: res?.data?.result?._id
            }
          });
          debouncedInvalidateCart(queryClient);
        },
        onError: async (err, variables, context) => {
          // On error, revert to previous state
          queryClient.setQueryData(
            ['getAllCartByID', getUserDetails()?.id],
            context.previousCart
          );
        }
      }
    );

  // Create a debounced version of the mutation function
  const debouncedMutateAddToOrder = useCallback(
    debounce((data, options) => {
      AddToOrder(data).then(options.onSuccess).catch(options.onError);
    }, 500),
    []
  );

  const debouncedInvalidateQueries = useCallback(
    debounce((queryClient) => {
      queryClient.invalidateQueries("getAllCartByID");
    }, 500),
    []
  );

  const { mutateAsync: mutateAddToOrder, isLoading: addToOrderIsLoading } =
    useMutation((data) => new Promise((resolve, reject) => {
      debouncedMutateAddToOrder(data, {
        onSuccess: resolve,
        onError: reject
      });
    }),
      {
        onMutate: async (data) => {
          await queryClient.cancelQueries(['getAllCartByID', getUserDetails()?.id]);
          const previousCart = queryClient.getQueryData(['getAllCartByID', getUserDetails()?.id]);

          // Safely update the cart data
          queryClient.setQueryData(['getAllCartByID', getUserDetails()?.id], (oldData) => {
            if (!oldData?.data?.result) {
              return oldData;
            }

            return {
              ...oldData,
              data: {
                ...oldData.data,
                result: [
                  ...(Array.isArray(oldData.data.result)
                    ? oldData.data.result.filter(item =>
                      item._id !== undefined && item._id !== data.orderId
                    )
                    : []
                  ),
                  // Only add the object if orderDetails has items
                  ...(data?.data?.orderDetails?.length ? [{
                    ...oldData.data.result.find(item => item._id === data.orderId),
                    orderDetails: data?.data?.orderDetails,
                    dinerCount: data?.dinerCount
                  }] : [])
                ].filter(Boolean) // Remove any undefined/null values
              }
            };
          });
          return { previousCart };
        },
        onSuccess: async (res) => {
          // Use the debounced invalidation
          // debouncedInvalidateQueries(queryClient);
          // queryClient.invalidateQueries("getAllCartByID");
          debouncedInvalidateCart(queryClient);
        },
        onSettled: async () => {
          // Removed the commented out refetch
        },
        onError: (error, context) => {
          queryClient.setQueryData(['getAllCartByID', getUserDetails()?.id], context.previousCart);
          console.log(error)
        }
      }
    );

  const { mutate: mutateDeleteOrder, isLoading: deleteOrderIsLoading } = useMutation(deleteOrder, {
    onMutate: async (data) => {
      await queryClient.cancelQueries(['getAllCartByID', getUserDetails()?.id]);
      const previousCart = queryClient.getQueryData(['getAllCartByID', getUserDetails()?.id]);
      queryClient.setQueryData(['getAllCartByID', getUserDetails()?.id], (prev) => ({
        ...prev,
        data: {
          ...prev.data,
          result: prev.data.result.filter(item => item._id !== data)
        }
      }));
      // console.log(data, "data")
      // console.log(previousCart, "previousCart")

      return { previousCart }
    },
    onSuccess: async (res) => {
      queryClient.refetchQueries("getAllCartByID");
    },
  });

  const { mutate: mutateUpdateDinerCount, isLoading: updateDinerCountIsLoading } = useMutation(updateDinerCount, {
    onSuccess: async (res) => { },
  });

  const dinerCountUpdate = ({ orderId, count }) => {
    mutateUpdateDinerCount({ orderId, count })
  }

  const showDeleteOrderModel = useCallback(() => {
    return new Promise((resolve) => {
      setDeleteOrderModel(true);
      setDeleteOrderPromise(() => resolve);
    });
  }, []);

  const handleDeleteOrderConfirm = useCallback(() => {
    if (deleteOrderPromise) deleteOrderPromise(true);
    setDeleteOrderModel(false);
  }, [deleteOrderPromise]);

  const handleDeleteOrderCancel = useCallback(() => {
    if (deleteOrderPromise) deleteOrderPromise(false);
    setDeleteOrderModel(false);
  }, [deleteOrderPromise]);

  const cancelOrder = async (orderId, removeToken = true) => {
    return new Promise(async (resolve) => {
      const confirmed = await showDeleteOrderModel();
      if (confirmed) {
        // Update localOrderDetails by removing the order
        setLocalOrderDetails(prev => prev.filter(order => order._id !== orderId));

        // Update cartList by removing the order
        setCartList(localOrderDetails.map(({ _id, orderDetails, isPlaced }) => ({ _id: _id, orderDetails: orderDetails, isPlaced: isPlaced })))

        mutateDeleteOrder(orderId);
        if (removeToken) {
          RemoveOrderID();
        }
        resolve();
      }
    });
  }


  // useEffect(() => {
  //   console.log(localOrderDetails, "useEffect localOrderDetails")
  // }, [localOrderDetails])

  const debouncedMutateCreateOrder = useCallback(
    debounce((orderData) => {
      mutateCreateOrder(orderData);
    }, 1000),
    [mutateCreateOrder]
  );

  const onCreateOrder = async (data) => {
    // Update localOrderDetails and get the new state
    const updatedOrderDetails = await new Promise(resolve => {
      setLocalOrderDetails(prev => {
        const existingOrderIndex = prev.findIndex(order => order._id === "new_order_id");

        let newState;
        if (existingOrderIndex !== -1) {
          const updatedPrev = [...prev];
          const existingOrder = updatedPrev[existingOrderIndex];

          const updatedOrderDetails = [...existingOrder.orderDetails];
          data.orderDetails.forEach(newItem => {
            const existingItemIndex = updatedOrderDetails.findIndex(
              item => item.itemId === newItem.itemId
            );

            if (existingItemIndex !== -1) {
              if (newItem.quantity === 0) {
                // Remove the item if quantity is zero
                updatedOrderDetails.splice(existingItemIndex, 1);
              } else {
                // Update the item if quantity is not zero
                updatedOrderDetails[existingItemIndex] = {
                  ...updatedOrderDetails[existingItemIndex],
                  quantity: newItem.quantity,
                  customization: newItem.customization || updatedOrderDetails[existingItemIndex].customization
                };
              }
            } else {
              updatedOrderDetails.push(newItem);
            }
          });

          updatedPrev[existingOrderIndex] = {
            ...existingOrder,
            orderDetails: updatedOrderDetails
          };

          newState = updatedPrev;
        } else {
          newState = [...prev, {
            ...data,
            isPlaced: false,
            _id: "new_order_id"
          }];
        }

        resolve(newState);
        return newState;
      });
    });

    // Update cartList with the latest data
    setCartList(
      updatedOrderDetails.map(({ _id, orderDetails, isPlaced }) =>
        ({ _id, orderDetails, isPlaced })
      )
    );

    // Get the last order from updatedOrderDetails and call mutateCreateOrder
    const lastOrder = updatedOrderDetails[updatedOrderDetails.length - 1];
    if (lastOrder && lastOrder.orderDetails.length > 0) {
      const orderData = {
        partnerId: lastOrder.partnerId,
        dinerCount: lastOrder.dinerCount,
        tableId: lastOrder.tableId,
        orderDetails: lastOrder.orderDetails
      };

      debouncedMutateCreateOrder(orderData);
    }
  };

  // const debouncedMutateAddToOrder = useCallback(
  //   debounce((orderData) => {
  //     mutateAddToOrder(orderData);
  //   }, 750),
  //   [mutateAddToOrder]
  // );

  // const onAddToOrder = async (data) => {
  //   // Update localOrderDetails and get the new state
  //   const updatedOrderDetails = await new Promise(resolve => {
  //     setLocalOrderDetails(prev => {
  //       // Get the last order
  //       const lastOrderIndex = prev.length - 1;
  //       if (lastOrderIndex < 0) return prev;

  //       const updatedPrev = [...prev];
  //       const lastOrder = updatedPrev[lastOrderIndex];

  //       // Create a set of itemIds from data.orderDetails for quick lookup
  //       const newItemIds = new Set(data.orderDetails.map(item => item.itemId));

  //       // Start with existing order details and remove items not in data.orderDetails
  //       let updatedOrderDetails = [...lastOrder.orderDetails]
  //         .filter(item => newItemIds.has(item.itemId));

  //       // Update or add items from data.orderDetails
  //       data.orderDetails.forEach(newItem => {
  //         const existingItemIndex = updatedOrderDetails.findIndex(
  //           item => item.itemId === newItem.itemId
  //         );

  //         if (existingItemIndex !== -1) {
  //           if (newItem.quantity === 0) {
  //             // Remove the item if quantity is zero
  //             updatedOrderDetails.splice(existingItemIndex, 1);
  //           } else {
  //             // Update the item if quantity is not zero
  //             updatedOrderDetails[existingItemIndex] = {
  //               ...updatedOrderDetails[existingItemIndex],
  //               quantity: newItem.quantity,
  //               customization: newItem.customization || updatedOrderDetails[existingItemIndex].customization
  //             };
  //           }
  //         } else if (newItem.quantity > 0) {
  //           // Only add new items with quantity > 0
  //           updatedOrderDetails.push(newItem);
  //         }
  //       });

  //       // Update the last order with new orderDetails
  //       updatedPrev[lastOrderIndex] = {
  //         ...lastOrder,
  //         orderDetails: updatedOrderDetails
  //       };

  //       resolve(updatedPrev);
  //       return updatedPrev;
  //     });
  //   });

  //   // Update cartList with the latest data
  //   setCartList(
  //     updatedOrderDetails.map(({ _id, orderDetails, isPlaced }) =>
  //       ({ _id, orderDetails, isPlaced })
  //     )
  //   );

  //   // Get the last order and call mutateAddToOrder if there are items
  //   const lastOrder = updatedOrderDetails[updatedOrderDetails.length - 1];
  //   if (lastOrder && lastOrder.orderDetails.length > 0) {
  //     const orderData = {
  //       data: {
  //         orderDetails: lastOrder.orderDetails.map(({ status, ...rest }) => rest)
  //       },
  //       orderId: lastOrder._id,
  //       dinerCount: lastOrder.dinerCount
  //     };

  //     if (orderData.orderId)
  //       debouncedMutateAddToOrder(orderData);
  //   }
  // };

  const OnAddCart = async (data) => {
    setCartResponseLoading(true)

    // Handle deletion confirmation first if count of an item is 0 and there is more than one item in the order
    if (data?.count === 0 && cartList[cartList.length - 1].orderDetails.length > 1) {
      const confirmDelete = await showDeleteOrderModel();
      if (!confirmDelete) {
        setCartResponseLoading(false);
        return;
      }
    }

    let transformedData = getOrderId() ? {
      orderDetails: cartList.flatMap(({ orderDetails, isPlaced }) => orderDetails.map(item => {
        if (isPlaced) {
          return null;
        }
        const { status, ...rest } = item;
        if (rest.itemId === data?.itemId) {
          if (data?.count === 0) {
            return null; // This will be filtered out later
          }
          return {
            ...rest,
            quantity: data?.count,
            customization: data?.customization ? data.customization : rest.customization,
          };
        }
        return rest;
      }))
        .filter(Boolean) // Remove null items
        .concat(
          cartList.some(cart => !cart.isPlaced && cart.orderDetails.some(item => item.itemId === data?.itemId))
            ? []
            : (data?.count > 0 ? [{
              itemId: data?.itemId,
              item_name: data?.itemName,
              oPrice: data?.oPrice,
              dPrice: data?.dPrice,
              quantity: data?.count,
              description: data?.description,
              customization: data?.customization ? data.customization : [],
            }] : [])
        )
    } : {
      partnerId: data?.restId || data?.partnerId,
      dinerCount: countDiner,
      tableId: data?.tableId,
      orderDetails: [
        {
          itemId: data?.itemId,
          item_name: data?.itemName,
          oPrice: data?.oPrice,
          dPrice: data?.dPrice,
          quantity: data?.count,
          description: data?.description,
          customization: data?.customization ? data.customization.map(customGroup => ({
            ...customGroup,
            items: customGroup.items.map(({ _id, ...itemRest }) => itemRest)
          })) : [],
        }
      ]
    };

    setLoadingItems(prevItems => [...prevItems, data?.itemId])
    try {
      if (getOrderId()) {
        if (transformedData?.orderDetails?.length === 0) {
          await cancelOrder(getOrderId(), false)
          RemoveOrderID()
        } else {
          mutateAddToOrder({ data: transformedData, orderId: getOrderId() });
          // if (getOrderId() && getOrderId() !== undefined) {
          //   onAddToOrder(transformedData)
          // }
        }
      } else {
        if (createOrderIsLoading) {
          // setTimeout(() => {
          //     OnAddCart(data)
          // }, 2000) // 1 second delay
          console.log("createOrderIsLoading", createOrderIsLoading)
          return
        } else if (!!getUserDetails()) {
          mutateCreateOrder(transformedData);
          // onCreateOrder(transformedData)
        }
      }
    } catch (e) { }

    if (isDrawerOpen) {
      setDrawerOpen(false)
    }
  };

  const { mutate: mutateCartItemDetails } = useMutation(getCartItemDetails, {
    onSuccess: async (res) => { },
    onError: (error) => {
      console.log(error);
    },
  });

  const onCartItemDetails = (data) => {
    mutateCartItemDetails(data)
  }

  const { mutate: mutateAddNotes, isLoading: addNotesIsLoading } = useMutation(
    noteAdd,
    {
      onSuccess: async (res) => {
        setDrawerNoteOpen(false);
        queryClient.refetchQueries("getAllCartByID");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const OnNoteAdd = (data) => {
    let transformedData = {
      partnerId: data?.restId,
      cartId: getCartId(),
      notes: noteValue,
    };

    mutateAddNotes(transformedData);
  };

  const { mutate: mutatePersonalizeEvent } = useMutation(getPersonalizeEvent, {
    onSuccess: async (res) => { },
    onError: (error) => {
      console.log(error);
    },
  });

  const PersonalizeEvent = (data) => {
    mutatePersonalizeEvent(data);
  };

  const { mutate: mutatePlaceOrder, isLoading: placeOrderIsLoading } =
    useMutation(PlaceOrder, {
      onMutate: async (data) => {
        await queryClient.cancelQueries(['getAllCartByID', getUserDetails()?.id]);
        const previousCart = queryClient.getQueryData(['getAllCartByID', getUserDetails()?.id]);
        queryClient.setQueryData(['getAllCartByID', getUserDetails()?.id], (oldData) => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              result: [
                ...oldData.data.result.slice(0, -1),
                {
                  ...oldData.data.result[oldData.data.result.length - 1],
                  isPlaced: true,
                }
              ]
            }
          };
        });
        return { previousCart }
      },
      onSuccess: async (res) => {
        queryClient.invalidateQueries("getAllCartByID");
        // debouncedInvalidateCart(queryClient);
        // saveOrderID(res?.data?.result?._id);
        // setOrderId(res?.data?.result?._id);

        // setOrderModel(true);
        // RemoveCartID();
        // PersonalizeEvent({
        //   itemIds: res.data.result.orderDetails?.map((item) => {
        //     return item?.itemId;
        //   }),
        //   evenType: "PURCHASE",
        // });

        // navigate(-1);
        RemoveOrderID()
      },
      onError: (error, context) => {
        queryClient.setQueryData(['getAllCartByID', getUserDetails()?.id], context?.previousCart);
      },
    });

  const placeOrder = (orderId) => {
    // setLocalOrderDetails(prev => {
    //   const updatedOrders = [...prev];
    //   const lastIndex = updatedOrders.length - 1;
    //   if (lastIndex >= 0) {
    //     updatedOrders[lastIndex] = {
    //       ...updatedOrders[lastIndex],
    //       isPlaced: true,
    //       orderDetails: updatedOrders[lastIndex].orderDetails.map(item => ({
    //         ...item,
    //         status: "Being Prepared"
    //       }))
    //     };
    //   }
    //   return updatedOrders;
    // });

    // // Update cartList based on the updated localOrderDetails
    // setCartList(prev => {
    //   const updatedCart = [...prev];
    //   const lastIndex = updatedCart.length - 1;
    //   if (lastIndex >= 0) {
    //     updatedCart[lastIndex] = {
    //       ...updatedCart[lastIndex],
    //       isPlaced: true,
    //       orderDetails: updatedCart[lastIndex].orderDetails.map(item => ({
    //         ...item,
    //         status: "Being Prepared"
    //       }))
    //     };
    //   }
    //   return updatedCart;
    // });

    mutatePlaceOrder({ orderId: orderId, tableId: tableId });
  };

  const { mutate: mutatePayment } = useMutation(Payment, {
    onSuccess: async (res) => {
      setOrderResult(res?.data.result);
      RemoveOrderID();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const addPayment = () => {
    let transformedData = {
      paymentMode: "cash",
      orderId: getOrderId(),
    };

    mutatePayment(transformedData);
  };

  const { mutateAsync: mutatePayBill } = useMutation(payBill, {
    onSuccess: async (res) => {
      // console.log("payment successful")
    }
  })

  const PayBill = async (data, partnerId, tableId) => {
    try {
      await Promise.all(data?.map(order => mutatePayBill({ orderId: order._id, tableId: tableId })))
      removeToken()
      navigate(`/genie/dine/${partnerId}/bill/success/?tableId=${tableId}`)
      setCartList([])
      await queryClient.refetchQueries("getAllDinerDetails");
      await queryClient.resetQueries("getAllCartByID");
    } catch (error) {
      console.log(error)
    }
  }

  const { mutate: mutateRating } = useMutation(Rating, {
    onSuccess: async (res) => {
      setRatingId(res?.data?.result?._id);
      // toast.success("Order Placed Successfully");
      // queryClient.refetchQueries("getAllCartByID");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const addRating = (data) => {
    let transformedData = {
      ...data,
      orderId: OrderId,
    };

    if (ratingId) {
      transformedData.ratingId = ratingId;
    }

    mutateRating(transformedData);
  };

  return {
    // DinnerDetails
    useGetDinerDetails,
    // Restaurant Info
    useGetRestaurantInfo,
    restaurantInfoHeight,
    setRestaurantInfoHeight,
    //food type
    useGetFoodType,
    // Spice Level
    useGetSpiceLevel,
    //Menu Category
    useGetFoodCategory,
    // menu Category with id
    useGetFoodMenuByCategory,
    // add menu to cart
    OnAddCart,
    // get customizations in cart
    useGetCustomizations,
    // delete order
    cancelOrder,
    deleteOrderIsLoading,
    // get Cart Item
    decrement,
    increment,
    count,
    setCount,
    useGetCartItem,
    toggleDrawer,
    isDrawerOpenValue,
    setDrawerOpenValue,
    isDrawerOpen,
    setDrawerOpen,
    isDrawerNoteOpen,
    toggleNoteDrawer,
    noteValue,
    setNoteValue,
    setDrawerNoteOpen,
    useGetCartCount,
    handleNoteChange,
    cartListDiff,

    // add notes
    OnNoteAdd,
    cartList,
    setCartList,
    addCartMenuIsLoading,
    addNotesIsLoading,

    // place order
    placeOrder,
    placeOrderIsLoading,
    OrderModel,
    setOrderModel,
    PayBill,

    useGetOrderDetails,

    // addPayment
    addPayment,
    orderResult,
    // rating
    addRating,
    hover2,
    setHover2,
    rating2,
    setRating2,
    hover,
    setHover,
    rating,
    setRating,
    suggestion,
    setSuggestion,

    // personalize

    PersonalizeEvent,

    // genie experience
    pageCount,
    setPageCount,
    countDiner,
    setCountDiner,
    dietaryPreferences,
    setDietaryPreferences,
    allergicPreferences,
    setAllergicPreferences,
    spicyPreferences,
    setSpicyPreferences,
    // dietaryPreferencesText,
    // setDietaryPreferencesText,
    allergicData,
    setAllergicData,
    useGetGenieExp,
    genieCheck,
    setGenieCheck,
    decrementKidsCount,
    incrementKidsCount,
    decrementAdultCount,
    incrementAdultCount,
    handleCheckboxChange,
    genieRecommendation,
    setGenieRecommendation,
    loadingItems,

    cartResponseLoading,
    setCartResponseLoading,
    useGetFoodItemsByQuery,
    onCartItemDetails,

    // temporary payment amount
    paymentAmount,
    setPaymentAmount,

    // delete order
    deleteOrderModel,
    setDeleteOrderModel,
    handleDeleteOrderCancel,
    handleDeleteOrderConfirm,

    createOrderIsLoading
  };
}

export function ProvideDiner({ children }) {
  const diner = useProvideDiner();
  return (
    <DinerContext.Provider value={diner}>{children}</DinerContext.Provider>
  );
}
