import React, { useState, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../utils/hooks";
import { FormHelperText, Typography} from "@mui/material";
import CropIcon from '@mui/icons-material/Crop';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ButtonWithIcon from "../ButtonWithIcon";
import asteriskWithBg from "../../assets/Icons/asteriskWithBg.svg";

function DragAndDropCoverPic({
  onChange,
  label,
  displayPicture,
  error,
  helperText,
  handleRegenerate,
}) {
  const { file, setFile } = useAuth();
  const [crop, setCrop] = useState({ aspect: 1, width: 200, height: 200 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const [isCropping, setIsCropping] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFile([Object.assign(file, { preview: URL.createObjectURL(file) })]);
      onChange(file);
      setIsCropping(true);
      setCroppedImageUrl(null);
      setCrop({ aspect: 1, width: 200, height: 200 });
    }
  }, [setFile, onChange]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    maxSize: 10485760,
    multiple: false,
    onDrop,
    noClick: false,
    disabled: file && file.length > 0,
  });

  const handleDelete = () => {
    setFile([]);
    onChange("");
    setIsCropping(false);
    setCroppedImageUrl(null);
    setCrop({ aspect: 1, width: 200, height: 200 });
  };

  const onImageLoad = useCallback((img) => {
    imgRef.current = img.target;
  }, []);

  const getCroppedImg = useCallback(() => {
    if (!completedCrop || !imgRef.current) return;

    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    canvas.toBlob((blob) => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      }
      const fileName = file[0]?.name || 'cropped_image.png';
      const croppedFile = new File([blob], fileName, { type: 'image/png' });
      const croppedImageUrl = URL.createObjectURL(croppedFile);
      onChange(croppedFile);
      setIsCropping(false);
      setCroppedImageUrl(croppedImageUrl);
    }, 'image/png', 1); // Set quality to 1 for maximum resolution
  }, [completedCrop, onChange, file]);

  const handleGenerateAIImage = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to the dropzone
    handleRegenerate();
  };

  return (
    <div className="flex flex-col space-y-4">
      {label && <Typography variant="subtitle1" className="text-secondary-dark">{label}</Typography>}

      <div 
        {...getRootProps()} 
        className={`p-3 rounded-lg text-center transition-colors
          ${isDragActive ? 'bg-blue-50' : ''}
          ${error ? 'border-red-500' : ''}
          ${(file && file.length > 0) || displayPicture ? '' : 'cursor-pointer border-2 border-dashed border-gray-300'}
        `}
      >
        <input {...getInputProps()} />
        {file && file.length > 0 && file[0] ? (
          <div className={`flex ${!isCropping ? 'flex-row' : 'flex-col'} items-center gap-4`}>
            {isCropping ? (
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={1}
              >
                <img
                  ref={imgRef}
                  alt="profile pic"
                  className="w-full h-auto max-w-full"
                  src={URL.createObjectURL(file[0])}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            ) : (
              <img
                alt="profile pic"
                className="w-48 h-48 object-cover rounded-full"
                src={croppedImageUrl || URL.createObjectURL(file[0])}
              />
            )}
            <div className="flex flex-col mt-2 gap-2">
              {isCropping ? (
                <ButtonWithIcon text="Confirm Crop" iconComponent={<CropIcon />} onClick={getCroppedImg} />
              ) : (
                <>
                  <ButtonWithIcon text="Crop" iconComponent={<CropIcon />} onClick={() => setIsCropping(true)} />
                  <ButtonWithIcon text="Delete" iconComponent={<DeleteIcon />} onClick={handleDelete} />
                  {/* <ButtonWithIcon text="Change Image" iconComponent={<CloudUploadIcon />} onClick={open} /> */}
                </>
              )}
            </div>
          </div>
        ) : displayPicture ? (
          <div className="flex items-center gap-4">
            <img
              alt="profile pic"
              className="w-48 h-48 object-cover rounded-full"
              src={displayPicture}
            />
            <div className="flex flex-col gap-2">
              <ButtonWithIcon text="Delete" iconComponent={<DeleteIcon />} onClick={handleDelete} />
              <ButtonWithIcon text="Change Image" iconComponent={<CloudUploadIcon />} onClick={open} />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <CloudUploadIcon className="text-gray-400 text-3xl mb-1" />
            <Typography variant="body2" className="text-sm mb-2">
              Drag & drop an image or <span className="text-[#b80e0c] cursor-pointer" onClick={open}>click to browse</span>
            </Typography>
            <Typography variant="caption" className="text-gray-500 text-xs">
              or
            </Typography>
            <div className="mt-2">
              <ButtonWithIcon
                text="Gen AI Image"
                icon={asteriskWithBg}
                onClick={handleGenerateAIImage}
              />
            </div>
          </div>
        )}
      </div>

      {error && (
        <FormHelperText error>
          <Typography variant="caption">
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </div>
  );
}

export default DragAndDropCoverPic;