import { deleteApi, getApi, postApi, putApi } from "../config/apis";

export const getAllTabList = () => getApi(`/partner/food-category/count`);
export const getMenuDetails = (data) =>
  getApi(`/partner/gpt?dishname="${data}"`);
export const getAllTabContent = (id) => getApi(`/partner/menu/category/${id}`);
export const getAllMenuByID = (id) => getApi(`/partner/menu/details/${id}`);
export const getAllFoodType = () => getApi(`/partner/food-type`);
export const getAllSpiceLevel = () => getApi(`/partner/spice-level`);
export const getAllAllergies = () => getApi(`/partner/allergies-ingredient`);
export const getPartnerInfo = () => getApi(`/partner/info`);
export const getBriefPartnerInfo = () => getApi(`/partner/info/brief`);
export const updatePartnerInfo = (data) => putApi(`/partner/info`, data);

export const getAllTable = () => getApi(`/partner/table`);

export const getUserDetailsFromAccess = (data) => getApi(`/partner/auth/details?token=${data}`, { headers: { Authorization: `Bearer ${data}` } });

export const getOrderHistory = ({ page, pageSize, toDate, fromDate }) =>
  getApi(
    `/partner/order-history?page=${page}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}`
  );

export const updateOrderStatus = ({ orderId, data }) => putApi(`/genie/order/update-status/${orderId}`, data);

export const getAllOrderDetails = ({ page = 1, pageSize = 10, toDate, fromDate, partnerId }) => {
  let url = `/partner/order-history?page=${page}&pageSize=${pageSize}&partnerId=${partnerId}`;

  if (fromDate) url += `&fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;

  return getApi(url);
};

export const getOrderById = (orderId) => getApi(`/partner/order-history/details?orderId=${orderId}`);

export const editMenu = (data) => putApi(`/partner/menu`, data);
export const changeSequence = (data) =>
  putApi(`/partner/food-category/category-sequence`, data);

export const aiPhotoGeneration = (data) =>
  postApi(`/ai/image/image-generate`, data);

export const addTable = (data) => postApi(`/partner/table`, data);
export const updateTable = ({ tableId, data }) => putApi(`/partner/table/table-status/${tableId}`, data);

export const AddMenu = (data) => postApi(`/partner/menu`, data);
export const AddAllergyPath = (data) =>
  postApi(`/partner/allergies-ingredient`, data);
export const AddCategoryPath = (data) =>
  postApi(`/partner/food-category`, data);
export const AddTypePath = (data) => postApi(`/partner/food-type`, data);
export const addSpicePath = (data) => postApi(`/partner/spice-level`, data);

export const getDeleteMenu = (data) => deleteApi(`/partner/menu/${data}`);
export const getDeleteAllergyList = (data) =>
  deleteApi(`/partner/allergies-ingredient/${data}`);
export const getDeleteCategoryList = (data) =>
  deleteApi(`/partner/food-category/${data}`);
export const getDeleteTypeList = (data) =>
  deleteApi(`/partner/food-type/${data}`);
export const getDeleteSpiceList = (data) =>
  deleteApi(`/partner/spice-level/${data}`);

export const getAllInsights = (data) => {
  const { from, to } = data;
  return getApi(`/partner/insights?fromDate=${from}&toDate=${to}`)
}

export const uploadImage = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return postApi(`/upload`, formData);
};

export const uploadImageAsUrl = (data) => {
  return postApi(`/upload`, data);
};
