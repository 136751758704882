import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Isolation_Mode.svg";
import { Link, useLocation } from "react-router-dom";

// import useToken from "../../utils/hooks/useToken";
// import ConfirmLogoutModal from "./ConfirmLogoutmodal";
import delivery from "../../assets/Icons/delivery.svg";
import Dining from "../../assets/Icons/dining.svg";
import PickUp from "../../assets/Icons/concierge.svg";
import menuManagementIcon from "../../assets/Icons/menuManagementIcon.svg"
import insights from "../../assets/Icons/insights.svg"
import { Drawer, Grid } from "@mui/material";
import cargo_restraunt_logo from "../../assets/images/cargo_restraunt_logo.png";
import settings from "../../assets/Icons/settings.svg";
import closeIcon from "../../assets/Icons/close.svg"
import { useRestaurant } from "../../utils/hooks";
import BasicButton from "../Button";

function HeaderWrapper({ children }) {
  const { useGetBriefPartnerInfo, notificationDrawer, setNotificationDrawer } = useRestaurant()
  const { data: restaurantInfo } = useGetBriefPartnerInfo()
  // const [openLogoutModal, setOpenLogoutModal] = useState(false);
  // const { removeToken } = useToken();
  // const navigate = useNavigate();
  const location = useLocation();

  // const adminTabs = [
  //   { id: 1, title: "Restaurant List", route: "/dashboard" },
  //   { id: 2, title: "Add New Restaurant", route: "/add" },
  // ];

  const restaurantTabs = [
    {
      id: 1,
      title: "Restaurant Profile",
      route: "/restaurant",
      icons: restaurantInfo?.result?.logoURL,
      // icons: cargo_restraunt_logo
    },
    { id: 2, title: "Menu Management", route: "/menu", icons: menuManagementIcon },
    // { id: 2, title: "Table Management", route: "/table" },
    {
      id: 3, title: "Insights",
      route: "/insights",
      icons: insights, disabled: true
    },
    // { id: 4, title: "Settings", route: "/settings", icons: settings },
  ];
  const restaurantMenuTabs = [
    {
      id: 1, title: "Delivery Management",
      route: "/delivery",
      icons: delivery, disabled: true
    },
    {
      id: 2, title: "Pickup Management",
      route: "/pickup",
      icons: PickUp, disabled: true
    },
    { id: 3, title: "DineIn Management", route: "/DineIn", icons: Dining },
  ];

  return (
    <>
      <Grid container sx={{ display: "flex" }} xs={12}>
        <Grid item xs={1.7}>
          <nav className=" p-4 bg-[#B80E0C] flex flex-col justify-between h-[100vh] overflow-scroll  w-full  z-[999]  rounded-r-2xl gap-2">
            <div>
              <img src={Logo} alt="" srcSet="" className="mx-auto  h-[51px] mb-[20px]" />

              <div className="">
                <h1 className="text-[#FFFFFF99] truncate font-normal">Dashboards</h1>

                <div className="flex flex-col mt-4">
                  <div className="flex gap-2 flex-col ">
                    {!window.location?.host.includes("superadmin") &&
                      restaurantMenuTabs?.map((tab) => (
                        <Link
                          key={tab.id}
                          to={tab.route}
                          replace={!tab.disabled}
                          onClick={(e) => tab.disabled && e.preventDefault()}
                          className={`text-white  flex gap-2 ${location?.pathname !== tab.route
                            ? ""
                            : "bg-[#FFFFFF52] rounded"
                            } px-1.5 py-2  font-[400] text-[14px] leading-[18px] ${tab.disabled ? "opacity-50 pointer-events-none" : ""} `}
                        >
                          <img src={tab.icons} alt="" width={20} />
                          {/* <Tooltip title={tab.title} arrow> */}
                          <span className="truncate text-wrap">
                            {/* {tab.title.split(' ').length > 1 ? (
                              <>
                                {tab.title.split(' ')[0]}
                                <br />
                                {tab.title.split(' ').slice(1).join(' ')}
                              </>
                            ) : ( */}
                              {tab.title}
                            {/* )} */}
                          </span>
                          {/* </Tooltip> */}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                {restaurantTabs?.map((tab) => (
                  <Link
                    key={tab.id}
                    to={tab.route}
                    replace={!tab.disabled}
                    onClick={(e) => tab.disabled && e.preventDefault()}
                    // className={`text-white  flex gap-2 ${location?.pathname !== tab.route
                    className={`text-white  flex ${tab.id === 1 ? "gap-[6px]" : "gap-2"} ${location?.pathname.indexOf(tab.route)
                      ? ""
                      : "bg-[#FFFFFF52] rounded"
                      } px-1.5 py-2  font-[400] text-[14px] leading-[18px] ${tab.disabled ? "opacity-50 pointer-events-none" : ""} `}
                  >
                    {tab.id === 1
                      ? <img className="rounded-full object-cover h-[24px] w-[24px] my-auto -ml-[2px]" src={tab.icons} alt="" width={20} height={20} />
                      : <img src={tab.icons} alt="" width={20} />
                    }
                    <span className="truncate text-wrap">
                      {/* {tab.title.split(' ').length > 1 ? (
                        <>
                          {tab.title.split(' ')[0]}
                          <br />
                          {tab.title.split(' ').slice(1).join(' ')}
                        </>
                      ) : ( */}
                        {tab.title}
                      {/* )} */}
                    </span>
                  </Link>
                ))}
              </div>
            </div>

            {/* 
        <div className="  items-center  hidden lg:flex absolute right-[10px] h-[52px]">
          <button
            className={`text-white opacity-[80%] px-3 py-2 font-quicksand font-[700] text-[16px]  hover:border-b-2 hover:ease-out `}
            onClick={() => setOpenLogoutModal(true)}
          >
            <LogoutIcon sx={{ width: "20px" }} /> Logout
          </button>
        </div> */}
          </nav>
        </Grid>
        <Grid item xs={10.3}>
          {children}
        </Grid>
        <Drawer open={notificationDrawer} onClose={() => { setNotificationDrawer(false) }} anchor="right"
          sx={{
            padding: "22px 16px"
          }}
        >
          <div className="w-[405px]">
            <div className="w-full px-4 h-[74px] border-b-2 border-[#E9E9E9] flex items-center justify-between">
              <h1 className="text-[20px] font-medium text-[#3E3E3E]">Notification Center</h1>
              <img className="cursor-pointer" onClick={() => { setNotificationDrawer(false) }} src={closeIcon} alt="" />
            </div>
          </div>
          <div className="px-4">
            <div className="py-3 flex w-full gap-[27px] border-b-2 border-[#E9E9E9]">
              <p className="text-4xl text-[#b80e0c] leading-tight">
                12
              </p>
              <div className="flex flex-col gap-3 w-full">
                <p className="w-3/4">Incoming payment request from table No. 3</p>
                <div className="flex gap-3 w-full">
                  <BasicButton variant="outline" sx={{ width: "100%", border: "1px solid #377931" }}>
                    <span className="capitalize text-sm text-nowrap">
                      Mark as complete
                    </span>
                  </BasicButton>
                  <BasicButton sx={{ width: "100%" }} color="genieRed">
                    <span className="capitalize text-sm text-white">
                      Dismiss
                    </span>
                  </BasicButton>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </Grid>

      {/* {openLogoutModal && (
        <ConfirmLogoutModal
          open={openLogoutModal}
          handleCancel={() => setOpenLogoutModal(false)}
          handleConfirmClick={() => (removeToken(), navigate(-1))}
        />
      )} */}
    </>
  );
}

export default HeaderWrapper;
