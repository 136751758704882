import { Grid } from "@mui/material";
import React, {  useState } from "react";
import HeaderWrapper from "../../../components/headerwrapper/HeaderWrapper";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import DatePicker from "../../../components/DatePicker";
// import { useRestaurant } from "../../../utils/hooks";
import dayjs from "dayjs";
// import { getAllInsights } from "../../../utils/controllers/Restaurant";
// import { useQuery } from "react-query";
import { TextInput } from "../../../components/InputBox";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LogOutButton from "../../../components/Logout";
import ReusableSmallIOSSwitch from "../../../components/switch/IOSSwitch";

ChartJS.register(Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);
function Insights() {
  // const navigate = useNavigate();
  // const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  // const [dates, setDates] = useState({ from: "", to: "" });
  // const { useGetAllergies } = useRestaurant();
  // const { useGetAllergies, useGetInsights } = useRestaurant();
  // const getInsights = useGetInsights();

  // const { data: allergies } = useGetAllergies();


  const data = {
    labels: [23, 24, 25, 26, 27, 28, 29],
    datasets: [
      {
        label: "sales number",
        data: [3, 4, 9, 4, 15, 12, 14],
        borderColor: "#2B74D0",
        backgroundColor: "#2B74D0",
        borderWidth: 4.16,
        fill: false,
        pointStyle: false,
      },
    ],
  };
  const options = {
    responsive: true,
    type: "line",
    // scales: {
    //   x: {
    //     // Remove barThickness as it's not applicable to line charts
    //   },
    // },
  };


  // const handleDate = (value) => {
  //   dayjs(value[0]).format("YYYY-MM-DD")
  // }

  // const { data: insights } = useQuery(["getInsights", dates], () => getAllInsights(dates), {
  //   enabled: true,
  //   select: (data) => data?.data,
  //   onSuccess: (data) => { },
  // });


  // useEffect(() => {
  //   // const from = dayjs(dateRange[0]).format("YYYY-MM-DD");
  //   // const to = dayjs(dateRange[0]).format("YYYY-MM-DD");
  //   setDates({ from: "2024-03-01", to: "2024-05-25" })
  // }, [dateRange]);

  return (
    <>
      <HeaderWrapper>
        <div
          className="overflow-auto"
          style={{
            height: "100vh",
            // height: "calc(100vh - 51px)",
          }}
        >
          <Grid container sx={12}>
            {/* <Grid container xs={12} justifyContent="space-between">
              <Grid item>
                <h1 className=" ml-5 text-[24px] font-semibold font-quicksand">
                  Insights
                </h1>
              </Grid>
              <Grid item>
                <BasicButton
                  color="primary"
                  sx={{
                    textTransform: "none",
                    width: "126px",
                    height: "36px",
                    marginRight: "10px",
                  }}
                //   onClick={() => navigate("create")}
                >
                  Export report
                </BasicButton>
              </Grid>
            </Grid> */}
            <Grid container xs={12} justifyContent="space-between">
              <Grid item xs={12} sx={{ borderBottom: "2px solid #1C1C1C1A" }}>
                <div className="  flex  items-center    justify-between py-4 px-8 ">
                  <h3 className="hidden sm:flex gap-3 items-center">
                    <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                    <span className="text-[#1C1C1C66]">/</span>
                    <span>Insights</span>
                  </h3>
                  <div className="max-w-[400px]  flex gap-4 items-center ">
                    <TextInput
                      variant="outlined"
                      preContent={<SearchIcon sx={{ color: "#c8c8c8" }} />}
                      transparentBorder
                      placeholder="Search"
                      // value={searchValue}
                      borderRadius="10px"
                    // onChange={(e) => setSearchValue(e.target.value)}
                    //   label="Description(optional)"
                    />
                    <NotificationsNoneOutlinedIcon />
                    <LogOutButton />
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", gap: "8px", flexDirection: { xs: "column", md: "row" }, alignItems: { md: "center" }, justifyContent: "space-between", padding: "0px 16px" }}>
              <h1 className="text-[#3E3E3E] font-medium text-[32px]">Insights</h1>

              <div>
                <label className=" font-[600]  block mb-2 text-[14px] text-[#757373]">
                  SELECT SERVICE TYPE<span className="text-[#b80e0c]">*</span>
                </label>
                <div className="flex items-center gap-[45px]">
                  <div className="flex items-center gap-2">
                    <ReusableSmallIOSSwitch />
                    <span className="font-normal text-[20px]">Delivery</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <ReusableSmallIOSSwitch />
                    <span className="font-normal text-[20px]">Pick-up</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <ReusableSmallIOSSwitch />
                    <span className="font-normal text-[20px]">Dine in</span>
                  </div>
                </div>
              </div>

              <DatePicker
                value={dayjs().format("YYYY-MM-DD")}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) =>
                  setDateRange(update)} x
                isClearable={true}
                placeholderText="Select date range"
              />
            </Grid>
          </Grid>

          <Grid container columns={12} sx={{ display: "flex", width: "100%", justifyContent: "start", padding: "0px 16px", gap: "26px", borderBottom: "1px solid #E8E8E8" }}>
            <div className="py-[30px] flex flex-col gap-[40px] bg-[#FCFAE9] border border-[#F4F1D7] rounded-lg px-[25px]">
              <p className="text-[20px] leading-[18px] font-normal">Average number of table size</p>
              <p className="text-[40px] leading-[36px] font-semibold">3</p>
            </div>

            <div className="py-[30px] flex flex-col gap-[40px] bg-[#E9FCF2] border border-[#D0EDDD] rounded-lg px-[25px]">
              <p className="text-[20px] leading-[18px] font-normal">Top dietary preference</p>
              <p className="text-[40px] leading-[36px] font-semibold">Vegan</p>
            </div>

            <div className="py-[30px] flex flex-col gap-[40px] bg-[#E9F6FC] border border-[#E9F6FC] rounded-lg px-[25px]">
              <p className="text-[20px] leading-[18px] font-normal">Most popular spice level</p>
              <p className="text-[40px] leading-[36px] font-semibold">Normal</p>
            </div>

            {/* <Grid item xs={12} sm={12} md={6} lg={4}>
              <div
                style={{ background: "white" }}
                className="h-[100px] rounded-md border border-solid border-gray-300 flex flex-col justify-center items-center p-[10px]"
              >
                <p className="text-gray-500">Average number of table size</p>
                <p className="text-[35px] font-semibold font-quicksand">
                  {insights?.result?.avgTableSize}

                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              {" "}
              <div
                style={{ background: "white" }}
                className="h-[100px] rounded-md border border-solid border-gray-300 flex flex-col justify-center items-center p-[10px]"
              >
                <p className="text-gray-500">Top dietary preference</p>
                <p className="text-[35px] font-semibold font-quicksand">
                  {insights?.result?.topDietaryPreference?.name}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div
                style={{ background: "white" }}
                className="h-[100px] rounded-md border border-solid border-gray-300 flex flex-col justify-center items-center p-[10px]"
              >
                <p className="text-gray-500">Most Popular spice level</p>
                <p className="text-[35px] font-semibold font-quicksand">
                  {insights?.result?.topSpicePreference?.name}
                </p>
              </div>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={4}>
              {" "}
              <div className="h-[150px] rounded-md border border-solid border-gray-300 p-[10px] overflow-y-scroll">
                <p>List of allergies (from frequently designated)</p>
                <ol className="list-decimal ml-4">
                  {allergies?.result?.map((item) => {
                    return <li>{item?.allergen}</li>;
                  })}
                </ol>
              </div>
            </Grid> */}
          </Grid>
          <Grid container padding={"24px"} columns={12}>
            <Grid item xs={12}>
              <div className="p-[24px] flex justify-start items-center">
                <Line data={data} options={options} height={"574px"} width={"1168px"}></Line>
              </div>
            </Grid>
          </Grid>
        </div>
      </HeaderWrapper>
    </>
  );
}

export default Insights;
