import React from "react";
import logo from "../../assets/images/Frame.png";
import { TextInput } from "../../components/InputBox";
import BasicButton from "../../components/Button";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../../utils/hooks";
import { useParams } from 'react-router-dom';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;
const schema = z.object({
  password: z
    .string()
    .min(8, { message: "Password length must be 8" })
    .regex(passwordRegex, { message: "must contain A-Z ,a-z,0-9 and symbols" }),
  confirmPassword: z
    .string()
    .min(8, { message: "Password length must be 8" })
    .regex(passwordRegex, { message: "must contain A-Z ,a-z,0-9 and symbols" }),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords do not match",
  path: ["confirmPassword"],
});

export default function ResetPasswordwrapper() {
  const routeParams = useParams();
  const {token} = routeParams;
  const navigate = useNavigate();
  const {onChangePassword} = useAuth();
  const { handleSubmit,register, formState ,trigger} = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(schema),
  });

  const { errors } = formState;
  const onSubmit = (data) => {
  
    // TODO:Handle Submit
    delete data?.confirmPassword;
    const values = {
        token : token,
        password :data?.password
    }
    onChangePassword(values)
  };

  return (
    <div className="flex  flex-col justify-center items-center h-[100vh]">
      <div className=" w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow  ">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-[#ED1703]  ">
            <img src={logo} alt="Main Logo" />
          </div>

          <div className=" flex  flex-col gap-5  p-4 sm:p-6 md:p-8 ">
            <div>
              <TextInput
                {...register("password", { required: "Password is required" })}
                variant="outlined"
                placeholder=""
                label="Password"
                error={!!errors.password}
                helperText={errors?.password?.message}
                onBlur={() => trigger("password")}
              />
            </div>
            <div>
              <TextInput
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                })}
                variant="outlined"
                placeholder=""
                label="Confirm Password"
                error={!!errors.confirmPassword}
                helperText={errors?.confirmPassword?.message}
                onBlur={() => trigger("confirmPassword")}
              />
            </div>

            <BasicButton
              color="primary"
              type="submit"
              sx={{ textTransform: "none" }}
            >
              Reset password
            </BasicButton>
          </div>
        </form>
      </div>
      <div className="text-sm mt-5 font-medium text-gray-500 flex justify-center">
        <p
          className="text-genieRed hover:underline "
          onClick={() => {
            navigate("/");
          }}
        >
          Back to Login{" "}
        </p>
      </div>
    </div>
  );
}
